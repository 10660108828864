import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type SaveTutorialStatusMutationVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
  currentTutorial: Types.Scalars['String'];
}>;


export type SaveTutorialStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'saveTutorialStatus'>
);


export const SaveTutorialStatusDocument = gql`
    mutation saveTutorialStatus($accessToken: String!, $currentTutorial: String!) {
  saveTutorialStatus(accessToken: $accessToken, currentTutorial: $currentTutorial)
}
    `;

/**
 * __useSaveTutorialStatusMutation__
 *
 * To run a mutation, you first call `useSaveTutorialStatusMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSaveTutorialStatusMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSaveTutorialStatusMutation({
 *   variables: {
 *     accessToken: // value for 'accessToken'
 *     currentTutorial: // value for 'currentTutorial'
 *   },
 * });
 */
export function useSaveTutorialStatusMutation(options: VueApolloComposable.UseMutationOptions<SaveTutorialStatusMutation, SaveTutorialStatusMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SaveTutorialStatusMutation, SaveTutorialStatusMutationVariables>>) {
  return VueApolloComposable.useMutation<SaveTutorialStatusMutation, SaveTutorialStatusMutationVariables>(SaveTutorialStatusDocument, options);
}
export type SaveTutorialStatusMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SaveTutorialStatusMutation, SaveTutorialStatusMutationVariables>;