<template>
  <div class="places">
    <PageHeader title="場所一覧" :backIcon="true" to="/settings" />
    <div class="contents-wrapper">
      <b-loading :is-full-page="false" :active="loading" />
      <div class="detail-contents">
        <router-link
          class="detail"
          v-for="place in places"
          :key="place.id"
          :to="`/places/${place.id}`"
        >
          <p class="text is-size-6_5">
            {{ place.name }}
          </p>
        </router-link>
      </div>
      <router-link to="/places/new">
        <b-button class="add-button" expanded>
          <p class="text is-size-6_5 has-text-weight-bold">場所を追加する</p>
        </b-button>
      </router-link>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, reactive, ref, toRefs } from "@vue/composition-api";

import { useAuth } from "@/submodules/auth";
import { usePlacesQuery } from "./graphql/query/places.generated";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";

const { getToken } = useAuth();
const fetchPlaces = () => {
  const places = ref([]);
  const { onResult, loading } = usePlacesQuery({
    accessToken: getToken(),
  });
  onResult((result) => {
    places.value = result.data.places;
  });

  return {
    places,
    loading,
  };
};

export default defineComponent({
  name: "Places",
  components: {
    PageHeader,
    Footer,
  },
  setup() {
    const state = reactive({
      loading: true,
      places: [],
    });

    const { places, loading } = fetchPlaces();
    state.places = places;
    state.loading = loading;

    return {
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.contents-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
}
.detail-contents {
  > .detail {
    width: 100%;
    height: 40px;
    text-align: left;
    padding: 8px 0;
    border-top: 1px solid colors(light-gray);
    display: block;
    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: colors(dark-blue);
    }
  }
}
::v-deep .button {
  margin-top: 16px;
  color: white;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      color: white;
    }
  }
}
.add-button {
  margin-top: 32px;
  background-color: colors(blue);
  color: white;
}
</style>
