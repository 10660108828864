<template>
  <div class="places">
    <PageHeader title="場所詳細" :backIcon="true" to="/places" />
    <div class="contents-wrapper">
      <b-loading :is-full-page="false" :active="loading" />
      <div class="detail-contents">
        <div class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">場所名</p>
          </div>
          <div class="value">
            <p class="is-size-6">{{ place.name }}</p>
          </div>
        </div>
      </div>
      <router-link :to="`/places/${place.id}/edit`">
        <b-button class="edit-button" expanded>
          <p class="text is-size-6_5 has-text-weight-bold">編集する</p>
        </b-button>
      </router-link>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, reactive, ref, toRefs } from "@vue/composition-api";

import { useAuth } from "@/submodules/auth";
import { usePlaceQuery } from "./graphql/query/place.generated";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";

const { getToken } = useAuth();
const fetchPlace = (placeId) => {
  const place = ref({});
  const { onResult, loading } = usePlaceQuery({
    accessToken: getToken(),
    placeId,
  });
  onResult((result) => {
    place.value = result.data.place;
  });

  return {
    place,
    loading,
  };
};

export default defineComponent({
  name: "Place",
  components: {
    PageHeader,
    Footer,
  },
  setup(_, { root }) {
    const state = reactive({
      loading: true,
      place: {},
    });

    const { place, loading } = fetchPlace(root.$route.params.id);
    state.place = place;
    state.loading = loading;

    return {
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.contents-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
}
.detail-contents {
  > .detail {
    width: 100%;
    text-align: left;
    padding-top: 16px;
    .name {
      height: 20px;
      white-space: nowrap;
    }
    .value {
    }
  }
}
::v-deep .button {
  margin-top: 16px;
  color: white;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      color: white;
    }
  }
}
.edit-button {
  margin-top: 40px;
  background-color: colors(green);
}
</style>
