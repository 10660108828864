import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type CancelPlanMutationVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
}>;


export type CancelPlanMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'cancelPlan'>
);


export const CancelPlanDocument = gql`
    mutation cancelPlan($accessToken: String!) {
  cancelPlan(accessToken: $accessToken)
}
    `;

/**
 * __useCancelPlanMutation__
 *
 * To run a mutation, you first call `useCancelPlanMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCancelPlanMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCancelPlanMutation({
 *   variables: {
 *     accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useCancelPlanMutation(options: VueApolloComposable.UseMutationOptions<CancelPlanMutation, CancelPlanMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CancelPlanMutation, CancelPlanMutationVariables>>) {
  return VueApolloComposable.useMutation<CancelPlanMutation, CancelPlanMutationVariables>(CancelPlanDocument, options);
}
export type CancelPlanMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CancelPlanMutation, CancelPlanMutationVariables>;