import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type SaveEventIntervalsMutationVariables = Types.Exact<{
  accessToken?: Types.Maybe<Types.Scalars['String']>;
  intervalBeforeEvent?: Types.Maybe<Types.Scalars['String']>;
  intervalAfterEvent?: Types.Maybe<Types.Scalars['String']>;
}>;


export type SaveEventIntervalsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'saveAdjustableTimePeriods'>
);


export const SaveEventIntervalsDocument = gql`
    mutation saveEventIntervals($accessToken: String, $intervalBeforeEvent: String, $intervalAfterEvent: String) {
  saveAdjustableTimePeriods(
    accessToken: $accessToken
    intervalBeforeEvent: $intervalBeforeEvent
    intervalAfterEvent: $intervalAfterEvent
  )
}
    `;

/**
 * __useSaveEventIntervalsMutation__
 *
 * To run a mutation, you first call `useSaveEventIntervalsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSaveEventIntervalsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSaveEventIntervalsMutation({
 *   variables: {
 *     accessToken: // value for 'accessToken'
 *     intervalBeforeEvent: // value for 'intervalBeforeEvent'
 *     intervalAfterEvent: // value for 'intervalAfterEvent'
 *   },
 * });
 */
export function useSaveEventIntervalsMutation(options: VueApolloComposable.UseMutationOptions<SaveEventIntervalsMutation, SaveEventIntervalsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SaveEventIntervalsMutation, SaveEventIntervalsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SaveEventIntervalsMutation, SaveEventIntervalsMutationVariables>(SaveEventIntervalsDocument, options);
}
export type SaveEventIntervalsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SaveEventIntervalsMutation, SaveEventIntervalsMutationVariables>;