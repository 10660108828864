import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type ReadNotificationMutationVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
  id: Types.Scalars['Int'];
}>;


export type ReadNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'readNotification'>
);


export const ReadNotificationDocument = gql`
    mutation readNotification($accessToken: String!, $id: Int!) {
  readNotification(accessToken: $accessToken, id: $id)
}
    `;

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useReadNotificationMutation({
 *   variables: {
 *     accessToken: // value for 'accessToken'
 *     id: // value for 'id'
 *   },
 * });
 */
export function useReadNotificationMutation(options: VueApolloComposable.UseMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables>>) {
  return VueApolloComposable.useMutation<ReadNotificationMutation, ReadNotificationMutationVariables>(ReadNotificationDocument, options);
}
export type ReadNotificationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ReadNotificationMutation, ReadNotificationMutationVariables>;