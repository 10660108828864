import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type PlacesQueryVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
}>;


export type PlacesQuery = (
  { __typename?: 'Query' }
  & { places: Array<(
    { __typename?: 'Place' }
    & Pick<Types.Place, 'id' | 'name'>
  )> }
);


export const PlacesDocument = gql`
    query places($accessToken: String!) {
  places(accessToken: $accessToken) {
    id
    name
  }
}
    `;

/**
 * __usePlacesQuery__
 *
 * To run a query within a Vue component, call `usePlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlacesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePlacesQuery({
 *   accessToken: // value for 'accessToken'
 * });
 */
export function usePlacesQuery(variables: PlacesQueryVariables | VueCompositionApi.Ref<PlacesQueryVariables> | ReactiveFunction<PlacesQueryVariables>, options: VueApolloComposable.UseQueryOptions<PlacesQuery, PlacesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PlacesQuery, PlacesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PlacesQuery, PlacesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PlacesQuery, PlacesQueryVariables>(PlacesDocument, variables, options);
}
export type PlacesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PlacesQuery, PlacesQueryVariables>;