<template>
  <div class="latest-news">
    <div class="areas-wrapper">
      <div class="title-area">
        <p class="is-size-7 has-text-weight-bold">最新のお知らせ</p>
      </div>
      <div class="news-area">
        <!-- <b-loading :is-full-page="false" :active="loading" /> -->
        <NewsRow v-if="notifications[0]" :notification="notifications[0]" />
        <NewsRow v-if="notifications[1]" :notification="notifications[1]" />
        <NewsRow v-if="notifications[2]" :notification="notifications[2]" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, reactive, toRefs } from "@vue/composition-api";

import { useNotificationsQuery } from "../graphql/query/notifications.generated";
import { useAuth } from "@/submodules/auth";
import { useToast } from "@/submodules/toast";

import NewsRow from "@/components/dashboard/NewsRow.vue";

export default defineComponent({
  name: "LatestNews",
  components: {
    NewsRow,
  },
  setup() {
    const state = reactive({
      loading: true,
      notifications: [],
    });

    const { getToken } = useAuth();
    const { toastError } = useToast();
    const { onResult, error } = useNotificationsQuery({
      accessToken: getToken(),
    });
    onResult((result) => {
      if (error.value) {
        toastError();
        return;
      }
      state.loading = result.loading;
      state.notifications = result.data.notifications;
    });

    return {
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.latest-news {
  // height: 176px;
  > .areas-wrapper {
    padding: 16px;
    // border-bottom: solid 1px colors(light-gray);
    max-width: 760px;
    margin: 0 auto;
    > .title-area {
      height: 16px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: column;
    }
    > .news-area {
      // height: 144px;
      width: 100%;
      position: relative;
    }
  }
}
</style>
