import { DialogProgrammatic as Dialog } from 'buefy'

type Option = {
  cancelText?: string,
  confirmText?: string,
  type?: string
}

export const showConfirmDialog = (message: string, option?: Option) => {
  return new Promise<void>((resolve) => {
    Dialog.confirm({
      message,
      cancelText: option?.cancelText || 'いいえ',
      confirmText: option?.confirmText || 'はい',
      onConfirm: () => {
        resolve()
      }
    });
  })
}
