












import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Slide3',
  setup(){}
})
