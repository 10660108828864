<template>
  <div class="places">
    <PageHeader title="プライバシーポリシー" :backIcon="true" to="/settings" />
    <div class="contents-wrapper">
      <div class="contents">
        <p class="is-size-7">
          アポとるは、ユーザー間のスケジュール調整を簡単にするサービスです。
        </p>
      </div>
      <div class="contents">
        <p class="is-size-6">個人情報保護方針</p>
        <div class="content">
          <p class="is-size-7">
            ・アポとるは、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。
          </p>
        </div>
      </div>
      <div class="contents">
        <p class="is-size-6">個人情報の管理</p>
        <div class="content">
          <p class="is-size-7">
            ・アポとるは、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。
          </p>
        </div>
      </div>
      <div class="contents">
        <p class="is-size-6">個人情報の利用目的</p>
        <div class="content">
          <p class="is-size-7">
            ・アポとるでは、お客様からのお問い合わせ時に、お名前、e-mailアドレス、電話番号等の個人情報をご登録いただく場合がございますが、これらの個人情報はご提供いただく際の目的以外では利用いたしません。お客さまからお預かりした個人情報は、当社からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。
          </p>
        </div>
      </div>
      <div class="contents">
        <p class="is-size-6">個人情報の第三者への開示・提供の禁止</p>
        <div class="content">
          <p class="is-size-7">
            ・アポとるは、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。
          </p>
          <div class="content nested">
            <p class="is-size-7">* お客さまの同意がある場合</p>
            <p class="is-size-7">
              *
              お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合
            </p>
            <p class="is-size-7">* 法令に基づき開示することが必要である場合</p>
          </div>
        </div>
      </div>
      <div class="contents">
        <p class="is-size-6">個人情報の安全対策</p>
        <div class="content">
          <p class="is-size-7">
            ・アポとるは、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。
          </p>
        </div>
      </div>
      <div class="contents">
        <p class="is-size-6">ご本人の照会</p>
        <div class="content">
          <p class="is-size-7">
            ・お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。
          </p>
        </div>
      </div>
      <div class="contents">
        <p class="is-size-6">法令、規範の遵守と見直し</p>
        <div class="content">
          <p class="is-size-7">
            ・当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
          </p>
        </div>
      </div>
      <div class="contents">
        <p class="is-size-6">お問い合せ</p>
        <div class="content">
          <p class="is-size-7">
            ・アポとるの個人情報の取扱に関するお問い合せは下記までご連絡ください。
          </p>
          <p class="is-size-7">Email: info@biz-app.biz</p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent } from "@vue/composition-api";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: "Places",
  components: {
    PageHeader,
    Footer,
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.contents-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
  text-align: left;
  .contents {
    padding: 8px 0;
    .content {
      padding: 0 8px;
      p {
        margin-bottom: 8px;
      }
      .nested {
        padding: 0 16px;
        margin: 0;
      }
    }
  }
}
</style>
