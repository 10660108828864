<template>
  <div class="new-calendar">
    <PageHeader title="カレンダー作成" :backIcon="true" />
    <CalendarForm
      @input="calendar = $event"
      :calendar="calendar"
      :place-options="placeOptions"
      :attendees="attendees"
      @submit="submit"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
} from "@vue/composition-api";
import { useAuth } from "@/submodules/auth";
import { useToast } from "@/submodules/toast";
import { usePlaceOptionsQuery } from "./graphql/query/placeOptions.generated";
import { useAttendeesQuery } from "./graphql/query/attendees.generated";
import { useCreateCalendarMutation } from "./graphql/mutation/createCalendar.generated";

import PageHeader from "@/components/PageHeader.vue";
import CalendarForm from "@/components/calendars/CalendarForm.vue";

const { getToken } = useAuth();
const { toastError, setSessionMessage } = useToast();

const fetchPlaceOptions = () => {
  const { result } = usePlaceOptionsQuery({ accessToken: getToken() });
  return computed(() => result.value?.placeOptions);
};
const fetchAttendees = () => {
  const { result } = useAttendeesQuery({ accessToken: getToken() });
  return computed(() => result.value?.attendees);
};
const useCreateCalendar = () => {
  const { mutate: createCalendarMutation } = useCreateCalendarMutation();

  const mutate = async (calendar) => {
    const result = await createCalendarMutation({
      accessToken: getToken(),
      name: calendar.name,
      duration: calendar.duration,
      timeRangeStartHour: calendar.startTime?.substring(0, 2),
      timeRangeStartMinute: calendar.startTime?.substring(3, 5),
      timeRangeEndHour: calendar.endTime?.substring(0, 2),
      timeRangeEndMinute: calendar.endTime?.substring(3, 5),
      placeId: calendar.place.id,
      placeKind: calendar.place.kind,
      otherPlaceName: calendar.otherPlaceName,
      attendeeIds: calendar.attendees.map((attendee) => attendee.id),
      needContactInfo: calendar.needContactInfo,
      webMtgUrl: calendar.webMtgUrl,
    });
    return result.data;
  };

  return {
    mutate,
  };
};

export default defineComponent({
  name: "CreateCalendar",
  components: {
    PageHeader,
    CalendarForm,
  },
  setup(_, { root }) {
    const state = reactive({
      loading: true,
      calendar: {
        name: null,
        duration: null,
        place: {},
        otherPlaceName: null,
        startTime: null,
        endTime: null,
        attendees: [],
      },
      placeOptions: [],
      attendees: [],
    });
    state.attendees = fetchAttendees();
    state.placeOptions = fetchPlaceOptions();
    const useCreateCalendarHandler = useCreateCalendar();
    const submit = async () => {
      try {
        const createCalendarResult = await useCreateCalendarHandler.mutate(
          state.calendar
        );
        const calendarId = createCalendarResult.createCalendar.id;
        setSessionMessage("カレンダーを作成しました");
        root.$router.push("/calendars/" + calendarId);
      } catch (error) {
        console.error(error);
        toastError();
      }
    };

    return {
      ...toRefs(state),
      submit,
    };
  },
});
</script>

<style lang="scss" scoped>
.calendar-form {
  padding-bottom: 64px;
}
</style>
