<template>
  <div class="places">
    <PageHeader title="よくあるご質問" :backIcon="true" to="/settings" />
    <div class="contents-wrapper">
      <div class="panel">
        <div class="panel-heading header-wrapper">
          <div>
            <b-icon icon="chat-question-outline" />
          </div>
          <div class="text-section">
            <p class="is-size-6_5">
              【ご質問】スケジュールを入れていないのに、カレンダー上で一日中スケジュールが埋まっているように表示されます。なぜですか？
            </p>
          </div>
        </div>
        <div class="panel-block content-wrapper">
          <div>
            <b-icon icon="chat-alert-outline" />
          </div>
          <div class="text-section">
            <p class="is-size-6_5">
              【回答】Googleカレンダーで終日スケジュールを入れている場合は、アポとるのカレンダーでは一日中スケジュールが埋まっている扱いになります。
            </p>
          </div>
        </div>
      </div>
      <div class="panel">
        <div class="panel-heading header-wrapper">
          <div>
            <b-icon icon="chat-question-outline" />
          </div>
          <div class="text-section">
            <p class="is-size-6_5">
              【ご質問】リリースされた機能を使うことができないのですが、なぜですか？
            </p>
          </div>
        </div>
        <div class="panel-block content-wrapper">
          <div>
            <b-icon icon="chat-alert-outline" />
          </div>
          <div class="text-section">
            <p class="is-size-6_5">
              【回答】アポとるをリロードするか、一度ログアウトしてからログインし直し、最新バージョンに更新して下さい。
            </p>
          </div>
        </div>
      </div>
      <div class="panel">
        <div class="panel-heading header-wrapper">
          <div>
            <b-icon icon="chat-question-outline" />
          </div>
          <div class="text-section">
            <p class="is-size-6_5">
              【ご質問】購入した有料プランを解約したいのですが、どうすればいいでしょうか？
            </p>
          </div>
        </div>
        <div class="panel-block content-wrapper">
          <div>
            <b-icon icon="chat-alert-outline" />
          </div>
          <div class="text-section">
            <p class="is-size-6_5">
              【回答】アポとるの『その他』=>『プラン変更』に進み、現在の料金プランを削除してください。
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent } from "@vue/composition-api";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: "Rules",
  components: {
    PageHeader,
    Footer,
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.contents-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
  text-align: left;
  .contents {
    padding: 8px 0;
    .content {
      padding: 0 8px;
      p {
        margin-bottom: 8px;
      }
      .nested {
        padding: 0 16px;
        margin: 0;
      }
    }
  }
}
.header-wrapper {
  display: flex;
  align-items: center;
  padding: 8px;
}
.content-wrapper {
  padding: 8px;
}
.text-section {
  padding-left: 8px;
}
</style>
