




























import { defineComponent } from "@vue/composition-api";
import Slide1 from "./welcome/Slide1.vue";
import Slide2 from "./welcome/Slide2.vue";
import Slide3 from "./welcome/Slide3.vue";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "Welcome",
  components: {
    Slide1,
    Slide2,
    Slide3,
    Header,
  },
  setup(_, { root }) {
    const login = () => {
      let url =
        window.location.origin +
        "/google_auth/redirect?client_type=pwa&base_url=" +
        window.location.origin;
      if (root.$route.query.agency_id) {
        url += "&agency_id=" + root.$route.query.agency_id;
      }
      location.href = url;
    };
    const currentSlide = (index) => {
      return `slide${index}`;
    };

    return {
      login,
      currentSlide,
    };
  },
});
