import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type ProfileQueryVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
}>;


export type ProfileQuery = (
  { __typename?: 'Query' }
  & { profile?: Types.Maybe<(
    { __typename?: 'Profile' }
    & Pick<Types.Profile, 'id' | 'organization' | 'name'>
  )> }
);


export const ProfileDocument = gql`
    query profile($accessToken: String!) {
  profile(accessToken: $accessToken) {
    id
    organization
    name
  }
}
    `;

/**
 * __useProfileQuery__
 *
 * To run a query within a Vue component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProfileQuery({
 *   accessToken: // value for 'accessToken'
 * });
 */
export function useProfileQuery(variables: ProfileQueryVariables | VueCompositionApi.Ref<ProfileQueryVariables> | ReactiveFunction<ProfileQueryVariables>, options: VueApolloComposable.UseQueryOptions<ProfileQuery, ProfileQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProfileQuery, ProfileQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProfileQuery, ProfileQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, variables, options);
}
export type ProfileQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ProfileQuery, ProfileQueryVariables>;