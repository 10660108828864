<template>
  <div class="adjustable-time-periods">
    <PageHeader
      title="受付可能な時間帯の設定編集"
      :backIcon="true"
      to="/adjustable_time_periods"
    />
    <div class="contents-wrapper">
      <b-loading :is-full-page="false" :active="loading" />
      <div class="detail-contents">
        <div class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">
              受付可能な時間帯の設定方法
            </p>
          </div>
          <div class="value radio-buttons-wrapper">
            <b-radio
              v-model="adjustableTimePeriods.periodType"
              name="name"
              native-value="daily"
            >
              毎日同じ時間帯で設定する
            </b-radio>
            <b-radio
              v-model="adjustableTimePeriods.periodType"
              name="name"
              native-value="weekly"
            >
              曜日ごとに時間帯を設定する
            </b-radio>
          </div>
        </div>
      </div>
      <div class="detail-contents">
        <div class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">時間帯の設定</p>
          </div>
          <div v-if="isDaily" class="periods-wrapper">
            <div class="period">
              <p class="day is-size-7 has-text-weight-bold">毎日</p>
              <b-select
                v-model="dailyStartTime"
                class="form-item"
                placeholder="時間を選択して下さい"
              >
                <option
                  v-for="time in filteredTimes"
                  :value="time.val"
                  :key="time.val"
                >
                  {{ time.text }}
                </option>
              </b-select>
              <p class="range-text">-</p>
              <b-select
                v-model="dailyEndTime"
                class="form-item"
                placeholder="時間を選択して下さい"
              >
                <option
                  v-for="time in filteredTimes"
                  :value="time.val"
                  :key="time.val"
                >
                  {{ time.text }}
                </option>
              </b-select>
            </div>
          </div>
          <div v-else class="periods-wrapper">
            <div v-if="isExistingPeriod(0)" class="period">
              <p class="day is-size-7 has-text-weight-bold">月曜</p>
              <b-select
                v-model="monStartTime"
                class="form-item"
                placeholder="時間を選択して下さい"
              >
                <option
                  v-for="time in filteredTimes"
                  :value="time.val"
                  :key="time.val"
                >
                  {{ time.text }}
                </option>
              </b-select>
              <p class="range-text">-</p>
              <b-select
                v-model="monEndTime"
                class="form-item"
                placeholder="時間を選択して下さい"
              >
                <option
                  v-for="time in filteredTimes"
                  :value="time.val"
                  :key="time.val"
                >
                  {{ time.text }}
                </option>
              </b-select>
            </div>
            <div v-if="isExistingPeriod(1)" class="period">
              <p class="day is-size-7 has-text-weight-bold">火曜</p>
              <b-select
                v-model="tuesStartTime"
                class="form-item"
                placeholder="時間を選択して下さい"
              >
                <option
                  v-for="time in filteredTimes"
                  :value="time.val"
                  :key="time.val"
                >
                  {{ time.text }}
                </option>
              </b-select>
              <p class="range-text">-</p>
              <b-select
                v-model="tuesEndTime"
                class="form-item"
                placeholder="時間を選択して下さい"
              >
                <option
                  v-for="time in filteredTimes"
                  :value="time.val"
                  :key="time.val"
                >
                  {{ time.text }}
                </option>
              </b-select>
            </div>
            <div v-if="isExistingPeriod(2)" class="period">
              <p class="day is-size-7 has-text-weight-bold">水曜</p>
              <b-select
                v-model="wednesStartTime"
                class="form-item"
                placeholder="時間を選択して下さい"
              >
                <option
                  v-for="time in filteredTimes"
                  :value="time.val"
                  :key="time.val"
                >
                  {{ time.text }}
                </option>
              </b-select>
              <p class="range-text">-</p>
              <b-select
                v-model="wednesEndTime"
                class="form-item"
                placeholder="時間を選択して下さい"
              >
                <option
                  v-for="time in filteredTimes"
                  :value="time.val"
                  :key="time.val"
                >
                  {{ time.text }}
                </option>
              </b-select>
            </div>
            <div v-if="isExistingPeriod(3)" class="period">
              <p class="day is-size-7 has-text-weight-bold">木曜</p>
              <b-select
                v-model="thursStartTime"
                class="form-item"
                placeholder="時間を選択して下さい"
              >
                <option
                  v-for="time in filteredTimes"
                  :value="time.val"
                  :key="time.val"
                >
                  {{ time.text }}
                </option>
              </b-select>
              <p class="range-text">-</p>
              <b-select
                v-model="thursEndTime"
                class="form-item"
                placeholder="時間を選択して下さい"
              >
                <option
                  v-for="time in filteredTimes"
                  :value="time.val"
                  :key="time.val"
                >
                  {{ time.text }}
                </option>
              </b-select>
            </div>
            <div v-if="isExistingPeriod(4)" class="period">
              <p class="day is-size-7 has-text-weight-bold">金曜</p>
              <b-select
                v-model="friStartTime"
                class="form-item"
                placeholder="時間を選択して下さい"
              >
                <option
                  v-for="time in filteredTimes"
                  :value="time.val"
                  :key="time.val"
                >
                  {{ time.text }}
                </option>
              </b-select>
              <p class="range-text">-</p>
              <b-select
                v-model="friEndTime"
                class="form-item"
                placeholder="時間を選択して下さい"
              >
                <option
                  v-for="time in filteredTimes"
                  :value="time.val"
                  :key="time.val"
                >
                  {{ time.text }}
                </option>
              </b-select>
            </div>
            <div v-if="isExistingPeriod(5)" class="period">
              <p class="day is-size-7 has-text-weight-bold">土曜</p>
              <b-select
                v-model="saturStartTime"
                class="form-item"
                placeholder="時間を選択して下さい"
              >
                <option
                  v-for="time in filteredTimes"
                  :value="time.val"
                  :key="time.val"
                >
                  {{ time.text }}
                </option>
              </b-select>
              <p class="range-text">-</p>
              <b-select
                v-model="saturEndTime"
                class="form-item"
                placeholder="時間を選択して下さい"
              >
                <option
                  v-for="time in filteredTimes"
                  :value="time.val"
                  :key="time.val"
                >
                  {{ time.text }}
                </option>
              </b-select>
            </div>
            <div v-if="isExistingPeriod(6)" class="period">
              <p class="day is-size-7 has-text-weight-bold">日曜</p>
              <b-select
                v-model="sunStartTime"
                class="form-item"
                placeholder="時間を選択して下さい"
              >
                <option
                  v-for="time in filteredTimes"
                  :value="time.val"
                  :key="time.val"
                >
                  {{ time.text }}
                </option>
              </b-select>
              <p class="range-text">-</p>
              <b-select
                v-model="sunEndTime"
                class="form-item"
                placeholder="時間を選択して下さい"
              >
                <option
                  v-for="time in filteredTimes"
                  :value="time.val"
                  :key="time.val"
                >
                  {{ time.text }}
                </option>
              </b-select>
            </div>
          </div>
        </div>
      </div>
      <b-button class="edit-button" expanded @click="onClickSaveButton">
        <p class="text is-size-6_5 has-text-weight-bold">
          受付可能な時間帯の設定を保存する
        </p>
      </b-button>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
} from "@vue/composition-api";

import { useAuth } from "@/submodules/auth";
import { useToast } from "@/submodules/toast";
import { useAdjustableTimePeriodsQuery } from "./graphql/query/adjustableTimePeriods.generated";
import { useSaveAdjustableTimePeriodsMutation } from "./graphql/mutation/saveAdjustableTimePeriods.generated.ts";
import { times } from "@/submodules/constants";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";

const { getToken } = useAuth();
const { toastError, setSessionMessage } = useToast();
const fetchAdjustableTimePeriods = () => {
  const adjustableTimePeriods = ref({});
  const { onResult, loading } = useAdjustableTimePeriodsQuery({
    accessToken: getToken(),
  });
  onResult((result) => {
    console.log("result", result);
    adjustableTimePeriods.value = result.data.adjustableTimePeriods;
  });

  return {
    adjustableTimePeriods,
    loading,
  };
};
const filteredTimes = times.filter((time) => time.val);
const paddedTime = (time) => {
  if (!time) return "";
  return ("0" + time).slice(-2);
};
const mapToPeriodType = (period) => {
  return {
    endHour: period.endHour,
    endMinute: period.endMinute,
    startHour: period.startHour,
    startMinute: period.startMinute,
    wday: period.wday,
  };
};
const useAdjustableTimePeriodsMutation = () => {
  const {
    mutate: saveAdjustableTimePeriodsMutate,
  } = useSaveAdjustableTimePeriodsMutation();
  const saveAdjustableTimePeriods = (adjustableTimePeriods) => {
    const result = saveAdjustableTimePeriodsMutate({
      accessToken: getToken(),
      periodType: adjustableTimePeriods.periodType,
      dailyTimePeriods: {
        dailyTimePeriods: adjustableTimePeriods.dailyTimePeriods.map((period) =>
          mapToPeriodType(period)
        ),
      },
      weeklyTimePeriods: {
        weeklyTimePeriods: adjustableTimePeriods.weeklyTimePeriods.map(
          (period) => mapToPeriodType(period)
        ),
      },
    });

    return result;
  };

  return saveAdjustableTimePeriods;
};

export default defineComponent({
  name: "AdjustableTimePeriods",
  components: {
    PageHeader,
    Footer,
  },
  setup(_, { root }) {
    const state = reactive({
      loading: true,
      adjustableTimePeriods: {
        periodType: "",
        dailyTimePeriods: [],
        weeklyTimePeriods: [],
      },
    });

    const { adjustableTimePeriods, loading } = fetchAdjustableTimePeriods();
    state.adjustableTimePeriods = adjustableTimePeriods;
    state.loading = loading;

    const isDaily = computed(() => {
      if (!state.adjustableTimePeriods.periodType) return false;
      return state.adjustableTimePeriods.periodType === "daily";
    });
    const getTimeRange = (period) => {
      if (!period?.startHour) return "";
      return `${period.startHour}時${period.startMinute}分 - ${period.endHour}時${period.endMinute}分`;
    };
    const getWday = (period) => {
      if (!period) return "";
      return period.wday;
    };
    const isExistingPeriod = (index) => {
      return !!state.adjustableTimePeriods?.weeklyTimePeriods?.[index];
    };
    const monStartTime = computed({
      get: () => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[0]) return "";
        const period = state.adjustableTimePeriods.weeklyTimePeriods[0];

        return `${paddedTime(period.startHour)}:${paddedTime(
          period.startMinute
        )}`;
      },
      set: (time) => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[0]) return;
        const period = state.adjustableTimePeriods.weeklyTimePeriods[0];
        period.startHour = time.slice(0, 2);
        period.startMinute = time.slice(-2);

        return;
      },
    });
    const monEndTime = computed({
      get: () => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[0]) return "";
        const period = state.adjustableTimePeriods.weeklyTimePeriods[0];

        return `${paddedTime(period.endHour)}:${paddedTime(period.endMinute)}`;
      },
      set: (time) => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[0]) return;
        const period = state.adjustableTimePeriods.weeklyTimePeriods[0];
        period.endHour = time.slice(0, 2);
        period.endMinute = time.slice(-2);

        return;
      },
    });
    const tuesStartTime = computed({
      get: () => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[1]) return "";
        const period = state.adjustableTimePeriods.weeklyTimePeriods[1];

        return `${paddedTime(period.startHour)}:${paddedTime(
          period.startMinute
        )}`;
      },
      set: (time) => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[1]) return;
        const period = state.adjustableTimePeriods.weeklyTimePeriods[1];
        period.startHour = time.slice(0, 2);
        period.startMinute = time.slice(-2);

        return;
      },
    });
    const tuesEndTime = computed({
      get: () => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[1]) return "";
        const period = state.adjustableTimePeriods.weeklyTimePeriods[1];

        return `${paddedTime(period.endHour)}:${paddedTime(period.endMinute)}`;
      },
      set: (time) => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[1]) return;
        const period = state.adjustableTimePeriods.weeklyTimePeriods[1];
        period.endHour = time.slice(0, 2);
        period.endMinute = time.slice(-2);

        return;
      },
    });
    const wednesStartTime = computed({
      get: () => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[2]) return "";
        const period = state.adjustableTimePeriods.weeklyTimePeriods[2];

        return `${paddedTime(period.startHour)}:${paddedTime(
          period.startMinute
        )}`;
      },
      set: (time) => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[2]) return;
        const period = state.adjustableTimePeriods.weeklyTimePeriods[2];
        period.startHour = time.slice(0, 2);
        period.startMinute = time.slice(-2);

        return;
      },
    });
    const wednesEndTime = computed({
      get: () => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[2]) return "";
        const period = state.adjustableTimePeriods.weeklyTimePeriods[2];

        return `${paddedTime(period.endHour)}:${paddedTime(period.endMinute)}`;
      },
      set: (time) => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[2]) return;
        const period = state.adjustableTimePeriods.weeklyTimePeriods[2];
        period.endHour = time.slice(0, 2);
        period.endMinute = time.slice(-2);

        return;
      },
    });
    const thursStartTime = computed({
      get: () => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[3]) return "";
        const period = state.adjustableTimePeriods.weeklyTimePeriods[3];

        return `${paddedTime(period.startHour)}:${paddedTime(
          period.startMinute
        )}`;
      },
      set: (time) => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[3]) return;
        const period = state.adjustableTimePeriods.weeklyTimePeriods[3];
        period.startHour = time.slice(0, 2);
        period.startMinute = time.slice(-2);

        return;
      },
    });
    const thursEndTime = computed({
      get: () => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[3]) return "";
        const period = state.adjustableTimePeriods.weeklyTimePeriods[3];

        return `${paddedTime(period.endHour)}:${paddedTime(period.endMinute)}`;
      },
      set: (time) => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[3]) return;
        const period = state.adjustableTimePeriods.weeklyTimePeriods[3];
        period.endHour = time.slice(0, 2);
        period.endMinute = time.slice(-2);

        return;
      },
    });
    const friStartTime = computed({
      get: () => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[4]) return "";
        const period = state.adjustableTimePeriods.weeklyTimePeriods[4];

        return `${paddedTime(period.startHour)}:${paddedTime(
          period.startMinute
        )}`;
      },
      set: (time) => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[4]) return;
        const period = state.adjustableTimePeriods.weeklyTimePeriods[4];
        period.startHour = time.slice(0, 2);
        period.startMinute = time.slice(-2);

        return;
      },
    });
    const friEndTime = computed({
      get: () => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[4]) return "";
        const period = state.adjustableTimePeriods.weeklyTimePeriods[4];

        return `${paddedTime(period.endHour)}:${paddedTime(period.endMinute)}`;
      },
      set: (time) => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[4]) return;
        const period = state.adjustableTimePeriods.weeklyTimePeriods[4];
        period.endHour = time.slice(0, 2);
        period.endMinute = time.slice(-2);

        return;
      },
    });
    const saturStartTime = computed({
      get: () => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[5]) return "";
        const period = state.adjustableTimePeriods.weeklyTimePeriods[5];

        return `${paddedTime(period.startHour)}:${paddedTime(
          period.startMinute
        )}`;
      },
      set: (time) => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[5]) return;
        const period = state.adjustableTimePeriods.weeklyTimePeriods[5];
        period.startHour = time.slice(0, 2);
        period.startMinute = time.slice(-2);

        return;
      },
    });
    const saturEndTime = computed({
      get: () => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[5]) return "";
        const period = state.adjustableTimePeriods.weeklyTimePeriods[5];

        return `${paddedTime(period.endHour)}:${paddedTime(period.endMinute)}`;
      },
      set: (time) => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[5]) return;
        const period = state.adjustableTimePeriods.weeklyTimePeriods[5];
        period.endHour = time.slice(0, 2);
        period.endMinute = time.slice(-2);

        return;
      },
    });
    const sunStartTime = computed({
      get: () => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[6]) return "";
        const period = state.adjustableTimePeriods.weeklyTimePeriods[6];

        return `${paddedTime(period.startHour)}:${paddedTime(
          period.startMinute
        )}`;
      },
      set: (time) => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[6]) return;
        const period = state.adjustableTimePeriods.weeklyTimePeriods[6];
        period.startHour = time.slice(0, 2);
        period.startMinute = time.slice(-2);

        return;
      },
    });
    const sunEndTime = computed({
      get: () => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[6]) return "";
        const period = state.adjustableTimePeriods.weeklyTimePeriods[6];

        return `${paddedTime(period.endHour)}:${paddedTime(period.endMinute)}`;
      },
      set: (time) => {
        if (!state.adjustableTimePeriods?.weeklyTimePeriods?.[6]) return;
        const period = state.adjustableTimePeriods.weeklyTimePeriods[6];
        period.endHour = time.slice(0, 2);
        period.endMinute = time.slice(-2);

        return;
      },
    });
    const dailyStartTime = computed({
      get: () => {
        if (!state.adjustableTimePeriods?.dailyTimePeriods?.[0]) return "";
        const period = state.adjustableTimePeriods.dailyTimePeriods[0];

        return `${paddedTime(period.startHour)}:${paddedTime(
          period.startMinute
        )}`;
      },
      set: (time) => {
        if (!state.adjustableTimePeriods?.dailyTimePeriods?.[0]) return;
        const period = state.adjustableTimePeriods.dailyTimePeriods[0];
        period.startHour = time.slice(0, 2);
        period.startMinute = time.slice(-2);

        return;
      },
    });
    const dailyEndTime = computed({
      get: () => {
        if (!state.adjustableTimePeriods?.dailyTimePeriods?.[0]) return "";
        const period = state.adjustableTimePeriods.dailyTimePeriods[0];

        return `${paddedTime(period.endHour)}:${paddedTime(period.endMinute)}`;
      },
      set: (time) => {
        if (!state.adjustableTimePeriods?.dailyTimePeriods?.[0]) return;
        const period = state.adjustableTimePeriods.dailyTimePeriods[0];
        period.endHour = time.slice(0, 2);
        period.endMinute = time.slice(-2);

        return;
      },
    });
    const saveAdjustableTimePeriods = useAdjustableTimePeriodsMutation();
    const onClickSaveButton = async () => {
      try {
        await saveAdjustableTimePeriods(state.adjustableTimePeriods);
        setSessionMessage("受付可能な時間帯の設定を保存しました");
        root.$router.push("/adjustable_time_periods");
      } catch (error) {
        console.error(error);
        toastError();
      }
    };

    return {
      ...toRefs(state),
      isDaily,
      getTimeRange,
      filteredTimes,
      getWday,
      isExistingPeriod,
      monStartTime,
      monEndTime,
      tuesStartTime,
      tuesEndTime,
      wednesStartTime,
      wednesEndTime,
      thursStartTime,
      thursEndTime,
      friStartTime,
      friEndTime,
      saturStartTime,
      saturEndTime,
      sunStartTime,
      sunEndTime,
      dailyStartTime,
      dailyEndTime,
      onClickSaveButton,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
}
.detail-contents {
  > .detail {
    width: 100%;
    text-align: left;
    padding: 8px 0;
    display: block;
    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .periods-wrapper {
      padding-top: 4px;
      .period {
        display: flex;
        align-items: center;
        padding-bottom: 2px;
        .day {
          padding-right: 8px;
        }
        .time {
          padding-left: 8px;
        }
        .range-text {
          padding: 0 8px;
        }
      }
    }
    .radio-buttons-wrapper {
      display: flex;
      flex-direction: column;
      padding: 8px 0 8px;
      .radio {
        padding-top: 4px;
      }
    }
  }
}
::v-deep .button {
  margin-top: 16px;
  color: white;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      color: white;
    }
  }
}
.edit-button {
  margin-top: 16px;
  background-color: colors(green);
  color: white;
}
</style>
