var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-schedule"},[_c('PageHeader',{attrs:{"title":"予約カレンダー","backIcon":_vm.loggedIn()}}),(_vm.calendar.isAvailable && _vm.calendar.status == 'active')?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"content-wrapper"},[_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.isCalendarLoading}}),_c('CalendarRow',{attrs:{"calendar-name":_vm.calendarName}}),_c('div',{staticClass:"calendar-details"},[_c('div',{staticClass:"detail"},[_c('div',{staticClass:"name"},[_c('p',{staticClass:"is-size-7 has-text-weight-bold"},[_vm._v("必要な時間")])]),_c('div',{staticClass:"value"},[_c('p',{staticClass:"is-size-6"},[_vm._v(_vm._s((_vm.calendarDuration + "分")))])])]),(_vm.isPlaceDetermined)?_c('div',{staticClass:"detail"},[_c('div',{staticClass:"name"},[_c('p',{staticClass:"is-size-7 has-text-weight-bold"},[_vm._v("場所")])]),_c('div',{staticClass:"value"},[_c('p',{staticClass:"is-size-6"},[_vm._v(_vm._s(_vm.placeName))])])]):_vm._e()]),_c('div',{staticClass:"form-wrapper"},[_c('div',{staticClass:"title-area"},[_c('span',{staticClass:"is-size-6_5 has-text-weight-bold label-text"},[_vm._v("下記内容をご入力下さい")])]),_c('ValidationProvider',{attrs:{"rules":"required","name":"日時選択"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"form-item",attrs:{"type":{ 'is-danger': errors[0] },"message":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"labels-wrapper"},[_c('div',{staticClass:"label-wrapper"},[_c('span',{staticClass:"label-text"},[_vm._v("日時選択")]),_c('b-tag',{attrs:{"type":"is-danger"}},[_vm._v("必須")])],1),(!_vm.datetimeNotSelected)?_c('div',{staticClass:"label-wrapper link-text",on:{"click":_vm.clearSelectedDatetime}},[_c('EditCalendarIcon'),_c('span',[_vm._v("変更")])],1):_vm._e()])]},proxy:true}],null,true)},[(!_vm.datetimeNotSelected)?_c('b-input',{staticClass:"form-item",attrs:{"disabled":""},model:{value:(_vm.computedSelectedDatetime),callback:function ($$v) {_vm.computedSelectedDatetime=$$v},expression:"computedSelectedDatetime"}}):_vm._e(),(_vm.datetimeNotSelected)?_c('CalendarSheet',{attrs:{"calendar-data":_vm.calendarData,"isLoading":_vm.isCalendarDataLodaing},on:{"update-selected-datetime":function($event){_vm.selectedDatetime = $event}}}):_vm._e()],1)]}}],null,true)}),(!_vm.loggedIn())?_c('ValidationProvider',{attrs:{"rules":"required","name":"お名前"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"form-item",attrs:{"type":{ 'is-danger': errors[0] },"message":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"label-text"},[_vm._v("お名前")]),_c('b-tag',{attrs:{"type":"is-danger"}},[_vm._v("必須")])]},proxy:true}],null,true)},[_c('b-input',{staticClass:"form-item",attrs:{"placeholder":"例）アポとる 太郎"},model:{value:(_vm.schedule.requesteeName),callback:function ($$v) {_vm.$set(_vm.schedule, "requesteeName", $$v)},expression:"schedule.requesteeName"}})],1)]}}],null,true)}):_vm._e(),(!_vm.isPlaceDetermined)?_c('ValidationProvider',{attrs:{"rules":"required","name":"場所"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"form-item",attrs:{"type":{ 'is-danger': errors[0] },"message":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"label-text"},[_vm._v("場所選択")]),_c('b-tag',{attrs:{"type":"is-danger"}},[_vm._v("必須")])]},proxy:true}],null,true)},[_c('b-select',{staticClass:"form-item",attrs:{"placeholder":"場所を選択して下さい"},model:{value:(_vm.schedule.place),callback:function ($$v) {_vm.$set(_vm.schedule, "place", $$v)},expression:"schedule.place"}},_vm._l((_vm.placeOptions),function(place){return _c('option',{key:place.id,domProps:{"value":place}},[_vm._v(" "+_vm._s(place.name)+" ")])}),0)],1)]}}],null,true)}):_vm._e(),(_vm.isPlaceKindOther)?_c('ValidationProvider',{attrs:{"rules":"required","name":"場所（自由入力）"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"form-item",attrs:{"type":{ 'is-danger': errors[0] },"message":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"label-text"},[_vm._v("場所（自由入力）")]),_c('b-tag',{attrs:{"type":"is-danger"}},[_vm._v("必須")])]},proxy:true}],null,true)},[_c('b-input',{staticClass:"form-item",attrs:{"placeholder":"例）○○駅前の××カフェ"},model:{value:(_vm.schedule.otherPlaceName),callback:function ($$v) {_vm.$set(_vm.schedule, "otherPlaceName", $$v)},expression:"schedule.otherPlaceName"}})],1)]}}],null,true)}):_vm._e(),(_vm.calendar.needContactInfo)?_c('ValidationProvider',{attrs:{"rules":"required|phone","name":"電話番号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"form-item",attrs:{"type":{ 'is-danger': errors[0] },"message":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"label-text"},[_vm._v("連絡先電話番号")]),_c('b-tag',{attrs:{"type":"is-danger"}},[_vm._v("必須")])]},proxy:true}],null,true)},[_c('b-input',{staticClass:"form-item",attrs:{"placeholder":"例）090-xxxx-xxxx"},model:{value:(_vm.schedule.tel),callback:function ($$v) {_vm.$set(_vm.schedule, "tel", $$v)},expression:"schedule.tel"}})],1)]}}],null,true)}):_vm._e(),(!_vm.loggedIn())?_c('ValidationProvider',{attrs:{"rules":"required|email","name":"メールアドレス"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"form-item",attrs:{"type":{ 'is-danger': errors[0] },"message":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"label-text"},[_vm._v("メールアドレス（設定すると予約内容詳細がメールで送信されます）")]),_c('b-tag',{attrs:{"type":"is-danger"}},[_vm._v("必須")])]},proxy:true}],null,true)},[_c('b-input',{staticClass:"form-item",attrs:{"placeholder":"例）test@test.com"},model:{value:(_vm.schedule.emailForNonLoginUser),callback:function ($$v) {_vm.$set(_vm.schedule, "emailForNonLoginUser", $$v)},expression:"schedule.emailForNonLoginUser"}})],1)]}}],null,true)}):_vm._e()],1),_c('b-button',{staticClass:"createschedule-button",attrs:{"expanded":"","disabled":invalid || !_vm.computedSelectedDatetime || _vm.isCalendarLoading},on:{"click":_vm.createSchedule}},[_c('CheckedCalendarIcon'),_c('p',{staticClass:"text is-size-6_5 has-text-weight-bold"},[_vm._v("アポ予約を入れる")])],1)],1)]}}],null,false,336504611)}):(_vm.calendar.status == 'deleted')?_c('div',{staticClass:"alert-section"},[_c('article',{staticClass:"message is-danger"},[_c('div',{staticClass:"message-body is-size-6_5 has-text-weight-bold"},[_vm._v(" このカレンダーは削除済みです ")])])]):(!_vm.isCalendarLoading)?_c('div',{staticClass:"alert-section"},[_c('article',{staticClass:"message is-danger"},[_c('div',{staticClass:"message-body is-size-6_5 has-text-weight-bold"},[_vm._v(" このカレンダーは現在無効です ")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }