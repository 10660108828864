<template>
  <div class="calendar">
    <PageHeader title="カレンダー詳細" :backIcon="true" />
    <div class="calendar-wrapper">
      <!-- <b-loading :is-full-page="false" :active="loading" /> -->
      <CalendarRow :calendar="calendar" />
      <div class="calendar-details">
        <div class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">必要な時間</p>
          </div>
          <div class="value">
            <p class="is-size-6">{{ `${calendar.duration || ''}分` }}</p>
          </div>
        </div>
        <div class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">場所</p>
          </div>
          <div class="value">
            <p class="is-size-6">{{ placeName }}</p>
          </div>
        </div>
        <div v-if="startTime" class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">
              相手が選択可能な時間帯（開始）
            </p>
          </div>
          <div class="value">
            <p class="is-size-6">{{ startTime }}</p>
          </div>
        </div>
        <div v-if="endTime" class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">
              相手が選択可能な時間帯（終了）
            </p>
          </div>
          <div class="value">
            <p class="is-size-6">{{ endTime }}</p>
          </div>
        </div>
        <div v-if="attendees.length" class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">同行者</p>
          </div>
          <div v-for="attendee in attendees" :key="attendee.id" class="value">
            <p class="is-size-6">{{ attendee.name }}</p>
          </div>
        </div>
        <div v-if="needContactInfo" class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">連絡先設定</p>
          </div>
          <div class="value">
            <p class="is-size-6">電話番号は必須入力です</p>
          </div>
        </div>
      </div>

      <div class="copy-button-wrapper">
        <b-tooltip v-if="isCreateCalendarStep(8)" :class="{ 'top-index': isCreateCalendarStep(8) }" position="is-bottom" type="is-success" multilined always>
          <template v-slot:content>
            <div><b>STEP3 カレンダーURLコピー(3/3)</b></div>
            <p>このボタンをクリックして</p>
            <p>登録したカレンダーのURLを</p>
            <p>コピーします。</p>
            <p>日程調整相手にURLを送って</p>
            <p>アポ調整にご利用下さい。</p>
            <p>以上でチュートリアルは終了となります。</p>
            <b-button @click="goToNextStep" class="next-button">
              トップページに戻ります
            </b-button>
          </template>
          <b-button class="copy-button" expanded @click="copyCalendarUrl">
            <LinkIcon />
            <p class="text is-size-6_5 has-text-weight-bold">
              カレンダーへのリンクをコピーする
            </p>
          </b-button>
        </b-tooltip>
        <b-button v-else class="copy-button" expanded @click="copyCalendarUrl">
          <LinkIcon />
          <p class="text is-size-6_5 has-text-weight-bold">
            カレンダーへのリンクをコピーする
          </p>
        </b-button>
      </div>

      <b-button class="edit-button" expanded @click="editCalendar">
        <EditCalendarIcon />
        <p class="text is-size-6_5 has-text-weight-bold">
          カレンダーを編集する
        </p>
      </b-button>
      <b-button class="delete-button" expanded @click="deleteCalendar">
        <TrashIcon />
        <p class="text is-size-6_5 has-text-weight-bold">
          カレンダーを削除する
        </p>
      </b-button>
    </div>
    <Footer />
    <b-modal
      v-model="computedShowFinishCreateCalendarTutorialModal"
      :on-cancel="cancelFinishCreateCalendarTutorialModal"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-label="PurchasePlanModal"
      aria-modal
      :can-cancel="['escape', 'outside']"
    >
      <template #default="props">
        <FinishCreateCalendarTutorialModal @go-to-next="goToNextStep" @close="props.close" />
      </template>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
} from "@vue/composition-api";
import { useCalendarQuery } from "../graphql/query/calendar.generated";
import { useAuth } from "@/submodules/auth";
import { useToast } from "@/submodules/toast";
import { useDeleteCalendarMutation } from "../graphql/mutation/deleteCalendar.generated";
import { showConfirmDialog } from "@/submodules/dialog";
import { useTutorialStore } from '@/stores/tutorial'
import { useSaveTutorialStatusMutation } from "../graphql/mutation/saveTutorialStatus.generated.ts";

import PageHeader from "@/components/PageHeader.vue";
import CalendarRow from "@/components/calendars/CalendarRow.vue";
import EditCalendarIcon from "@/assets/img/common/outline_icons/edit_calendar.svg";
import LinkIcon from "@/assets/img/common/outline_icons/link.svg";
import TrashIcon from "@/assets/img/common/outline_icons/trash.svg";
import Footer from "@/components/Footer.vue";
import FinishCreateCalendarTutorialModal from "@/components/tutorial/dashboard/FinishCreateCalendarTutorialModal.vue";

const { getToken } = useAuth();
const isNullable = (val) => {
  return val === null || val === undefined;
};
const { toastMessage, toastError, setSessionMessage } = useToast();
const copyStringToClipBoard = (str) => {
  const ta = document.createElement("textarea");
  ta.value = str;
  document.body.appendChild(ta);

  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    // iOS の場合
    var range = document.createRange();
    range.selectNodeContents(ta);
    var selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    ta.setSelectionRange(0, 999999);
  } else {
    ta.select();
  }

  document.execCommand("copy");
  ta.parentElement.removeChild(ta);
};

export default defineComponent({
  name: "Calendar",
  components: {
    PageHeader,
    CalendarRow,
    EditCalendarIcon,
    Footer,
    LinkIcon,
    TrashIcon,
    FinishCreateCalendarTutorialModal,
  },
  setup(_, { root }) {
    const state = reactive({
      calendar: {
        name: "",
        duration: '',
      },
      placeName: "",
      loading: true,
    });
    const tutorial = useTutorialStore()
    const { onResult } = useCalendarQuery({
      accessToken: getToken(),
      id: root.$route.params.id,
    });
    onResult((result) => {
      const { calendar } = result.data;
      state.calendar = calendar;
      if (calendar.placeKind === "registered") {
        state.placeName = calendar.place.name;
      } else if (calendar.placeKind === "follow_requestee") {
        state.placeName = "相手に選択してもらう";
      } else if (calendar.placeKind === "other") {
        state.placeName = calendar.otherPlaceName;
      }
      state.loading = false;
    });
    const startTime = computed(() => {
      if (
        isNullable(state.calendar?.timeRangeStartHour) ||
        isNullable(state.calendar?.timeRangeStartMinute)
      )
        return null;
      return (
        String(state.calendar?.timeRangeStartHour) +
        ":" +
        ("00" + String(state.calendar?.timeRangeStartMinute)).slice(-2)
      );
    });
    const endTime = computed(() => {
      if (
        isNullable(state.calendar?.timeRangeEndHour) ||
        isNullable(state.calendar?.timeRangeEndMinute)
      )
        return null;
      return (
        String(state.calendar?.timeRangeEndHour) +
        ":" +
        ("00" + String(state.calendar?.timeRangeEndMinute)).slice(-2)
      );
    });
    const attendees = computed(() => {
      if (!state.calendar?.attendees?.length) return [];
      return state.calendar.attendees.map((attendee) => {
        return {
          id: attendee.id,
          name: attendee.profile.name,
        };
      });
    });
    const needContactInfo = computed(() => {
      return state.calendar?.needContactInfo;
    });
    const copyCalendarUrl = () => {
      try {
        copyStringToClipBoard(
          `${window.location.origin}/schedules/new?calendar_id=${state.calendar.id}`
        );
        toastMessage("リンクURLをコピーしました");
      } catch (e) {
        console.error(e);
        toastError("エラーのためコピーできませんでした");
      }
    };
    const editCalendar = () => {
      root.$router.push(`/calendars/${state.calendar.id}/edit`);
    };
    const { mutate: deleteCalendarMutation } = useDeleteCalendarMutation();
    const deleteCalendar = async () => {
      showConfirmDialog("カレンダーを削除しますか？").then(async () => {
        try {
          await deleteCalendarMutation({
            accessToken: getToken(),
            id: state.calendar.id,
          });
          setSessionMessage("カレンダーを削除しました");
          root.$router.push("/calendars/");
        } catch (error) {
          console.error(error);
          toastError();
        }
      });
    };
    const cancelFinishCreateCalendarTutorialModal = () => {
      console.log('cancelFinishCreateCalendarTutorialModal CLICKED!!')
    }
    const computedShowFinishCreateCalendarTutorialModal = computed({
      get: () => tutorial.current === 'CREATE_CALENDAR' && tutorial.step === 7 && !state.loading,
      set: (val) => {
        console.log('cancelFinishCreateCalendarTutorialModal', val)
        tutorial.startTutorial()
        // root.$router.push('/')
      }
    })
    const isCreateCalendarStep = (step) => {
      return step == tutorial.step && tutorial.current == 'CREATE_CALENDAR'
    }
    const { mutate: saveTutorialStatusMutate } = useSaveTutorialStatusMutation()
    const goToNextStep = async () => {
      await tutorial.changeTutorial('COMPLETED', saveTutorialStatusMutate)
      root.$router.push('/')
    }

    return {
      ...toRefs(state),
      startTime,
      endTime,
      attendees,
      needContactInfo,
      copyCalendarUrl,
      editCalendar,
      deleteCalendar,
      cancelFinishCreateCalendarTutorialModal,
      computedShowFinishCreateCalendarTutorialModal,
      isCreateCalendarStep,
      goToNextStep,
    };
  },
});
</script>

<style lang="scss" scoped>
.calendar-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
}
::v-deep .calendar-row {
  > .areas-wrapper {
    border-top: 0;
  }
}
.calendar-details {
  > .detail {
    width: 100%;
    text-align: left;
    padding-top: 16px;
    .name {
      height: 20px;
      white-space: nowrap;
    }

    .value {
    }
  }
}
::v-deep .button {
  margin-top: 16px;
  color: white;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      padding-left: 8px;
    }
  }
}
.copy-button-wrapper {
  padding-top: 32px;
  .copy-button {
    margin-top: 0;
    background-color: colors(blue);
  }
}
.edit-button {
  background-color: colors(green);
}
.delete-button {
  background-color: colors(gray);
}
::v-deep .loading-overlay .loading-background {
  background-color: white;
}
.svg {
  width: 18px;
  height: 18px;
  fill: white;
}
::v-deep .modal .animation-content.modal-content .modal-card {
  margin: 0 auto;
}
::v-deep .b-tooltip {
  width: 100%;
}
.top-index {
  z-index: 10;
  background-color: white;
  ::v-deep .button.next-button {
    height: 28px;
    color: gray;
    margin: 4px;
    font-size: 12px;
  }
}
</style>
