<template>
  <header class="page-header">
    <div class="wrapper">
      <div class="item back-icon">
        <template v-if="backIcon">
          <b-tooltip v-if="isInitialSettingTutorialStep(3) || isInitialSettingTutorialStep(6)" :class="{ 'top-index': isInitialSettingTutorialStep(3) || isInitialSettingTutorialStep(6) }" position="is-right" type="is-success" multilined always>
            <template v-slot:content>
              <div><b>戻るアイコンをクリック</b></div>
            </template>
            <router-link v-if="to" :to="to"><LeftArrowIcon /></router-link>
            <a v-else @click="historyBack"><LeftArrowIcon /></a>
          </b-tooltip>
          <template v-else>
            <router-link v-if="to" :to="to"><LeftArrowIcon /></router-link>
            <a v-else @click="historyBack"><LeftArrowIcon /></a>
          </template>
        </template>
      </div>
      <div class="item title-text">
        <span>{{ title }}</span>
      </div>
      <div class="item" />
    </div>
  </header>
</template>

<script>
// @ is an alias to /src
import { defineComponent } from "@vue/composition-api";
import LeftArrowIcon from "@/assets/img/common/left_arrow.svg";

import { useTutorialStore } from '@/stores/tutorial'

export default defineComponent({
  name: "Header",
  components: {
    LeftArrowIcon
  },
  props: {
    title: {
      type: String,
      required: false,
      default: () => "",
    },
    to: {
      type: String,
      required: false,
      default: () => "",
    },
    backIcon: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  setup(_, { root }) {
    const historyBack = () => {
      root.$router.go(-1);
    };
    const tutorial = useTutorialStore()
    const isInitialSettingTutorialStep = (step) => {
      return tutorial.current === 'INITIAL_SETTING' && tutorial.step == step
    }

    return {
      historyBack,
      isInitialSettingTutorialStep,
    };
  },
});
</script>

<style lang="scss" scoped>
.page-header {
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > .wrapper {
    display: flex;
    flex-direction: row;
    > .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    > .back-icon {
      svg {
        display: block;
      }
    }
    > .title-text {
      white-space: nowrap;
    }
  }
}
.back-icon {
  ::v-deep .b-tooltip {
    width: 30%;
    padding: 4px;
  }
}
.top-index {
  z-index: 10;
  background-color: white;
}
</style>
