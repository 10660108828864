import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type ReadAllNotificationsMutationVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
}>;


export type ReadAllNotificationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'readAllNotifications'>
);


export const ReadAllNotificationsDocument = gql`
    mutation readAllNotifications($accessToken: String!) {
  readAllNotifications(accessToken: $accessToken)
}
    `;

/**
 * __useReadAllNotificationsMutation__
 *
 * To run a mutation, you first call `useReadAllNotificationsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useReadAllNotificationsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useReadAllNotificationsMutation({
 *   variables: {
 *     accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useReadAllNotificationsMutation(options: VueApolloComposable.UseMutationOptions<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>>) {
  return VueApolloComposable.useMutation<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>(ReadAllNotificationsDocument, options);
}
export type ReadAllNotificationsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>;