export const durations = [
  { text: "30分", val: 30 },
  { text: "60分", val: 60 },
  { text: "90分", val: 90 },
  { text: "120分", val: 120 },
  { text: "150分", val: 150 },
  { text: "180分", val: 180 },
  { text: "210分", val: 210 },
  { text: "240分", val: 240 },
  { text: "270分", val: 270 },
  { text: "300分", val: 300 },
];
export const times = [
  { text: "選択なし", val: null },
  { text: "00:00", val: "00:00" },
  { text: "00:30", val: "00:30" },
  { text: "01:00", val: "01:00" },
  { text: "01:30", val: "01:30" },
  { text: "02:00", val: "02:00" },
  { text: "02:30", val: "02:30" },
  { text: "03:00", val: "03:00" },
  { text: "03:30", val: "03:30" },
  { text: "04:00", val: "04:00" },
  { text: "04:30", val: "04:30" },
  { text: "05:00", val: "05:00" },
  { text: "05:30", val: "05:30" },
  { text: "06:00", val: "06:00" },
  { text: "06:30", val: "06:30" },
  { text: "07:00", val: "07:00" },
  { text: "07:30", val: "07:30" },
  { text: "08:00", val: "08:00" },
  { text: "08:30", val: "08:30" },
  { text: "09:00", val: "09:00" },
  { text: "09:30", val: "09:30" },
  { text: "10:00", val: "10:00" },
  { text: "10:30", val: "10:30" },
  { text: "11:00", val: "11:00" },
  { text: "11:30", val: "11:30" },
  { text: "12:00", val: "12:00" },
  { text: "12:30", val: "12:30" },
  { text: "13:00", val: "13:00" },
  { text: "13:30", val: "13:30" },
  { text: "14:00", val: "14:00" },
  { text: "14:30", val: "14:30" },
  { text: "15:00", val: "15:00" },
  { text: "15:30", val: "15:30" },
  { text: "16:00", val: "16:00" },
  { text: "16:30", val: "16:30" },
  { text: "17:00", val: "17:00" },
  { text: "17:30", val: "17:30" },
  { text: "18:00", val: "18:00" },
  { text: "18:30", val: "18:30" },
  { text: "19:00", val: "19:00" },
  { text: "19:30", val: "19:30" },
  { text: "20:00", val: "20:00" },
  { text: "20:30", val: "20:30" },
  { text: "21:00", val: "21:00" },
  { text: "21:30", val: "21:30" },
  { text: "22:00", val: "22:00" },
  { text: "22:30", val: "22:30" },
  { text: "23:00", val: "23:00" },
  { text: "23:30", val: "23:30" },
  { text: "24:00", val: "24:00" },
];