<template>
  <div class="profile">
    <PageHeader title="プロフィール" :backIcon="true" />
    <div class="contents-wrapper">
      <b-loading :is-full-page="false" :active="loading" />
      <div class="detail-contents">
        <div class="detail">
          <b-field>
            <template #label>
              <span class="is-size-7 has-text-weight-bold"
                >所属組織名（会社・団体名）</span
              >
            </template>
            <b-input
              class="form-item"
              v-model="profile.organization"
              placeholder=""
            ></b-input>
          </b-field>
        </div>
        <div class="detail">
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            name="お名前"
          >
            <b-field :type="{ 'is-danger': errors[0] }" :message="errors[0]">
              <template #label>
                <span class="label-text">お名前</span>
                <b-tag type="is-danger">必須</b-tag>
              </template>
              <b-input
                class="form-item"
                v-model="profile.name"
                placeholder="例）定例MTG, 予約コースA etc..."
              ></b-input>
            </b-field>
          </ValidationProvider>
        </div>
      </div>
      <b-button class="save-button" expanded @click="onClickSaveButton">
        <p class="text is-size-6_5 has-text-weight-bold">保存する</p>
      </b-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, reactive, toRefs, ref } from "@vue/composition-api";
import { useAuth } from "@/submodules/auth";

import { useProfileQuery } from "./graphql/query/profile.generated";
import { useSaveProfileMutation } from "./graphql/mutation/saveProfile.generated";
import { useToast } from "@/submodules/toast";

import PageHeader from "@/components/PageHeader.vue";

const { getToken } = useAuth();
const { toastError, setSessionMessage } = useToast();
const fetchProfile = () => {
  const profile = ref({ name: "", organization: "" });
  const { onResult, loading } = useProfileQuery({ accessToken: getToken() });
  onResult((result) => {
    profile.value = result.data.profile;
  });

  return {
    profile,
    loading,
  };
};
const useProfileMutation = () => {
  const { mutate: saveProfileMutate } = useSaveProfileMutation();
  const saveProfile = (organization, name) => {
    const result = saveProfileMutate({
      accessToken: getToken(),
      organization: organization || "",
      name,
    });

    return result;
  };

  return saveProfile;
};

export default defineComponent({
  name: "Profile",
  components: {
    PageHeader,
  },
  setup(_, { root }) {
    const state = reactive({
      loading: true,
      profile: {},
    });

    const { profile, loading } = fetchProfile();
    state.profile = profile;
    state.loading = loading;

    const saveProfile = useProfileMutation();

    const onClickSaveButton = async () => {
      try {
        await saveProfile(state.profile.organization, state.profile.name);
        setSessionMessage("プロフィールを保存しました");
        root.$router.push("/profile");
      } catch (error) {
        console.error(error);
        toastError();
      }
    };

    return {
      ...toRefs(state),
      onClickSaveButton,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
}
.detail-contents {
  > .detail {
    width: 100%;
    text-align: left;
    padding-top: 16px;
    .name {
      height: 20px;
      white-space: nowrap;
    }

    .value {
      height: 20px;
    }
  }
}
::v-deep .button {
  margin-top: 16px;
  color: white;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      padding-left: 8px;
    }
  }
}
.save-button {
  margin-top: 32px;
  background-color: colors(green);
}
::v-deep .tag {
  height: 16px;
  margin-left: 4px;
  color: white;
  &.attendee-tag {
    color: colors(black);
  }
  span {
    font-weight: 700;
  }
}
</style>
