import { useToast } from '../toast'
const { setSessionMessage } = useToast()


export const useAuth = () => {
  const login = (token): void => {
    localStorage.token = token
  }
  const logout = (): void => {
    localStorage.removeItem('token')
    setSessionMessage('ログアウトしました')
  }
  const loggedIn = (): boolean => {
    return !!localStorage.token
  }
  const getToken = (): string => {
    return localStorage.token
  }

  return {
    login,
    logout,
    loggedIn,
    getToken
  }
}