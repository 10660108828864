











































































import { defineComponent, reactive, toRefs } from "@vue/composition-api";
import Slide1 from "./welcome/Slide1.vue";
import Slide2 from "./welcome/Slide2.vue";
import Slide3 from "./welcome/Slide3.vue";
import Header from "@/components/Header.vue";
import { useToast } from "@/submodules/toast";

const { toastMessage, toastError } = useToast();
const copyStringToClipBoard = (str) => {
  const ta = document.createElement("textarea");
  ta.value = str;
  document.body.appendChild(ta);

  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    // iOS の場合
    var range = document.createRange();
    range.selectNodeContents(ta);
    var selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    ta.setSelectionRange(0, 999999);
  } else {
    ta.select();
  }

  document.execCommand("copy");
  ta.parentElement.removeChild(ta);
};

export default defineComponent({
  name: "Welcome",
  components: {
    Slide1,
    Slide2,
    Slide3,
    Header,
  },
  setup(_, { root }) {
    const state = reactive({
      isModalOpen: false,
    })
    const cancelModal = () => {
      state.isModalOpen = false
    }
    const copyCurrentUrl = () => {
      try {
        copyStringToClipBoard(window.location.href);
        state.isModalOpen = false
        toastMessage("リンクURLをコピーしました");
      } catch (e) {
        console.error(e);
        toastError("エラーのためコピーできませんでした");
      }
    };

    const login = () => {
      const userAgent = navigator.userAgent.toLowerCase()
      const isSafari = /safari/.test(userAgent)
      const isChrome = /chrome/.test(userAgent)
      if (!isSafari && !isChrome) {
        state.isModalOpen = true
        return
      }

      let url =
        window.location.origin +
        "/google_auth/redirect?client_type=pwa&base_url=" +
        window.location.origin;
      if (root.$route.query.agency_id) {
        url += "&agency_id=" + root.$route.query.agency_id;
      }
      location.href = url;
    };
    const currentSlide = (index) => {
      return `slide${index}`;
    };

    return {
      ...toRefs(state),
      cancelModal,
      copyCurrentUrl,
      login,
      currentSlide,
    };
  },
});
