<template>
  <header class="header">
    <div class="logo is-size-5 has-text-weight-bold">
      <Logo />
    </div>
    <div v-if="!loading && user.id" class="user">
      <div class="name is-size-6 has-text-weight-bold">
        <span>{{ user.name + "様" }}</span>
      </div>
      <div
        class="picture"
        :style="{ backgroundImage: `url(${user.pictureUrl})` }"
      ></div>
    </div>
    <div v-else class="header-link-wrapper">
      <router-link
        class="areas-wrapper has-text-weight-bold is-size-6_5"
        :to="pathSegment"
        >{{ pageName }}</router-link
      >
    </div>
  </header>
</template>

<script>
// @ is an alias to /src
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
} from "@vue/composition-api";
import { useUserQuery } from "./graphql/query/userPictureUrl.generated.ts";
import { useAuth } from "@/submodules/auth";

import Logo from "@/assets/img/common/logo.svg";

export default defineComponent({
  name: "Header",
  components: {
    Logo,
  },
  setup(_, { root }) {
    const { getToken } = useAuth();
    const { onResult, error } = useUserQuery({
      accessToken: getToken(),
      id: null,
    });
    const state = reactive({
      user: {},
      loading: true,
    });
    onResult((result) => {
      if (result.data?.user) {
        state.user = result.data.user;
      }
      state.loading = false;
    });
    if (error.value) {
      console.error(error.value);
    }
    const pageName = computed(() => {
      if (root.$route.path === "/plans") {
        return "トップへ";
      } else if (root.$route.path === "/") {
        return "プラン一覧";
      } else {
        return "";
      }
    });
    const pathSegment = computed(() => {
      if (root.$route.path === "/plans") {
        return "/";
      } else if (root.$route.path === "/") {
        return "/plans";
      } else {
        return "";
      }
    });

    return {
      ...toRefs(state),
      pageName,
      pathSegment,
    };
  },
});
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 56px;
  display: flex;
  padding: 0 16px;
  align-items: center;
  border-bottom: 1px solid colors(light-gray);
  .logo {
    svg {
      display: block;
    }
  }
  .header-link-wrapper {
    width: 100%;
    text-align: right;
  }
  > .user {
    margin-left: auto;
    display: flex;
    align-items: center;
    > .name {
      padding-right: 8px;
    }
    > .picture {
      height: 40px;
      width: 40px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 50%;
    }
  }
}
</style>
