<template>
  <div>This is DetailSetting.</div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, reactive, toRefs } from "@vue/composition-api";

export default defineComponent({
  name: "DetailSetting",
  components: {},
  setup() {
    const state = reactive({});

    return {
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped></style>
