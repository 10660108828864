<template>
  <div class="calendar-form">
    <ValidationObserver v-slot="{ invalid }">
      <div class="calendar-form-wrapper">
        <b-loading :is-full-page="false" :active="loading" />
        <div class="required-items">
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            name="カレンダー名"
          >
            <b-field :type="{ 'is-danger': errors[0] }" :message="errors[0]">
              <template #label>
                <span class="label-text">カレンダー名</span>
                <b-tag type="is-danger">必須</b-tag>
              </template>
              <b-input
                class="form-item"
                v-model="formInputName"
                placeholder="例）定例MTG, 予約コースA etc..."
              ></b-input>
            </b-field>
          </ValidationProvider>
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            name="必要な時間"
          >
            <b-field :type="{ 'is-danger': errors[0] }" :message="errors[0]">
              <template #label>
                <span class="label-text">必要な時間</span>
                <b-tag type="is-danger">必須</b-tag>
              </template>
              <b-select
                v-model="formInputDuration"
                class="form-item"
                placeholder="時間を選択して下さい"
              >
                <option
                  v-for="duration in durations"
                  :value="duration.val"
                  :key="duration.val"
                >
                  {{ duration.text }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
          <ValidationProvider rules="required" v-slot="{ errors }" name="場所">
            <b-field :type="{ 'is-danger': errors[0] }" :message="errors[0]">
              <template #label>
                <span class="label-text">場所</span>
                <b-tag type="is-danger">必須</b-tag>
              </template>
              <b-select
                v-model="formInputPlace"
                class="form-item"
                placeholder="場所を選択して下さい"
              >
                <option
                  v-for="place in placeOptions"
                  :value="place.id"
                  :key="place.id"
                >
                  {{ place.name }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
          <ValidationProvider
            v-if="calendar.place.kind == 'other'"
            rules="required"
            v-slot="{ errors }"
            name="場所（自由入力）"
          >
            <b-field
              label="場所（自由入力）"
              :type="{ 'is-danger': errors[0] }"
              :message="errors[0]"
            >
              <b-input
                class="form-item"
                v-model="formInputOtherPlaceName"
                placeholder="例）○○駅前の××カフェ"
              />
            </b-field>
          </ValidationProvider>
        </div>
        <div class="optional-items">
          <b-collapse
            animation="slide"
            :open="isOptionSpecified || isOptionFormOpen"
            @open="isOptionFormOpen = !isOptionFormOpen"
            @close="isOptionFormOpen = !isOptionFormOpen"
          >
            <template #trigger="props">
              <div class="collapse-area">
                <div class="title-area">
                  <span class="is-size-6_5 has-text-weight-bold label-text"
                    >詳細設定</span
                  >
                  <b-tag type="is-info">任意</b-tag>
                  <b-icon :icon="props.open ? 'menu-down' : 'menu-up'" />
                </div>
              </div>
            </template>
            <b-field label="相手が選択可能な時間帯（開始）">
              <b-select
                v-model="formInputStartTime"
                class="form-item"
                placeholder="開始時間を選択して下さい"
              >
                <option v-for="time in times" :value="time.val" :key="time.val">
                  {{ time.text }}
                </option>
              </b-select>
            </b-field>
            <b-field label="相手が選択可能な時間帯（終了）">
              <b-select
                v-model="formInputEndTime"
                class="form-item"
                placeholder="終了時間を選択して下さい"
              >
                <option v-for="time in times" :value="time.val" :key="time.val">
                  {{ time.text }}
                </option>
              </b-select>
            </b-field>
            <b-field>
              <template #label>
                <span class="label-text">同行者を設定</span>
                <b-tooltip
                  label="過去にアポ調整をしたことがあるアポとるユーザーがリストに表示されます。指定すると、そのユーザーを含めた状態で Googleカレンダーの空き情報を考慮してアポ調整をすることができます。"
                  type="is-dark"
                  multilined
                >
                  <b-icon class="help-icon" icon="help-circle" />
                </b-tooltip>
              </template>
              <b-taginput
                v-model="formInputAttendees"
                :data="computedAttendeeOptions"
                autocomplete
                ref="taginput"
                icon="label"
                :open-on-focus="true"
                placeholder="同行者を追加"
                @typing="getFilterdAttendees"
              >
                <template slot-scope="props">
                  {{ props.option.name }}
                </template>
                <template #empty>指定可能な同行者がいません</template>
                <template #selected="props">
                  <b-tag
                    class="attendee-tag is-size-7"
                    v-for="(attendee, index) in props.tags"
                    :key="attendee.id"
                    :tabstop="false"
                    ellipsis
                    closable
                    @close="$refs.taginput.removeTag(index, $event)"
                  >
                    {{ attendee.name }}
                  </b-tag>
                </template>
              </b-taginput>
            </b-field>
            <b-field label="連絡先設定">
              <b-checkbox
                v-model="formInputNeedContactInfo"
                size="is-small"
                type="is-info"
                >アポ予約時に電話番号を必須入力にする</b-checkbox
              >
            </b-field>
            <ValidationProvider
              rules="url"
              v-slot="{ errors }"
              name="URL"
            >
              <b-field label="WebミーティングURL" :type="{ 'is-danger': errors[0] }" :message="errors[0]">
                <b-input
                  class="form-item"
                  v-model="formInputWebMtgUrl"
                  placeholder="例）https://zoom.us/12345"
                ></b-input>
              </b-field>
            </ValidationProvider>
          </b-collapse>
        </div>
        <b-button
          class="submit-button"
          :disabled="invalid || loading"
          expanded
          @click="submit"
        >
          <EditCalendar />
          <p class="text is-size-6_5 has-text-weight-bold">
            カレンダーを保存する
          </p>
        </b-button>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
} from "@vue/composition-api";
import EditCalendar from "@/assets/img/common/outline_icons/edit_calendar.svg";
import { durations, times } from "@/submodules/constants";

export default defineComponent({
  name: "CalendarForm",
  components: {
    EditCalendar,
  },
  props: {
    calendar: {
      type: Object,
      required: true,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    placeOptions: {
      type: Array,
      required: true,
      default: () => [],
    },
    attendees: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      isOptionFormOpen: false,
      attendeeOptions: props.attendees,
      tagFilterText: null,
    });
    const isOptionSpecified = computed(() => {
      return (
        !!props.calendar.startTime ||
        !!props.calendar.endTime ||
        !!props.calendar.attendees?.length ||
        !!props.calendar.needContactInfo
      );
    });
    const computedAttendeeOptions = computed(() => {
      const selectedAttendeeIds = props.calendar.attendees.map(
        (attendee) => attendee.id
      );
      const notSelectedAttendees = props.attendees.filter((attendee) => {
        return !selectedAttendeeIds.includes(attendee.id);
      });
      if (state.tagFilterText) {
        return notSelectedAttendees.filter((attendee) =>
          attendee.name.includes(state.tagFilterText)
        );
      } else {
        return notSelectedAttendees;
      }
    });
    const getFilterdAttendees = (text) => {
      state.tagFilterText = text;
    };

    const formInputName = computed({
      get: () => {
        return props.calendar.name;
      },
      set: (name) => {
        emit("input", { ...props.calendar, name });
      },
    });
    const formInputDuration = computed({
      get: () => {
        return props.calendar.duration;
      },
      set: (duration) => {
        emit("input", { ...props.calendar, duration });
      },
    });
    const formInputPlace = computed({
      get: () => {
        return props.calendar.place?.id;
      },
      set: (placeId) => {
        const place = props.placeOptions.find((option) => option.id == placeId);
        emit("input", { ...props.calendar, place });
      },
    });
    const formInputStartTime = computed({
      get: () => {
        return props.calendar.startTime;
      },
      set: (startTime) => {
        emit("input", { ...props.calendar, startTime });
      },
    });
    const formInputEndTime = computed({
      get: () => {
        return props.calendar.endTime;
      },
      set: (endTime) => {
        emit("input", { ...props.calendar, endTime });
      },
    });
    const formInputOtherPlaceName = computed({
      get: () => {
        return props.calendar.otherPlaceName;
      },
      set: (otherPlaceName) => {
        emit("input", { ...props.calendar, otherPlaceName });
      },
    });
    const formInputAttendees = computed({
      get: () => {
        return props.calendar.attendees;
      },
      set: (attendees) => {
        emit("input", { ...props.calendar, attendees });
      },
    });
    const formInputNeedContactInfo = computed({
      get: () => {
        return props.calendar.needContactInfo;
      },
      set: (needContactInfo) => {
        emit("input", { ...props.calendar, needContactInfo });
      },
    });
    const formInputWebMtgUrl = computed({
      get: () => {
        return props.calendar.webMtgUrl;
      },
      set: (webMtgUrl) => {
        emit("input", { ...props.calendar, webMtgUrl });
      },
    });

    const submit = () => {
      emit("submit");
    };

    return {
      ...toRefs(state),
      durations,
      times,
      formInputName,
      formInputDuration,
      formInputPlace,
      formInputOtherPlaceName,
      formInputStartTime,
      formInputEndTime,
      formInputAttendees,
      formInputNeedContactInfo,
      formInputWebMtgUrl,
      submit,
      computedAttendeeOptions,
      getFilterdAttendees,
      isOptionSpecified,
    };
  },
});
</script>

<style lang="scss" scoped>
.calendar-form-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  text-align: left;
  padding: 16px;
  .title-area {
    height: 20px;
    border-bottom: 1px solid colors(light-gray);
    margin-bottom: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
  }
  .collapse-area {
    padding: 8px 0 16px;
    .title-area {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 0;
    }
  }
  .optional-items {
    padding: 16px 0;
  }
}
.field {
  padding-bottom: 16px;
}
::v-deep .submit-button {
  background-color: colors(green);
  color: white;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      padding-left: 8px;
    }
  }
}
::v-deep .tag {
  height: 16px;
  margin-left: 4px;
  color: white;
  &.attendee-tag {
    color: colors(black);
  }
  span {
    font-weight: 700;
  }
}
::v-deep .icon {
  display: flex;
  align-items: flex-end;
  padding-top: 26px;
  &.help-icon {
    .mdi-24px.mdi:before {
      font-size: 20px;
    }
  }
}
::v-deep .b-tooltip.is-multiline.is-medium .tooltip-content {
  left: 50px;
}
</style>
