export const copyStringToClipBoard = (str) => {
  const ta = document.createElement("textarea");
  ta.value = str;
  document.body.appendChild(ta);

  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    // iOS の場合
    var range = document.createRange();
    range.selectNodeContents(ta);
    var selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    ta.setSelectionRange(0, 999999);
  } else {
    ta.select();
  }

  document.execCommand("copy");
  ta.parentElement.removeChild(ta);
};