<template>
  <div class="google-calendars">
    <PageHeader
      title="連携Googleカレンダー設定"
      :backIcon="true"
      to="/settings"
    />
    <div class="contents-wrapper">
      <div class="description-container">
        <div class="text-container">
          <b-notification rounded :closable="false">
            <p>選択中の Google カレンダーを元に</p>
            <p>予約可能な空き日程を計算します。</p>
          </b-notification>
        </div>
      </div>
      <b-loading :is-full-page="false" :active="loading" />
      <div class="detail-contents">
        <div
          class="detail"
          v-for="googleCalendar in googleCalendars"
          :key="googleCalendar.id"
        >
          <div class="name">
            <p class="text is-size-6_5">
              {{ googleCalendar.name }}
            </p>
          </div>
          <div class="switch-section">
            <b-switch
              v-model="googleCalendar.available"
              @input="onSwitchChange(googleCalendar)"
            />
            <p v-if="googleCalendar.available" class="text is-size-6_5">
              連携済
            </p>
            <p v-else class="text is-size-6_5">未連携</p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, reactive, ref, toRefs } from "@vue/composition-api";

import { useAuth } from "@/submodules/auth";
import { useToast } from "@/submodules/toast";

import { useGoogleCalendarsQuery } from "./graphql/query/googleCalendars.generated";
import { useSaveGoogleCalendarMutation } from "./graphql/mutation/saveGoogleCalendar.generated.ts";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";

const { toastMessage, toastError } = useToast();
const { getToken } = useAuth();
const fetchGoogleCalendars = () => {
  const googleCalendars = ref([]);
  const { onResult, loading } = useGoogleCalendarsQuery({
    accessToken: getToken(),
  });
  onResult((result) => {
    googleCalendars.value = result.data.googleCalendars;
  });

  return {
    googleCalendars,
    loading,
  };
};
const useGoogleCalendarMutation = () => {
  const { mutate: saveGoogleCalendarMutate } = useSaveGoogleCalendarMutation();
  const saveGoogleCalendar = (googleCalendar) => {
    const result = saveGoogleCalendarMutate({
      accessToken: getToken(),
      id: googleCalendar.id,
      available: googleCalendar.available,
    });

    return result;
  };

  return saveGoogleCalendar;
};

export default defineComponent({
  name: "GoogleCalendars",
  components: {
    PageHeader,
    Footer,
  },
  setup() {
    const state = reactive({
      loading: true,
      googleCalendars: [],
    });

    const { googleCalendars, loading } = fetchGoogleCalendars();
    state.googleCalendars = googleCalendars;
    state.loading = loading;

    const saveGoogleCalendar = useGoogleCalendarMutation();

    const onSwitchChange = async (googleCalendar) => {
      console.log("googleCalendar", googleCalendar);
      try {
        await saveGoogleCalendar(googleCalendar);
        toastMessage("連携設定を保存しました");
      } catch (error) {
        console.error(error);
        toastError();
      }
    };

    return {
      ...toRefs(state),
      onSwitchChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
  > .description-container {
    padding-bottom: 16px;
    > .text-container {
      font-size: 14px;
      > .notification {
        padding: 8px;
      }
    }
  }
}
.detail-contents {
  > .detail {
    width: 100%;
    height: 40px;
    text-align: left;
    padding: 8px 0;
    border-top: 1px solid colors(light-gray);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
    }
    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.switch-section {
  display: flex;
}
::v-deep {
  .switch {
    margin: 0;
  }
}
</style>
