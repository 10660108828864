import { ToastProgrammatic as Toast } from 'buefy'

export const useToast = () => {
  const toastMessage = (message) => {
    Toast.open({
      message,
      type: 'is-dark',
      position: 'is-bottom',
    })
  }
  const setSessionMessage = (sessionMessage) => {
    sessionStorage.message = sessionMessage
  }
  const toastSessionMessage = () => {
    Toast.open({
      message: sessionStorage.message,
      type: 'is-dark',
      position: 'is-bottom',
    })
    sessionStorage.removeItem('message')
  }
  const toastError = (message="エラーが発生しました。しばらく経ってから再度実行して下さい。") => {
    Toast.open({
      message,
      type: 'is-danger',
      position: 'is-bottom',
      duration: 10000,
    })
  }

  return {
    toastMessage,
    setSessionMessage,
    toastSessionMessage,
    toastError
  }
}