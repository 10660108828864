<template>
  <div class="notifications">
    <PageHeader title="お知らせ" />
    <div class="notifications-wrapper">
      <b-tabs
        v-model="activeTab"
        position="is-centered"
        type="is-toggle"
        size="is-small"
        expanded
      >
        <b-tab-item label="未読">
          <b-loading :is-full-page="false" :active="loading" />
          <div v-if="!noNewMessage" class="text-row">
            <span
              @click="readAll"
              class="readall-link is-size-7 has-text-weight-bold"
              >すべて既読にする</span
            >
          </div>
          <div v-if="noNewMessage" class="empty-container">
            <div v-if="!loading" class="text-container">
              <b-tag rounded>未読のお知らせはありません</b-tag>
            </div>
          </div>
          <NotificationRow
            v-for="notification in newNotifications"
            :notification="notification || {}"
            :key="notification.id"
            @read-notification="readNotification"
          />
        </b-tab-item>
        <b-tab-item label="既読">
          <b-loading :is-full-page="false" :active="loading" />
          <NotificationRow
            v-for="notification in readNotifications"
            :notification="notification || {}"
            :key="notification.id"
            @read-notification="readNotification"
          />
          <div v-if="!readNotifications.length" class="empty-container">
            <div v-if="!loading" class="text-container">
              <b-tag rounded>既読のお知らせはありません</b-tag>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
} from "@vue/composition-api";
import { useNotificationsQuery } from "./graphql/query/notifications.generated";
import { useReadAllNotificationsMutation } from "./graphql/mutation/readAllNotifications.generated";
import { useReadNotificationMutation } from "./graphql/mutation/readNotification.generated";
import { useAuth } from "@/submodules/auth";
import { showConfirmDialog } from "@/submodules/dialog";
import { useToast } from "@/submodules/toast";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";
import NotificationRow from "@/components/notifications/NotificationRow.vue";

const { getToken } = useAuth();
const { toastError, toastMessage } = useToast();

export default defineComponent({
  name: "Notifications",
  components: {
    PageHeader,
    Footer,
    NotificationRow,
  },
  setup(_, { root }) {
    const state = reactive({
      activeTab: 0,
      newNotifications: [],
      readNotifications: [],
      loading: true,
    });
    const {
      mutate: readAllNotificationsMutate,
    } = useReadAllNotificationsMutation();
    const { mutate: readNotificationMutate } = useReadNotificationMutation();
    const { onResult, refetch, error } = useNotificationsQuery({
      accessToken: getToken(),
    });
    if (error.value) {
      console.error(error);
    }
    onResult((result) => {
      const notifications = result.data.notifications;
      state.newNotifications = notifications.filter(
        (notification) => !notification.read
      );
      state.readNotifications = notifications.filter(
        (notification) => notification.read
      );
      state.loading = false;
    });
    const readAll = () => {
      showConfirmDialog("未読のお知らせをすべて既読にしますか？").then(
        async () => {
          state.loading = true;
          try {
            await readAllNotificationsMutate({ accessToken: getToken() });
            toastMessage("すべて既読に更新しました");
          } catch (error) {
            console.log(error);
            toastError();
          }

          refetch();
        }
      );
    };
    const noNewMessage = computed(
      () => !state.loading && state.newNotifications.length == 0
    );
    const readNotification = async (notification) => {
      console.log("emitted args", notification);
      if (!notification.read) {
        console.log("更新");
        await readNotificationMutate({
          accessToken: getToken(),
          id: notification.id,
        });
      }
      root.$router.push(notification.url);
    };

    return {
      ...toRefs(state),
      readAll,
      noNewMessage,
      readNotification,
    };
  },
});
</script>

<style lang="scss" scoped>
.notifications-wrapper {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 0 16px 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .loading {
    width: 100%;
    min-height: 80px;
    position: relative;
  }
}
::v-deep .b-tabs {
  .tab-content {
    min-height: 80px;
    padding: 16px 0;
  }
  .tabs.is-toggle {
    ul {
      li {
        width: 50%;
        a {
          height: 24px;
        }
        &.is-active {
          a {
            background-color: colors(blue);
            border-color: colors(blue);
            font-weight: bold;
          }
        }
      }
    }
    font-size: 0.85rem;
  }
}
.text-row {
  text-align: right;
  > .readall-link {
    color: colors(blue);
    cursor: pointer;
  }
}
.empty-container {
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
