import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type AttendeesQueryVariables = Types.Exact<{
  accessToken?: Types.Maybe<Types.Scalars['String']>;
}>;


export type AttendeesQuery = (
  { __typename?: 'Query' }
  & { attendees: Array<(
    { __typename?: 'User' }
    & Pick<Types.User, 'id' | 'name' | 'email'>
  )> }
);


export const AttendeesDocument = gql`
    query attendees($accessToken: String) {
  attendees(accessToken: $accessToken) {
    id
    name
    email
  }
}
    `;

/**
 * __useAttendeesQuery__
 *
 * To run a query within a Vue component, call `useAttendeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAttendeesQuery({
 *   accessToken: // value for 'accessToken'
 * });
 */
export function useAttendeesQuery(variables: AttendeesQueryVariables | VueCompositionApi.Ref<AttendeesQueryVariables> | ReactiveFunction<AttendeesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<AttendeesQuery, AttendeesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AttendeesQuery, AttendeesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AttendeesQuery, AttendeesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<AttendeesQuery, AttendeesQueryVariables>(AttendeesDocument, variables, options);
}
export type AttendeesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<AttendeesQuery, AttendeesQueryVariables>;