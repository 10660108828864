<template>
  <div class="places">
    <PageHeader title="場所一覧" :backIcon="true" to="/tutorial/settings" />
    <div class="contents-wrapper">
      <!-- <b-loading :is-full-page="false" :active="loading" /> -->
      <div class="detail-contents">
        <router-link
          class="detail"
          v-for="place in places"
          :key="place.id"
          :to="`/places/${place.id}`"
        >
          <p class="text is-size-6_5">
            {{ place.name }}
          </p>
        </router-link>
      </div>
      <b-tooltip v-if="isInitialSettingTutorialStep(4)" position="is-bottom" type="is-success" multilined always>
        <template v-slot:content>
          <div><b>STEP2 場所を設定(2/3)</b></div>
          <p>場所を追加するボタンをクリックして下さい</p>
        </template>
        <router-link to="/tutorial/places/new">
          <div class="add-button-wrapper">
            <b-button class="add-button" :class="{ 'top-index': isInitialSettingTutorialStep(4) }" expanded >
              <p class="text is-size-6_5 has-text-weight-bold">場所を追加する</p>
            </b-button>
          </div>
        </router-link>
      </b-tooltip>
      <router-link v-else to="/tutorial/places/new">
        <div class="add-button-wrapper">
          <b-button class="add-button" expanded>
            <p class="text is-size-6_5 has-text-weight-bold">場所を追加する</p>
          </b-button>
        </div>
      </router-link>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, reactive, ref, toRefs } from "@vue/composition-api";

import { useAuth } from "@/submodules/auth";
import { usePlacesQuery } from "../graphql/query/places.generated";
import { useTutorialStore } from '@/stores/tutorial'

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";

const { getToken } = useAuth();
const fetchPlaces = () => {
  const places = ref([]);
  const { onResult, loading } = usePlacesQuery({
    accessToken: getToken(),
  });
  onResult((result) => {
    places.value = result.data.places;
  });

  return {
    places,
    loading,
  };
};

export default defineComponent({
  name: "Places",
  components: {
    PageHeader,
    Footer,
  },
  setup() {
    const state = reactive({
      loading: true,
      places: [],
    });

    const { places, loading } = fetchPlaces();
    state.places = places;
    state.loading = loading;

    const tutorial = useTutorialStore()
    const isInitialSettingTutorialStep = (step) => {
      return tutorial.current === 'INITIAL_SETTING' && tutorial.step == step
    }

    return {
      ...toRefs(state),
      isInitialSettingTutorialStep,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
}
.detail-contents {
  > .detail {
    width: 100%;
    height: 40px;
    text-align: left;
    padding: 8px 0;
    border-top: 1px solid colors(light-gray);
    display: block;
    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: colors(dark-blue);
    }
  }
}
::v-deep .button {
  // margin-top: 16px;
  color: white;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      color: white;
    }
  }
}
.add-button-wrapper {
  padding-top: 32px;
  .add-button {
    background-color: colors(blue);
    color: white;
  }
}

::v-deep .b-tooltip {
  width: 100%;
}
.top-index {
  z-index: 10;
  background-color: white;
  ::v-deep .button.next-button {
    height: 28px;
    color: gray;
    margin: 4px;
    font-size: 12px;
  }
}
</style>
