import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type SaveAdjustableTimePeriodsMutationVariables = Types.Exact<{
  accessToken?: Types.Maybe<Types.Scalars['String']>;
  periodType?: Types.Maybe<Types.Scalars['String']>;
  dailyTimePeriods?: Types.Maybe<Types.DailyTimePeriods>;
  weeklyTimePeriods?: Types.Maybe<Types.WeeklyTimePeriods>;
}>;


export type SaveAdjustableTimePeriodsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'saveAdjustableTimePeriods'>
);


export const SaveAdjustableTimePeriodsDocument = gql`
    mutation saveAdjustableTimePeriods($accessToken: String, $periodType: String, $dailyTimePeriods: DailyTimePeriods, $weeklyTimePeriods: WeeklyTimePeriods) {
  saveAdjustableTimePeriods(
    accessToken: $accessToken
    periodType: $periodType
    dailyTimePeriods: $dailyTimePeriods
    weeklyTimePeriods: $weeklyTimePeriods
  )
}
    `;

/**
 * __useSaveAdjustableTimePeriodsMutation__
 *
 * To run a mutation, you first call `useSaveAdjustableTimePeriodsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSaveAdjustableTimePeriodsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSaveAdjustableTimePeriodsMutation({
 *   variables: {
 *     accessToken: // value for 'accessToken'
 *     periodType: // value for 'periodType'
 *     dailyTimePeriods: // value for 'dailyTimePeriods'
 *     weeklyTimePeriods: // value for 'weeklyTimePeriods'
 *   },
 * });
 */
export function useSaveAdjustableTimePeriodsMutation(options: VueApolloComposable.UseMutationOptions<SaveAdjustableTimePeriodsMutation, SaveAdjustableTimePeriodsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SaveAdjustableTimePeriodsMutation, SaveAdjustableTimePeriodsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SaveAdjustableTimePeriodsMutation, SaveAdjustableTimePeriodsMutationVariables>(SaveAdjustableTimePeriodsDocument, options);
}
export type SaveAdjustableTimePeriodsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SaveAdjustableTimePeriodsMutation, SaveAdjustableTimePeriodsMutationVariables>;