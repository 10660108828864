import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type DeleteScheduleMutationVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
  id: Types.Scalars['ID'];
}>;


export type DeleteScheduleMutation = (
  { __typename?: 'Mutation' }
  & { deleteSchedule: (
    { __typename?: 'DetailSchedule' }
    & Pick<Types.DetailSchedule, 'id'>
  ) }
);


export const DeleteScheduleDocument = gql`
    mutation deleteSchedule($accessToken: String!, $id: ID!) {
  deleteSchedule(accessToken: $accessToken, id: $id) {
    id
  }
}
    `;

/**
 * __useDeleteScheduleMutation__
 *
 * To run a mutation, you first call `useDeleteScheduleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteScheduleMutation({
 *   variables: {
 *     accessToken: // value for 'accessToken'
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScheduleMutation(options: VueApolloComposable.UseMutationOptions<DeleteScheduleMutation, DeleteScheduleMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteScheduleMutation, DeleteScheduleMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteScheduleMutation, DeleteScheduleMutationVariables>(DeleteScheduleDocument, options);
}
export type DeleteScheduleMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteScheduleMutation, DeleteScheduleMutationVariables>;