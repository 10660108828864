import { defineStore } from 'pinia'
import { useAuth } from "../submodules/auth";
import { useTutorialStatusQuery } from "./graphql/query/tutorialStatus.generated";

export const useTutorialStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'tutorial',
  // a function that returns a fresh state
  state: () => ({
    current: null,
    step: 0,
    isFetched: false,
  }),
  // optional getters
  getters: {
  },
  // optional actions
  actions: {
    async fetch() {
      console.log('Tutorial FETCH CALLED!')
      if (this.isFetched) return
      console.log('Tutorial FIRST FETCH!')
      const { getToken } = useAuth();
      const { onResult, error } = useTutorialStatusQuery({
        accessToken: getToken(),
      })
      onResult((result) => {
        if (error.value) {
          return;
        }
        console.log('result', result)
        // BEFORE_START_TUTORIAL, CREATE_CALENDAR, COMPLETED
        this.current = result.data.tutorialStatus.currentTutorial
        this.step = 0
        this.isFetched = true
      });
    },
    // startTutorial() {
    //   this.current = 'CREATE_CALENDAR'
    //   // this.current = 'INITIAL_SETTING'
    //   this.step = 0
    // },
    skipTutorial() {
      this.current = 'COMPLETED'
    },
    async changeTutorial(currentTutorial, mutate) {
      this.current = currentTutorial
      this.step = 0
      const { getToken } = useAuth();
      await mutate({
        accessToken: getToken(),
        currentTutorial: currentTutorial,
      })
    },
    goToNextStep() {
      this.step++
    },
    finishTutorial() {
      this.current = 'COMPLETED'
    },
  },
})