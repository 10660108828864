<template>
  <footer class="footer">
    <div class="icon-container">
      <div class="icon-wrapper" :class="{ active: isHomeIconActive }">
        <router-link to="/"><HomeIcon /></router-link>
      </div>
      <div class="icon-wrapper" :class="{ active: isCalendarsIconActive }">
        <router-link to="/schedules"><CalendarsIcon /></router-link>
      </div>
      <div class="icon-wrapper" :class="{ active: isReservationIconActive }">
        <router-link to="/calendars"><ReservationIcon /></router-link>
        </div>
      <div class="icon-wrapper" :class="{ active: isNotificationIconActive }">
        <router-link to="/notifications"><NotificationIcon /></router-link>
      </div>  
      <div class="icon-wrapper" :class="{ active: isSettingIconActive }">
        <router-link to="/settings"><SettingIcon /></router-link>
      </div>
    </div>
  </footer>
</template>

<script>
// @ is an alias to /src
import { defineComponent, reactive, toRefs } from '@vue/composition-api'

import HomeIcon from '@/assets/img/footer_icons/home.svg'
import CalendarsIcon from '@/assets/img/footer_icons/calendars.svg'
import ReservationIcon from '@/assets/img/footer_icons/reservation.svg'
import NotificationIcon from '@/assets/img/footer_icons/notification.svg'
import SettingIcon from '@/assets/img/footer_icons/setting.svg'

export default defineComponent({
  name: 'Footer',
  components: {
    HomeIcon,
    CalendarsIcon,
    ReservationIcon,
    NotificationIcon,
    SettingIcon,
  },
  props: {
    currentIcon: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  setup() {
    const state = reactive({
      isHomeIconActive: true,
      isCalendarsIconActive: false,
      isReservationIconActive : false,
      isNotificationIconActive : false,
      isSettingIconActive : false,
    })

    return {
      ...toRefs(state)
    }
  },
})
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 56px;
  padding: 0;
  position: fixed;
  bottom: 0;
  border-top: 1px solid colors(light-gray);
  > .icon-container {
    width: 100%;
    max-width: 760px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .icon-wrapper {
      width: 100%;
      > a {
        line-height: 0;
        display: block;
      }
      > .router-link-exact-active {
        svg g, tspan {
          fill: colors(blue);
        }
      }
    }
  }
}
</style>