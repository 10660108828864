<template>
  <div class="holiday-setting">
    <PageHeader title="土日・祝日の受付設定" :backIcon="true" to="/settings" />
    <div class="contents-wrapper">
      <b-loading :is-full-page="false" :active="loading" />
      <div class="detail-contents">
        <b-radio
          v-model="isHolidayAdjustable"
          name="name"
          :native-value="false"
          @input="onChangeSetting(isHolidayAdjustable)"
        >
          土日はアポ予約を受付しない
        </b-radio>
        <b-radio
          v-model="isHolidayAdjustable"
          name="name"
          :native-value="true"
          @input="onChangeSetting(isHolidayAdjustable)"
        >
          土日もアポ予約を受付する
        </b-radio>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, reactive, ref, toRefs } from "@vue/composition-api";

import { useAuth } from "@/submodules/auth";
import { useToast } from "@/submodules/toast";

import { useHolidaySettingQuery } from "./graphql/query/holidaySetting.generated";
import { useSaveHolidaySettingMutation } from "./graphql/mutation/saveHolidaySetting.generated.ts";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";

const { toastMessage, toastError } = useToast();
const { getToken } = useAuth();
const fetchHolidaySetting = () => {
  const isHolidayAdjustable = ref(null);
  const { onResult, loading } = useHolidaySettingQuery({
    accessToken: getToken(),
  });
  onResult((result) => {
    isHolidayAdjustable.value =
      result.data.adjustableTimePeriods.isHolidayAdjustable;
  });

  return {
    isHolidayAdjustable,
    loading,
  };
};
const useHolidaySettingMutation = () => {
  const { mutate: saveHolidaySettingMutate } = useSaveHolidaySettingMutation();
  const saveHolidaySetting = (isHolidayAdjustable) => {
    const result = saveHolidaySettingMutate({
      accessToken: getToken(),
      isHolidayAdjustable: isHolidayAdjustable,
    });

    return result;
  };

  return saveHolidaySetting;
};

export default defineComponent({
  name: "HolidaySetting",
  components: {
    PageHeader,
    Footer,
  },
  setup() {
    const state = reactive({
      loading: true,
      isHolidayAdjustable: null,
    });

    const { isHolidayAdjustable, loading } = fetchHolidaySetting();
    state.isHolidayAdjustable = isHolidayAdjustable;
    state.loading = loading;

    const saveHolidaySetting = useHolidaySettingMutation();

    const onChangeSetting = async (isHolidayAdjustable) => {
      console.log("isHolidayAdjustable", isHolidayAdjustable);
      try {
        await saveHolidaySetting(isHolidayAdjustable);
        toastMessage("土日の受付設定を保存しました");
      } catch (error) {
        console.error(error);
        toastError();
      }
    };

    return {
      ...toRefs(state),
      onChangeSetting,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
}
.detail-contents {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.switch-section {
  display: flex;
}
::v-deep {
  .switch {
    margin: 0;
  }
}
.b-radio.radio {
  margin-top: 8px;
}
</style>
