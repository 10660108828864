<template>
  <div class="settings">
    <PageHeader title="その他" />
    <div class="settings-wrapper">
      <b-menu v-for="setting in settings" :key="setting.id" :class="{ 'top-index': setting.id==3 && isDetailSettingsTutorial }">
        <b-menu-list>
          <b-menu-item :active="setting.id==3 && isInitialSettingTutorialStep(6)" :class="{ 'top-index': setting.id==3 && isDetailSettingsTutorial }">
            <template
              v-if="isPath(setting.url) || isExternalLink(setting.url)"
              #label
            >
              <template v-if="setting.url=='/tutorial/profile'">
                <b-tooltip v-if="isInitialSettingTutorialStep(1)" :class="{ 'top-index': isInitialSettingTutorialStep(1) }" position="is-bottom" type="is-success" multilined always>
                  <template v-slot:content>
                    <p>プロフィール設定をクリックして下さい</p>
                  </template>
                  <router-link
                    class="setting"
                    :to="setting.url"
                  >
                    <b-icon :icon="setting.icon" />
                    <span class="text is-size-7 has-text-weight-bold">{{
                      setting.title
                    }}</span>
                  </router-link>
                </b-tooltip>
                <router-link
                  v-else
                  class="setting"
                  :to="setting.url"
                >
                  <b-icon :icon="setting.icon" />
                  <span class="text is-size-7 has-text-weight-bold">{{
                    setting.title
                  }}</span>
                </router-link>
              </template>
              <template v-else-if="setting.url=='/tutorial/places'">
                <b-tooltip v-if="isInitialSettingTutorialStep(3)" :class="{ 'top-index': isInitialSettingTutorialStep(3) }" position="is-bottom" type="is-success" multilined always>
                  <template v-slot:content>
                    <p>場所設定をクリックして下さい</p>
                  </template>
                  <router-link
                    class="setting"
                    :to="setting.url"
                    @click.native="goToNextStep"
                  >
                    <b-icon :icon="setting.icon" />
                    <span class="text is-size-7 has-text-weight-bold">{{
                      setting.title
                    }}</span>
                  </router-link>
                </b-tooltip>
                <router-link
                  v-else
                  class="setting"
                  :to="setting.url"
                >
                  <b-icon :icon="setting.icon" />
                  <span class="text is-size-7 has-text-weight-bold">{{
                    setting.title
                  }}</span>
                </router-link>
              </template>
              <template v-else>
                <router-link
                  class="setting"
                  v-if="isPath(setting.url)"
                  :to="setting.url"
                >
                  <b-icon :icon="setting.icon" />
                  <span class="text is-size-7 has-text-weight-bold">{{
                    setting.title
                  }}</span>
                </router-link>
                <a
                  v-else-if="isExternalLink(setting.url)"
                  :href="setting.url"
                  class="setting"
                  target="_blank"
                >
                  <b-icon :icon="setting.icon" />
                  <span class="text is-size-7 has-text-weight-bold">{{
                    setting.title
                  }}</span>
                </a>
              </template>
            </template>
            <template v-else #label="props">
              <b-tooltip v-if="isInitialSettingTutorialStep(6)" :class="{ 'top-index': isInitialSettingTutorialStep(6) }" position="is-bottom" type="is-success" multilined always>
                <template v-slot:content>
                  <div><b>STEP3 カレンダーの詳細設定(3/3)</b></div>
                  <p>アポ予約受付設定をクリックして下さい</p>
                </template>
                <div class="setting" @click="goToNextStep">
                  <b-icon :icon="setting.icon" />
                  <span class="text is-size-7 has-text-weight-bold">{{
                    setting.title
                  }}</span>
                  <b-icon
                    class="is-pulled-right"
                    :icon="props.expanded ? 'menu-down' : 'menu-up'"
                  ></b-icon>
                </div>
              </b-tooltip>
              <div v-else class="setting">
                <b-icon :icon="setting.icon" />
                <span class="text is-size-7 has-text-weight-bold">{{
                  setting.title
                }}</span>
                <b-icon
                  class="is-pulled-right"
                  :icon="props.expanded ? 'menu-down' : 'menu-up'"
                ></b-icon>
              </div>
            </template>
            <b-menu-item
              class="setting child-setting"
              v-for="childSetting in setting.childSettings"
              :key="childSetting.id"
            >
              <template #label>
                <template v-if="childSetting.id==301">
                  <b-tooltip v-if="isInitialSettingTutorialStep(7)" position="is-bottom" type="is-success" multilined always>
                    <template v-slot:content>
                      <div><b>STEP3 カレンダーの詳細設定(3/3)</b></div>
                      <p>こちらから土日と休日にアポ予約を</p>
                      <p>受け付けるか設定することができます</p>
                      <b-button @click="goToNextStep" class="next-button">
                        次の説明に進む
                      </b-button>
                    </template>
                    <b-icon :icon="childSetting.icon" />
                    <span class="text is-size-7 has-text-weight-bold">{{
                      childSetting.title
                    }}</span>
                  </b-tooltip>
                  <template v-else>
                    <b-icon :icon="childSetting.icon" />
                    <span class="text is-size-7 has-text-weight-bold">{{
                      childSetting.title
                    }}</span>
                  </template>
                </template>
                <template v-else-if="childSetting.id==302">
                  <b-tooltip v-if="isInitialSettingTutorialStep(8)" position="is-bottom" type="is-success" multilined always>
                    <template v-slot:content>
                      <div><b>STEP3 カレンダーの詳細設定(3/3)</b></div>
                      <p>こちらから曜日ごとにアポ予約を</p>
                      <p>受け付ける時間帯を指定することができます</p>
                      <b-button @click="goToNextStep" class="next-button">
                        次の説明に進む
                      </b-button>
                    </template>
                    <b-icon :icon="childSetting.icon" />
                    <span class="text is-size-7 has-text-weight-bold">{{
                      childSetting.title
                    }}</span>
                  </b-tooltip>
                  <template v-else>
                    <b-icon :icon="childSetting.icon" />
                    <span class="text is-size-7 has-text-weight-bold">{{
                      childSetting.title
                    }}</span>
                  </template>
                </template>
                <template v-else-if="childSetting.id==303">
                  <b-tooltip v-if="isInitialSettingTutorialStep(9)" position="is-bottom" type="is-success" multilined always>
                    <template v-slot:content>
                      <div><b>STEP3 カレンダーの詳細設定(3/3)</b></div>
                      <p>こちらから昼休みを設定し、</p>
                      <p>該当時間はアポ予約が入らないようできます</p>
                      <b-button @click="goToNextStep" class="next-button">
                        次の説明に進む
                      </b-button>
                    </template>
                    <b-icon :icon="childSetting.icon" />
                    <span class="text is-size-7 has-text-weight-bold">{{
                      childSetting.title
                    }}</span>
                  </b-tooltip>
                  <template v-else>
                    <b-icon :icon="childSetting.icon" />
                    <span class="text is-size-7 has-text-weight-bold">{{
                      childSetting.title
                    }}</span>
                  </template>
                </template>
                <template v-else-if="childSetting.id==304">
                  <b-tooltip v-if="isInitialSettingTutorialStep(10)" position="is-bottom" type="is-success" multilined always>
                    <template v-slot:content>
                      <div><b>STEP3 カレンダーの詳細設定(3/3)</b></div>
                      <p>こちらからアポ予約間に必要な</p>
                      <p>移動時間や準備時間などの間隔を設定できます</p>
                      <b-button @click="goToNextStep" class="next-button">
                        次の説明に進む
                      </b-button>
                    </template>
                    <b-icon :icon="childSetting.icon" />
                    <span class="text is-size-7 has-text-weight-bold">{{
                      childSetting.title
                    }}</span>
                  </b-tooltip>
                  <template v-else>
                    <b-icon :icon="childSetting.icon" />
                    <span class="text is-size-7 has-text-weight-bold">{{
                      childSetting.title
                    }}</span>
                  </template>
                </template>
                <router-link v-else class="setting" :to="childSetting.url">
                  <b-icon :icon="childSetting.icon" />
                  <span class="text is-size-7 has-text-weight-bold">{{
                    childSetting.title
                  }}</span>
                </router-link>
              </template>
            </b-menu-item>
          </b-menu-item>
        </b-menu-list>
      </b-menu>
    </div>

    <Footer />
    <b-modal
      v-model="computedShowFinishInitialSettinsTutorialModal"
      :on-cancel="cancelFinishInitialSettinsTutorialModal"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-label="PurchasePlanModal"
      aria-modal
      :can-cancel="['escape', 'outside']"
    >
      <template #default="props">
        <FinishInitialSettingsTutorialModal @go-to-next="goToNextStep" @close="props.close" />
      </template>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, reactive, toRefs, onMounted, computed } from "@vue/composition-api";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";
import FinishInitialSettingsTutorialModal from "@/components/tutorial/settings/FinishInitialSettingsTutorialModal.vue"

import config from "config";
import { useTutorialStore } from '@/stores/tutorial'

const settings = [
  {
    id: 1,
    icon: "account-circle",
    title: "プロフィール設定",
    url: "/tutorial/profile",
  },
  {
    id: 2,
    icon: "map-marker",
    title: "場所設定",
    url: "/tutorial/places",
  },
  {
    id: 3,
    icon: "calendar-clock",
    title: "アポ予約受付設定",
    // url: "/detail_settings",
    childSettings: [
      {
        id: 301,
        icon: "cancel",
        title: "土日・祝日の受付設定",
        url: "/tutorial/holiday_setting",
      },
      {
        id: 302,
        icon: "clock-time-four-outline",
        title: "受付可能な時間帯の設定",
        url: "/adjustable_time_periods",
      },
      {
        id: 303,
        icon: "food-fork-drink",
        title: "昼休みの設定",
        url: "/lunch_time_setting",
      },
      {
        id: 304,
        icon: "arrow-expand-horizontal",
        title: "アポ予約前後の空き時間の設定",
        url: "/interval_settings",
      },
    ],
  },
  {
    id: 4,
    icon: "calendar-import",
    title: "アポ予約を入れるGoogleカレンダーの指定",
    url: "/event_registerable_caendar",
  },
  {
    id: 5,
    icon: "calendar-multiple-check",
    title: "連携Googleカレンダー設定",
    url: "/google_calendars",
  },
  {
    id: 6,
    icon: "cart",
    title: "プラン変更",
    url: "/purchase_plan",
    // url: config.plansUrl + "?access_token=" + getToken(),
  },
  {
    id: 7,
    icon: "text-box-outline",
    title: "利用規約",
    url: "/rules",
  },
  {
    id: 8,
    icon: "security",
    title: "プライバシーポリシー",
    url: "/privacy_policy",
  },
  {
    id: 9,
    icon: "message-alert-outline",
    title: "不具合・バグレポート",
    url: config.bugReportUrl,
  },
  {
    id: 10,
    icon: "help-circle-outline",
    title: "よくあるご質問",
    url: "/faq",
  },
  // {
  //   id: 10,
  //   icon: "information-outline",
  //   title: "その他操作",
  //   childSettings: [
  //     {
  //       id: 301,
  //       icon: "map-marker",
  //       title: "土日・祝日の設定",
  //       url: "/places",
  //     },
  //   ],
  // },
  {
    id: 12,
    icon: "logout-variant",
    title: "ログアウト",
    url: "/logout",
  },
];

export default defineComponent({
  name: "Settings",
  components: {
    PageHeader,
    Footer,
    FinishInitialSettingsTutorialModal,
  },
  setup() {
    const state = reactive({});
    const tutorial = useTutorialStore()

    const isPath = (url) => {
      if (!url) {
        return false;
      }
      return !url.includes("http");
    };
    const isExternalLink = (url) => {
      if (!url) {
        return false;
      }
      return url.includes("http");
    };
    const isInitialSettingTutorialStep = (step) => {
      return tutorial.current === 'INITIAL_SETTING' && tutorial.step == step
    }
    const goToNextStep = () => {
      tutorial.goToNextStep()
    }

    onMounted(() => {
      if (isInitialSettingTutorialStep(6)) {
        console.log('test')
      }
    })
    const isDetailSettingsTutorial = computed(() => {
      return isInitialSettingTutorialStep(6) || isInitialSettingTutorialStep(7) || isInitialSettingTutorialStep(8) || isInitialSettingTutorialStep(9) || isInitialSettingTutorialStep(10)
    })
    const computedShowFinishInitialSettinsTutorialModal = computed({
      get: () => tutorial.current === 'INITIAL_SETTING' && tutorial.step === 11,
      set: (val) => {
        console.log('cancelFinishCreateCalendarTutorialModal', val)
        // tutorial.startTutorial()
        // root.$router.push('/')
      }
    })
    const cancelFinishInitialSettinsTutorialModal = () => {
      console.log('cancelFinishInitialSettinsTutorialModal')
    }

    return {
      ...toRefs(state),
      settings,
      isPath,
      isExternalLink,
      isInitialSettingTutorialStep,
      goToNextStep,
      isDetailSettingsTutorial,
      computedShowFinishInitialSettinsTutorialModal,
      cancelFinishInitialSettinsTutorialModal,
    };
  },
});
</script>

<style lang="scss" scoped>
.settings-wrapper {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .setting {
    height: 40px;
    border-top: 1px solid colors(light-gray);
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    // line-height: 36px;
    .text {
      padding-left: 8px;
    }
    .v-icon {
      font-size: 28px;
    }
  }
}
.child-setting {
  a {
    width: 100%;
  }
}
::v-deep .menu-list {
  a {
    padding: 0;
    &.setting {
      height: 40px;

      border-top: 1px solid colors(light-gray);
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      // line-height: 36px;
      .text {
        padding-left: 8px;
      }
      .v-icon {
        font-size: 28px;
      }
    }
    &.is-active {
      background-color: white;
      color: colors(black);
    }
  }

  .child-setting {
    > a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
::v-deep .b-tooltip {
  width: 100%;
}
::v-deep .tooltip-trigger {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.top-index {
  z-index: 10;
  background-color: white;
  ::v-deep .button.next-button {
    height: 28px;
    color: gray;
    margin: 4px;
    font-size: 12px;
  }
}
::v-deep .modal .animation-content.modal-content .modal-card {
  margin: 0 auto;
}
</style>
