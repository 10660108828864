<template>
  <div class="lunchtime-setting">
    <PageHeader title="昼休みの設定" :backIcon="true" to="/settings" />
    <div class="contents-wrapper">
      <b-loading :is-full-page="false" :active="loading" />
      <div class="detail-contents">
        <div class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">昼休み開始時間</p>
          </div>
          <div class="value">
            <p class="is-size-6">{{ lunchStartTime }}</p>
          </div>
        </div>
      </div>
      <div class="detail-contents">
        <div class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">昼休み終了時間</p>
          </div>
          <div class="value">
            <p class="is-size-6">{{ lunchEndTime }}</p>
          </div>
        </div>
      </div>
      <router-link to="/lunch_time_setting/edit">
        <b-button class="edit-button" expanded>
          <p class="text is-size-6_5 has-text-weight-bold">
            昼休みの設定を編集する
          </p>
        </b-button>
      </router-link>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import {
  defineComponent,
  reactive,
  ref,
  toRefs,
  computed,
} from "@vue/composition-api";

import { useAuth } from "@/submodules/auth";
import { useLunchTimeQuery } from "./graphql/query/lunchTime.generated";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";

const { getToken } = useAuth();
const fetchLunchTime = () => {
  console.log("fetchLunchTime start");
  const lunchTime = ref({});
  const { onResult, loading } = useLunchTimeQuery({
    accessToken: getToken(),
  });
  onResult((result) => {
    console.log("result", result);
    lunchTime.value = result.data.lunchTime;
  });

  return {
    lunchTime,
    loading,
  };
};

export default defineComponent({
  name: "LunchTime",
  components: {
    PageHeader,
    Footer,
  },
  setup() {
    const state = reactive({
      loading: true,
      lunchTime: {},
    });

    const { lunchTime, loading } = fetchLunchTime();
    state.lunchTime = lunchTime;
    state.loading = loading;

    const lunchStartTime = computed(() => {
      if (!state.lunchTime.startHour) return "";
      return `${state.lunchTime.startHour}:${state.lunchTime.startMinute}`;
    });
    const lunchEndTime = computed(() => {
      if (!state.lunchTime.endHour) return "";
      return `${state.lunchTime.endHour}:${state.lunchTime.endMinute}`;
    });

    return {
      ...toRefs(state),
      lunchStartTime,
      lunchEndTime,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
}
.detail-contents {
  > .detail {
    width: 100%;
    text-align: left;
    padding: 8px 0;
    display: block;
    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .button {
  margin-top: 16px;
  color: white;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      color: white;
    }
  }
}
.edit-button {
  margin-top: 32px;
  background-color: colors(green);
  color: white;
}
</style>
