<template>
  <div class="schedule">
    <PageHeader title="アポ予約詳細" :backIcon="true" />
    <div class="schedule-wrapper">
      <b-loading :is-full-page="false" :active="loading" />
      <ScheduleRow :schedule="schedule" />
      <div class="schedule-details">
        <div class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">アポ予約の時間帯</p>
          </div>
          <div class="value">
            <p class="is-size-6">{{ `${scheduleDatetime}` }}</p>
          </div>
        </div>
        <div class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">場所</p>
          </div>
          <div class="value">
            <p class="is-size-6">{{ placeName }}</p>
          </div>
        </div>
        <div class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">カレンダー名</p>
          </div>
          <div class="value">
            <p class="is-size-6">{{ `${calendarName}` }}</p>
          </div>
        </div>
        <div class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">ステータス</p>
          </div>
          <div class="value">
            <p class="is-size-6">{{ `${scheduleStatus}` }}</p>
          </div>
        </div>
        <div :v-if="isConfirmed || isCanceled" class="detail">
          <div class="name">
            <p v-if="isConfirmed" class="is-size-7 has-text-weight-bold">
              アポ予約を入れた日時
            </p>
            <p v-if="isCanceled" class="is-size-7 has-text-weight-bold">
              キャンセルした日時
            </p>
          </div>
          <div class="value">
            <p class="is-size-6">{{ `${scheduleUpdatedAt}` }}</p>
          </div>
        </div>
        <div v-if="isAttendeeInfoShown" class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">同行者</p>
          </div>
          <div
            v-for="attendee in scheduleAttendees"
            :key="attendee.id"
            class="value"
          >
            <p class="is-size-6">{{ attendee.name }}</p>
          </div>
        </div>
        <div v-if="canShowTel" class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">電話番号</p>
          </div>
          <div class="value">
            <p class="is-size-6">{{ `${scheduleTel}` }}</p>
          </div>
        </div>
        <div v-if="scheduleWebMtgUrl" class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">WebミーティングURL</p>
          </div>
          <div class="value">
            <a class="link" :href="scheduleWebMtgUrl" target="_blank" rel="noreferrer">
              <p class="is-size-6">{{ `${scheduleWebMtgUrl}` }}</p>
            </a>
          </div>
        </div>
        <div v-if="scheduleEmailForNonLoginUser" class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">メールアドレス</p>
          </div>
          <div class="value">
            <div class="value">
              <p class="is-size-6">{{ scheduleEmailForNonLoginUser }}</p>
            </div>
          </div>
        </div>
        <div class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">カレンダー作成者</p>
          </div>
          <div class="value">
            <p class="is-size-6">{{ `${scheduleRequesterName}` }}</p>
          </div>
        </div>
      </div>
      <b-button
        v-if="!isCanceled"
        class="copy-button"
        expanded
        @click="copyCalendarUrl"
      >
        <LinkIcon />
        <p class="text is-size-6_5 has-text-weight-bold">
          このアポ予約のリンクをコピーする
        </p>
      </b-button>
      <b-button
        v-if="canDeleteSchedule && !isCanceled"
        class="cancel-button"
        expanded
        @click="deleteSchedule"
      >
        <TrashIcon />
        <p class="text is-size-6_5 has-text-weight-bold">
          アポ予約をキャンセルする
        </p>
      </b-button>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
} from "@vue/composition-api";
import { useAuth } from "@/submodules/auth";
import { useToast } from "@/submodules/toast";
import { copyStringToClipBoard } from "@/submodules/util";
import { showConfirmDialog } from "@/submodules/dialog";
import { useScheduleQuery } from "./graphql/query/schedule.generated";
import { useDeleteScheduleMutation } from "./graphql/mutation/deleteSchedule.generated";
import { formatDateWithoutPadding } from "@/submodules/format";

import PageHeader from "@/components/PageHeader.vue";
import ScheduleRow from "@/components/calendars/ScheduleRow.vue";

import LinkIcon from "@/assets/img/common/outline_icons/link.svg";
import TrashIcon from "@/assets/img/common/outline_icons/trash.svg";
import Footer from "@/components/Footer.vue";

const { getToken, loggedIn } = useAuth();
const { toastMessage, toastError, setSessionMessage } = useToast();

const useSchedule = (scheduleId) => {
  const { onResult } = useScheduleQuery({
    accessToken: getToken(),
    id: scheduleId,
  });
  return onResult;
};

export default defineComponent({
  name: "Schedule",
  components: {
    PageHeader,
    ScheduleRow,
    LinkIcon,
    TrashIcon,
    Footer,
  },
  setup(_, { root }) {
    const state = reactive({
      loading: true,
      schedule: null,
    });
    const onResultUseSchedule = useSchedule(root.$route.params.id);
    onResultUseSchedule((result) => {
      console.log('Schedule: ', result.data?.schedule)
      state.schedule = result.data?.schedule;
      state.loading = false;
    });

    const placeName = computed(() => {
      return state.schedule?.placeName;
    });
    const scheduleDatetime = computed(() => {
      return state.schedule?.mtgDateText || "";
    });
    const isAttendeeInfoShown = computed(() => {
      return !!state.schedule?.attendees?.length;
    });
    const scheduleAttendees = computed(() => {
      return state.schedule?.attendees?.length ? state.schedule?.attendees : [];
    });
    const calendarName = computed(() => {
      return state.schedule?.reservationName || "";
    });
    const canDeleteSchedule = computed(() => {
      return state.schedule?.userType == "requester";
    });
    const scheduleStatus = computed(() => {
      if (state.schedule?.status == "deleted") return "キャンセル済み";
      return state.schedule?.statusText || "";
    });
    const scheduleUpdatedAt = computed(() => {
      return `${formatDateWithoutPadding(
        state.schedule?.updatedAt,
        "M月d日 H:mm"
      )}`;
    });
    const canShowTel = computed(() => {
      return state.schedule?.userType == "requester" && state.schedule?.tel;
    });
    const scheduleTel = computed(() => {
      return state.schedule?.tel || "";
    });
    const scheduleRequesterName = computed(() => {
      return state.schedule?.requesterName || "";
    });
    const scheduleWebMtgUrl = computed(() => {
      return state.schedule?.webMtgUrl || "";
    });
    const scheduleEmailForNonLoginUser = computed(() => {
      return state.schedule?.emailForNonLoginUser || "";
    });

    const copyCalendarUrl = () => {
      try {
        copyStringToClipBoard(
          `${window.location.origin}/schedules/${state.schedule?.id}`
        );
        toastMessage("リンクURLをコピーしました");
      } catch (e) {
        console.error(e);
        toastError("エラーのためコピーできませんでした");
      }
    };
    const { mutate: deleteScheduleMutation } = useDeleteScheduleMutation();
    const deleteSchedule = async () => {
      showConfirmDialog("アポ予約をキャンセルしますか？").then(async () => {
        state.loading = true;
        try {
          await deleteScheduleMutation({
            accessToken: getToken(),
            id: state.schedule.id,
          });
          setSessionMessage("アポ予約をキャンセルしました");
          root.$router.push("/schedules/");
        } catch (error) {
          console.error(error);
          toastError();
        }
      });
    };
    const isConfirmed = computed(() => {
      if (!state.schedule?.status) return false;
      return state.schedule?.status == "confirmed";
    });
    const isCanceled = computed(() => {
      if (!state.schedule?.status) return false;
      return state.schedule?.status == "deleted";
    });

    return {
      ...toRefs(state),
      placeName,
      scheduleDatetime,
      isAttendeeInfoShown,
      scheduleAttendees,
      copyCalendarUrl,
      deleteSchedule,
      calendarName,
      canDeleteSchedule,
      scheduleStatus,
      isConfirmed,
      isCanceled,
      canShowTel,
      scheduleTel,
      scheduleRequesterName,
      scheduleWebMtgUrl,
      scheduleEmailForNonLoginUser,
      scheduleUpdatedAt,
      formatDateWithoutPadding,
      loggedIn,
    };
  },
});
</script>

<style lang="scss" scoped>
.schedule-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
}
.schedule-details {
  > .detail {
    width: 100%;
    text-align: left;
    padding-top: 16px;
    .name {
      height: 20px;
      white-space: nowrap;
    }

    .value {
      .link {
        color: colors(blue);
      }
    }
  }
}
::v-deep .button {
  margin-top: 16px;
  color: white;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      padding-left: 8px;
    }
  }
}
.copy-button {
  margin-top: 32px;
  background-color: colors(blue);
}
.cancel-button {
  background-color: colors(gray);
}
::v-deep .loading-overlay .loading-background {
  background-color: white;
}
.svg {
  width: 18px;
  height: 18px;
  fill: white;
}
</style>
