import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type CurrentPlanQueryVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
}>;


export type CurrentPlanQuery = (
  { __typename?: 'Query' }
  & { currentPlan: (
    { __typename?: 'CurrentPlan' }
    & Pick<Types.CurrentPlan, 'persisted' | 'name' | 'price'>
  ) }
);


export const CurrentPlanDocument = gql`
    query currentPlan($accessToken: String!) {
  currentPlan(accessToken: $accessToken) {
    persisted
    name
    price
  }
}
    `;

/**
 * __useCurrentPlanQuery__
 *
 * To run a query within a Vue component, call `useCurrentPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentPlanQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCurrentPlanQuery({
 *   accessToken: // value for 'accessToken'
 * });
 */
export function useCurrentPlanQuery(variables: CurrentPlanQueryVariables | VueCompositionApi.Ref<CurrentPlanQueryVariables> | ReactiveFunction<CurrentPlanQueryVariables>, options: VueApolloComposable.UseQueryOptions<CurrentPlanQuery, CurrentPlanQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CurrentPlanQuery, CurrentPlanQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CurrentPlanQuery, CurrentPlanQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CurrentPlanQuery, CurrentPlanQueryVariables>(CurrentPlanDocument, variables, options);
}
export type CurrentPlanQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CurrentPlanQuery, CurrentPlanQueryVariables>;