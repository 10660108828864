import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type LunchTimeQueryVariables = Types.Exact<{
  accessToken?: Types.Maybe<Types.Scalars['String']>;
}>;


export type LunchTimeQuery = (
  { __typename?: 'Query' }
  & { lunchTime: (
    { __typename?: 'LunchTime' }
    & Pick<Types.LunchTime, 'startHour' | 'startMinute' | 'endHour' | 'endMinute'>
  ) }
);


export const LunchTimeDocument = gql`
    query lunchTime($accessToken: String) {
  lunchTime(accessToken: $accessToken) {
    startHour
    startMinute
    endHour
    endMinute
  }
}
    `;

/**
 * __useLunchTimeQuery__
 *
 * To run a query within a Vue component, call `useLunchTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLunchTimeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLunchTimeQuery({
 *   accessToken: // value for 'accessToken'
 * });
 */
export function useLunchTimeQuery(variables: LunchTimeQueryVariables | VueCompositionApi.Ref<LunchTimeQueryVariables> | ReactiveFunction<LunchTimeQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<LunchTimeQuery, LunchTimeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LunchTimeQuery, LunchTimeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<LunchTimeQuery, LunchTimeQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<LunchTimeQuery, LunchTimeQueryVariables>(LunchTimeDocument, variables, options);
}
export type LunchTimeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<LunchTimeQuery, LunchTimeQueryVariables>;