<template>
  <div class="adjustable-time-periods">
    <PageHeader
      title="受付可能な時間帯の設定"
      :backIcon="true"
      to="/settings"
    />
    <div class="contents-wrapper">
      <b-loading :is-full-page="false" :active="loading" />
      <div class="detail-contents">
        <div class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">
              受付可能な時間帯の設定方法
            </p>
          </div>
          <div class="value">
            <p v-if="isDaily" class="is-size-6">毎日同じ時間帯で設定する</p>
            <p v-else class="is-size-6">曜日ごとに時間帯を設定する</p>
          </div>
        </div>
      </div>
      <div class="detail-contents">
        <div class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">時間帯の設定</p>
          </div>
          <div v-if="isDaily" class="periods-wrapper">
            <div
              class="period"
              v-for="period in adjustableTimePeriods.dailyTimePeriods"
              :key="period.id"
            >
              <p class="day is-size-7 has-text-weight-bold">
                {{ period.wday }}
              </p>
              <p class="time is-size-6">{{ getTimeRange(period) }}</p>
            </div>
          </div>
          <div v-else class="periods-wrapper">
            <div
              class="period"
              v-for="period in adjustableTimePeriods.weeklyTimePeriods"
              :key="period.id"
            >
              <p class="day is-size-7 has-text-weight-bold">
                {{ period.wday }}
              </p>
              <p class="time is-size-6">{{ getTimeRange(period) }}</p>
            </div>
          </div>
        </div>
      </div>
      <router-link to="/adjustable_time_periods/edit">
        <b-button class="edit-button" expanded>
          <p class="text is-size-6_5 has-text-weight-bold">
            受付可能な時間帯の設定を編集する
          </p>
        </b-button>
      </router-link>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
} from "@vue/composition-api";

import { useAuth } from "@/submodules/auth";
import { useAdjustableTimePeriodsQuery } from "./graphql/query/adjustableTimePeriods.generated";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";

const { getToken } = useAuth();
const fetchAdjustableTimePeriods = () => {
  const adjustableTimePeriods = ref({});
  const { onResult, loading } = useAdjustableTimePeriodsQuery({
    accessToken: getToken(),
  });
  onResult((result) => {
    console.log("result", result);
    adjustableTimePeriods.value = result.data.adjustableTimePeriods;
  });

  return {
    adjustableTimePeriods,
    loading,
  };
};

export default defineComponent({
  name: "AdjustableTimePeriods",
  components: {
    PageHeader,
    Footer,
  },
  setup() {
    const state = reactive({
      loading: true,
      adjustableTimePeriods: {
        dailyTimePeriods: [],
        weeklyTimePeriods: [],
      },
    });

    const { adjustableTimePeriods, loading } = fetchAdjustableTimePeriods();
    state.adjustableTimePeriods = adjustableTimePeriods;
    state.loading = loading;

    const isDaily = computed(() => {
      if (!state.adjustableTimePeriods.periodType) return false;
      return state.adjustableTimePeriods.periodType === "daily";
    });
    const getTimeRange = (period) => {
      if (!period?.startHour) return "";
      return `${period.startHour}時${period.startMinute}分 - ${period.endHour}時${period.endMinute}分`;
    };

    return {
      ...toRefs(state),
      isDaily,
      getTimeRange,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
}
.detail-contents {
  > .detail {
    width: 100%;
    text-align: left;
    padding: 8px 0;
    display: block;
    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .periods-wrapper {
      padding-top: 4px;
      .period {
        display: flex;
        align-items: center;
        .day {
        }
        .time {
          padding-left: 8px;
        }
      }
    }
  }
}
::v-deep .button {
  margin-top: 16px;
  color: white;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      color: white;
    }
  }
}
.edit-button {
  margin-top: 32px;
  background-color: colors(green);
  color: white;
}
</style>
