<template>
  <div class="news-row">
    <router-link class="areas-wrapper" :to="`${notification.url}`">
      <div class="icon-area">
        <CircleIcon :kind="notification.kind || ''" />
      </div>
      <div class="description-area">
        <div class="summary">
          <p class="text is-size-7 has-text-weight-bold">{{ summaryText }}</p>
        </div>
        <div class="detail">
          <p class="text is-size-8 has-text-weight-bold has-text-grey">
            {{ detailText }}
          </p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, computed } from "@vue/composition-api";

import CircleIcon from "@/components/shared/CircleIcon.vue";

export default defineComponent({
  name: "NewsRow",
  components: {
    CircleIcon,
  },
  props: {
    notification: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const summaryText = computed(() => {
      return props.notification?.content
    });
    const detailText = computed(() => {
      if (
        ["schedule_confirmed", "schedule_confirmed_as_attendee"].includes(
          props.notification?.kind
        )
      ) {
        return `${props.notification?.day} ${props.notification?.time} @ ${props.notification?.placeName}`;
      } else if (props.notification?.kind === "schedule_requested") {
        return `${props.notification?.name}様からアポ調整依頼がありました`;
      } else if (props.notification?.kind === "schedule_deleted") {
        if (
          !props.notification?.day &&
          !props.notification?.time &&
          !props.notification?.placeName
        ) {
          return null;
        } else {
          const day = props.notification?.day
            ? props.notification?.day + " "
            : "";
          const time = props.notification?.time
            ? props.notification?.time + " "
            : "";
          const placeName = props.notification?.placeName
            ? "@ " + props.notification?.placeName
            : "";
          return day + time + placeName;
        }
      } else {
        return "";
      }
    });

    return {
      summaryText,
      detailText,
    };
  },
});
</script>

<style lang="scss" scoped>
.news-row {
  height: 48px;
  width: 100%;
  display: flex;
  > .areas-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    border-top: solid 1px colors(light-gray);
    > .icon-area {
      > .circle-icon {
        display: flex;
      }
    }
    > .description-area {
      width: calc(100% - 32px);
      padding-left: 8px;
      > .summary {
        width: 100%;
        height: 16px;
        display: flex;
        flex-direction: row;
        > .text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: colors(dark-blue);
        }
      }
      > .detail {
        width: 100%;
        display: flex;
        flex-direction: row;
        > .text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
