import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type SaveHolidaySettingMutationVariables = Types.Exact<{
  accessToken?: Types.Maybe<Types.Scalars['String']>;
  isHolidayAdjustable?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type SaveHolidaySettingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'saveAdjustableTimePeriods'>
);


export const SaveHolidaySettingDocument = gql`
    mutation saveHolidaySetting($accessToken: String, $isHolidayAdjustable: Boolean) {
  saveAdjustableTimePeriods(
    accessToken: $accessToken
    isHolidayAdjustable: $isHolidayAdjustable
  )
}
    `;

/**
 * __useSaveHolidaySettingMutation__
 *
 * To run a mutation, you first call `useSaveHolidaySettingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSaveHolidaySettingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSaveHolidaySettingMutation({
 *   variables: {
 *     accessToken: // value for 'accessToken'
 *     isHolidayAdjustable: // value for 'isHolidayAdjustable'
 *   },
 * });
 */
export function useSaveHolidaySettingMutation(options: VueApolloComposable.UseMutationOptions<SaveHolidaySettingMutation, SaveHolidaySettingMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SaveHolidaySettingMutation, SaveHolidaySettingMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SaveHolidaySettingMutation, SaveHolidaySettingMutationVariables>(SaveHolidaySettingDocument, options);
}
export type SaveHolidaySettingMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SaveHolidaySettingMutation, SaveHolidaySettingMutationVariables>;