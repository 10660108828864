<template>
  <div class="latest-schedules">
    <div class="areas-wrapper">
      <div class="title-area">
        <p class="is-size-7 has-text-weight-bold">直近の確定済みアポ</p>
      </div>
      <div class="news-area">
        <b-loading :is-full-page="false" :active="loading" />
        <div v-if="schedules.length" class="schedules-container">
          <ConfirmedScheduleRow v-if="schedules[0]" :schedule="schedules[0]" />
          <ConfirmedScheduleRow v-if="schedules[1]" :schedule="schedules[1]" />
          <ConfirmedScheduleRow v-if="schedules[2]" :schedule="schedules[2]" />
        </div>
        <div v-else class="empty-container">
          <div v-if="!loading" class="text-container">
            <b-tag rounded>直近の確定済みアポはありません</b-tag>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, reactive, toRefs } from "@vue/composition-api";

import { useSchedulesQuery } from "../graphql/query/schedules.generated";
import { useAuth } from "@/submodules/auth";
import { useToast } from "@/submodules/toast";

import ConfirmedScheduleRow from "@/components/dashboard/ConfirmedScheduleRow.vue";

export default defineComponent({
  name: "LatestSchedules",
  components: {
    ConfirmedScheduleRow,
  },
  setup() {
    const state = reactive({
      loading: true,
      schedules: [],
    });

    const { getToken } = useAuth();
    const { toastError } = useToast();
    const { onResult, error } = useSchedulesQuery({
      accessToken: getToken(),
      status: "confirmed",
      limit: true,
    });
    onResult((result) => {
      if (error.value) {
        toastError();
        return;
      }
      console.log("result", result);
      state.loading = result.loading;
      state.schedules = [...result.data.schedules].reverse();
      console.log("schedules", state.schedules);
    });

    return {
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.latest-schedules {
  // height: 176px;
  > .areas-wrapper {
    padding: 16px;
    // border-bottom: solid 1px colors(light-gray);
    max-width: 760px;
    margin: 0 auto;
    > .title-area {
      height: 16px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: column;
    }
    > .news-area {
      // height: 144px;
      width: 100%;
      min-height: 48px;
      position: relative;
      > .empty-container {
        min-height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
