import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type SaveProfileMutationVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
  organization: Types.Scalars['String'];
  name: Types.Scalars['String'];
}>;


export type SaveProfileMutation = (
  { __typename?: 'Mutation' }
  & { saveProfile: (
    { __typename?: 'Profile' }
    & Pick<Types.Profile, 'id' | 'name'>
  ) }
);


export const SaveProfileDocument = gql`
    mutation saveProfile($accessToken: String!, $organization: String!, $name: String!) {
  saveProfile(accessToken: $accessToken, organization: $organization, name: $name) {
    id
    name
  }
}
    `;

/**
 * __useSaveProfileMutation__
 *
 * To run a mutation, you first call `useSaveProfileMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSaveProfileMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSaveProfileMutation({
 *   variables: {
 *     accessToken: // value for 'accessToken'
 *     organization: // value for 'organization'
 *     name: // value for 'name'
 *   },
 * });
 */
export function useSaveProfileMutation(options: VueApolloComposable.UseMutationOptions<SaveProfileMutation, SaveProfileMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SaveProfileMutation, SaveProfileMutationVariables>>) {
  return VueApolloComposable.useMutation<SaveProfileMutation, SaveProfileMutationVariables>(SaveProfileDocument, options);
}
export type SaveProfileMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SaveProfileMutation, SaveProfileMutationVariables>;