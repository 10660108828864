import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type CreateCalendarMutationVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
  name: Types.Scalars['String'];
  duration: Types.Scalars['Int'];
  timeRangeStartHour?: Types.Maybe<Types.Scalars['String']>;
  timeRangeStartMinute?: Types.Maybe<Types.Scalars['String']>;
  timeRangeEndHour?: Types.Maybe<Types.Scalars['String']>;
  timeRangeEndMinute?: Types.Maybe<Types.Scalars['String']>;
  placeId: Types.Scalars['String'];
  placeKind: Types.Scalars['String'];
  otherPlaceName?: Types.Maybe<Types.Scalars['String']>;
  attendeeIds?: Types.Maybe<Array<Types.Maybe<Types.Scalars['Int']>>>;
  needContactInfo?: Types.Maybe<Types.Scalars['Boolean']>;
  webMtgUrl?: Types.Maybe<Types.Scalars['String']>;
}>;


export type CreateCalendarMutation = (
  { __typename?: 'Mutation' }
  & { createCalendar: (
    { __typename?: 'Reservation' }
    & Pick<Types.Reservation, 'id' | 'name' | 'duration' | 'placeId'>
  ) }
);


export const CreateCalendarDocument = gql`
    mutation createCalendar($accessToken: String!, $name: String!, $duration: Int!, $timeRangeStartHour: String, $timeRangeStartMinute: String, $timeRangeEndHour: String, $timeRangeEndMinute: String, $placeId: String!, $placeKind: String!, $otherPlaceName: String, $attendeeIds: [Int], $needContactInfo: Boolean, $webMtgUrl: String) {
  createCalendar(
    accessToken: $accessToken
    name: $name
    duration: $duration
    timeRangeStartHour: $timeRangeStartHour
    timeRangeStartMinute: $timeRangeStartMinute
    timeRangeEndHour: $timeRangeEndHour
    timeRangeEndMinute: $timeRangeEndMinute
    placeId: $placeId
    placeKind: $placeKind
    otherPlaceName: $otherPlaceName
    attendeeIds: $attendeeIds
    needContactInfo: $needContactInfo
    webMtgUrl: $webMtgUrl
  ) {
    id
    name
    duration
    placeId
  }
}
    `;

/**
 * __useCreateCalendarMutation__
 *
 * To run a mutation, you first call `useCreateCalendarMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateCalendarMutation({
 *   variables: {
 *     accessToken: // value for 'accessToken'
 *     name: // value for 'name'
 *     duration: // value for 'duration'
 *     timeRangeStartHour: // value for 'timeRangeStartHour'
 *     timeRangeStartMinute: // value for 'timeRangeStartMinute'
 *     timeRangeEndHour: // value for 'timeRangeEndHour'
 *     timeRangeEndMinute: // value for 'timeRangeEndMinute'
 *     placeId: // value for 'placeId'
 *     placeKind: // value for 'placeKind'
 *     otherPlaceName: // value for 'otherPlaceName'
 *     attendeeIds: // value for 'attendeeIds'
 *     needContactInfo: // value for 'needContactInfo'
 *     webMtgUrl: // value for 'webMtgUrl'
 *   },
 * });
 */
export function useCreateCalendarMutation(options: VueApolloComposable.UseMutationOptions<CreateCalendarMutation, CreateCalendarMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateCalendarMutation, CreateCalendarMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateCalendarMutation, CreateCalendarMutationVariables>(CreateCalendarDocument, options);
}
export type CreateCalendarMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateCalendarMutation, CreateCalendarMutationVariables>;