import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type CalendarsQueryVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
  status?: Types.Maybe<Types.Scalars['String']>;
}>;


export type CalendarsQuery = (
  { __typename?: 'Query' }
  & { reservations: Array<(
    { __typename?: 'Reservation' }
    & Pick<Types.Reservation, 'id' | 'name' | 'duration' | 'timeRangeStartHour' | 'timeRangeStartMinute' | 'timeRangeEndHour' | 'timeRangeEndMinute' | 'placeId' | 'placeKind' | 'otherPlaceName' | 'status' | 'createdAt'>
  )> }
);


export const CalendarsDocument = gql`
    query calendars($accessToken: String!, $status: String) {
  reservations(accessToken: $accessToken, status: $status) {
    id
    name
    duration
    timeRangeStartHour
    timeRangeStartMinute
    timeRangeEndHour
    timeRangeEndMinute
    placeId
    placeKind
    otherPlaceName
    status
    createdAt
  }
}
    `;

/**
 * __useCalendarsQuery__
 *
 * To run a query within a Vue component, call `useCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCalendarsQuery({
 *   accessToken: // value for 'accessToken'
 *   status: // value for 'status'
 * });
 */
export function useCalendarsQuery(variables: CalendarsQueryVariables | VueCompositionApi.Ref<CalendarsQueryVariables> | ReactiveFunction<CalendarsQueryVariables>, options: VueApolloComposable.UseQueryOptions<CalendarsQuery, CalendarsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CalendarsQuery, CalendarsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CalendarsQuery, CalendarsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CalendarsQuery, CalendarsQueryVariables>(CalendarsDocument, variables, options);
}
export type CalendarsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CalendarsQuery, CalendarsQueryVariables>;