import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type SaveLunchTimeMutationVariables = Types.Exact<{
  accessToken?: Types.Maybe<Types.Scalars['String']>;
  startHour: Types.Scalars['String'];
  startMinute: Types.Scalars['String'];
  endHour: Types.Scalars['String'];
  endMinute: Types.Scalars['String'];
}>;


export type SaveLunchTimeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'saveLunchTime'>
);


export const SaveLunchTimeDocument = gql`
    mutation saveLunchTime($accessToken: String, $startHour: String!, $startMinute: String!, $endHour: String!, $endMinute: String!) {
  saveLunchTime(
    accessToken: $accessToken
    startHour: $startHour
    startMinute: $startMinute
    endHour: $endHour
    endMinute: $endMinute
  )
}
    `;

/**
 * __useSaveLunchTimeMutation__
 *
 * To run a mutation, you first call `useSaveLunchTimeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSaveLunchTimeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSaveLunchTimeMutation({
 *   variables: {
 *     accessToken: // value for 'accessToken'
 *     startHour: // value for 'startHour'
 *     startMinute: // value for 'startMinute'
 *     endHour: // value for 'endHour'
 *     endMinute: // value for 'endMinute'
 *   },
 * });
 */
export function useSaveLunchTimeMutation(options: VueApolloComposable.UseMutationOptions<SaveLunchTimeMutation, SaveLunchTimeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SaveLunchTimeMutation, SaveLunchTimeMutationVariables>>) {
  return VueApolloComposable.useMutation<SaveLunchTimeMutation, SaveLunchTimeMutationVariables>(SaveLunchTimeDocument, options);
}
export type SaveLunchTimeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SaveLunchTimeMutation, SaveLunchTimeMutationVariables>;