<template>
  <div class="calendars">
    <PageHeader title="カレンダー" />
    <div class="calendars-wrapper">
      <div v-if="loading" class="loading">
        <b-loading :is-full-page="false" :active="loading" />
      </div>
      <CalendarRow v-for="calendar in reservationCalendars" :calendar="calendar || {}" :key="calendar.id" />
      <div v-if="!reservationCalendars.length" class="empty-container">
        <div v-if="!loading" class="text-container">
          <b-tag rounded>カレンダーはありません</b-tag>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, reactive, toRefs } from '@vue/composition-api'
import { useCalendarsQuery } from './graphql/query/calendars.generated.ts'
import { useAuth } from '@/submodules/auth'

import PageHeader from '@/components/PageHeader.vue'
import Footer from '@/components/Footer.vue'
import CalendarRow from '@/components/calendars/CalendarRow.vue'

const { getToken } = useAuth()

export default defineComponent({
  name: 'Calendars',
  components: {
    PageHeader,
    Footer,
    CalendarRow,
  },
  setup() {
    const state = reactive({
      reservationCalendars: [],
      loading: true,
    })
    const { onResult, error } = useCalendarsQuery({ accessToken: getToken() })
    if (error.value) { console.error(error) }
    onResult((result) => {
      console.log('reservations', result.data.reservations)
      state.reservationCalendars = result.data.reservations
      state.loading = false
    })

    return {
      ...toRefs(state),
    }
  }
})
</script>

<style lang="scss" scoped>
.calendars-wrapper {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .loading {
    width: 100%;
    min-height: 80px;
    position: relative;
  }
  .empty-container {
    min-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>