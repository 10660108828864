<template>
  <div v-if="!isServiceAvailable" class="service-available-alert-section">
    <article class="message is-danger">
      <div class="message-header">
        <b-icon icon="alert-circle-outline" />
        <span>予約カレンダーが無効です</span>
      </div>
      <div class="message-body is-size-6_5 has-text-weight-bold">
        <p>
          今月受け付けたアポ予約が3件の上限に達したため、現在予約カレンダーが無効になっています。
        </p>
        <p>
          <a class="link" href="/purchase_plan">こちら</a
          >から月額990円の有料プランをご購入頂くと、アポ予約の上限が無制限になります。
        </p>
      </div>
    </article>
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, reactive, ref, toRefs } from "@vue/composition-api";

import { useIsServiceAvailableQuery } from "../graphql/query/isServiceAvailable.generated";
import { useAuth } from "@/submodules/auth";

const { getToken } = useAuth();
const fetchIsServiceAvailable = () => {
  const isServiceAvailable = ref(true);
  const { onResult } = useIsServiceAvailableQuery({
    accessToken: getToken(),
  });
  onResult((result) => {
    console.log("DEBUG result", result);
    isServiceAvailable.value = result.data.isServiceAvailable;
  });

  return isServiceAvailable;
};

export default defineComponent({
  name: "ServiceAvailableAlertSection",
  components: {},
  setup() {
    const state = reactive({
      isServiceAvailable: true,
    });

    state.isServiceAvailable = fetchIsServiceAvailable();

    return {
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.service-available-alert-section {
  padding: 16px;
  max-width: 760px;
  margin: 0 auto;
}

::v-deep .message-header {
  justify-content: left;
  span {
    margin-right: 4px;
  }
}
::v-deep .message {
  a:not(.button):not(.tag):not(.dropdown-item) {
    color: blue;
  }
  .link {
    color: blue;
  }
}
</style>
