import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type EventIntervalsQueryVariables = Types.Exact<{
  accessToken?: Types.Maybe<Types.Scalars['String']>;
}>;


export type EventIntervalsQuery = (
  { __typename?: 'Query' }
  & { adjustableTimePeriods: (
    { __typename?: 'AdjustableTimePeriods' }
    & Pick<Types.AdjustableTimePeriods, 'intervalBeforeEvent' | 'intervalAfterEvent'>
  ) }
);


export const EventIntervalsDocument = gql`
    query eventIntervals($accessToken: String) {
  adjustableTimePeriods(accessToken: $accessToken) {
    intervalBeforeEvent
    intervalAfterEvent
  }
}
    `;

/**
 * __useEventIntervalsQuery__
 *
 * To run a query within a Vue component, call `useEventIntervalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventIntervalsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useEventIntervalsQuery({
 *   accessToken: // value for 'accessToken'
 * });
 */
export function useEventIntervalsQuery(variables: EventIntervalsQueryVariables | VueCompositionApi.Ref<EventIntervalsQueryVariables> | ReactiveFunction<EventIntervalsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<EventIntervalsQuery, EventIntervalsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EventIntervalsQuery, EventIntervalsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EventIntervalsQuery, EventIntervalsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<EventIntervalsQuery, EventIntervalsQueryVariables>(EventIntervalsDocument, variables, options);
}
export type EventIntervalsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<EventIntervalsQuery, EventIntervalsQueryVariables>;