<template>
  <div class="schedules">
    <PageHeader title="アポ一覧" />
    <div class="tabs-wrapper">
      <b-tabs
        v-model="activeTab"
        position="is-centered"
        type="is-toggle"
        size="is-small"
        expanded
      >
        <b-tab-item label="確定済み">
          <b-loading :is-full-page="false" :active="loading" />
          <ScheduleRow
            v-for="schedule in confirmedSchedules"
            :schedule="schedule || {}"
            :key="schedule.id"
          />
          <div v-if="!confirmedSchedules.length" class="empty-container">
            <div v-if="!loading" class="text-container">
              <b-tag rounded>確定済みアポはありません</b-tag>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="キャンセル済み">
          <b-loading :is-full-page="false" :active="loading" />
          <ScheduleRow
            v-for="schedule in canceledSchedules"
            :schedule="schedule || {}"
            :key="schedule.id"
          />
          <div v-if="!canceledSchedules.length" class="empty-container">
            <div v-if="!loading" class="text-container">
              <b-tag rounded>キャンセル済みアポはありません</b-tag>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, reactive, toRefs } from "@vue/composition-api";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";
import { useSchedulesQuery } from "./graphql/query/schedules.generated.ts";
import { useAuth } from "@/submodules/auth";

import ScheduleRow from "@/components/schedules/ScheduleRow.vue";

const { getToken } = useAuth();
const querySchedules = (status) => {
  return new Promise((resolve) => {
    const { onResult, error } = useSchedulesQuery({
      accessToken: getToken(),
      status,
    });
    if (error.value) {
      console.error(error);
    }
    onResult((result) => {
      resolve(result.data?.schedules);
    });
  });
};

export default defineComponent({
  name: "Schedules",
  components: {
    PageHeader,
    Footer,
    ScheduleRow,
  },
  setup() {
    const state = reactive({
      activeTab: 0,
      confirmedSchedules: [],
      canceledSchedules: [],
      loading: true,
    });
    querySchedules("confirmed").then((result) => {
      state.confirmedSchedules = result;
      state.loading = false;
    });
    querySchedules("canceled").then(
      (result) => (state.canceledSchedules = result)
    );

    return {
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.schedules-wrapper {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.tabs-wrapper {
  width: calc(100% - 32px);
  max-width: 760px;
  margin: 0 auto;
  padding-bottom: 56px;
}
::v-deep .b-tabs {
  .tab-content {
    min-height: 80px;
    padding: 16px 0;
  }
  .tabs.is-toggle {
    ul {
      li {
        width: 50%;
        a {
          height: 24px;
        }
        &.is-active {
          a {
            background-color: colors(blue);
            border-color: colors(blue);
            font-weight: bold;
          }
        }
      }
    }
    font-size: 0.85rem;
  }
}
.empty-container {
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
