<template>
  <div class="confirmed-schedule-row">
    <router-link class="areas-wrapper" :to="`/schedules/${schedule.id}`">
      <div class="icon-area">
        <CircleIcon :kind="schedule.status || ''" />
      </div>
      <div class="description-area">
        <div class="summary">
          <p class="text is-size-7 has-text-weight-bold">{{ summaryText }}</p>
        </div>
        <div class="detail">
          <p class="text is-size-8 has-text-weight-bold has-text-grey">
            {{ detailText }}
          </p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, computed } from "@vue/composition-api";

import CircleIcon from "@/components/shared/CircleIcon.vue";

export default defineComponent({
  name: "ConfirmedScheduleRow",
  components: {
    CircleIcon,
  },
  props: {
    schedule: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const summaryText = computed(() => {
      return props.schedule.summaryText
      // if (!props.schedule?.requesteeName) {
      //   return "";
      // }
      // return `${props.schedule.requesteeName}様とのアポが確定しました`;
    });
    const detailText = computed(() => {
      if (!props.schedule?.date) {
        return "";
      }
      return `${props.schedule?.date} ${props.schedule?.time} @ ${props.schedule?.placeName}`;
    });

    return {
      summaryText,
      detailText,
    };
  },
});
</script>

<style lang="scss" scoped>
.confirmed-schedule-row {
  height: 48px;
  width: 100%;
  display: flex;
  > .areas-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    border-top: solid 1px colors(light-gray);
    > .icon-area {
      > .circle-icon {
        display: flex;
      }
    }
    > .description-area {
      width: calc(100% - 32px);
      padding-left: 8px;
      > .summary {
        width: 100%;
        height: 16px;
        display: flex;
        flex-direction: row;
        > .text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: colors(dark-blue);
        }
      }
      > .detail {
        width: 100%;
        display: flex;
        flex-direction: row;
        > .text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
