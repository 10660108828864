import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

// // HTTP connection to the API
// const httpLink = createHttpLink({
//   // You should use an absolute URL here
//   uri: config.graphqlUrl,
// })

// // Cache implementation
// const cache = new InMemoryCache()

// // Create the apollo client
// export const apolloClient = new ApolloClient({
//   link: httpLink,
//   cache,
// })

// Vue.use(VueApollo)

// export const apolloProvider = new VueApollo({
//   defaultClient: apolloClient,
// })

export const useApollo = (graphqlUrl) => {
  const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: graphqlUrl,
    // credentials: 'include', 
    // fetchOptions: {
    //   mode: 'no-cors',
    // },
    // fetch: enchancedFetch,
  })
  const cache = new InMemoryCache()
  const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
    defaultOptions: {
      watchQuery: {fetchPolicy: 'network-only'},
      query: {fetchPolicy: 'network-only'}
    },
  })
  Vue.use(VueApollo)
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
  })

  return {
    apolloClient,
    apolloProvider
  }
}