<template>
  <div class="circle-icon">
    <ConfirmedCalendarIcon v-if="kind==='schedule_confirmed' || kind==='confirmed'" />
    <RequestedCalendarIcon v-else-if="kind==='schedule_requested'" />
    <RequestingCalendarIcon v-else-if="kind==='schedule_requesting'" />
    <AttendeeCalendarIcon v-else-if="kind==='schedule_confirmed_as_attendee'" />
    <ReservationCalendarIcon v-else-if="kind==='reservation'" />
    <CanceledCalendarIcon v-else-if="kind==='schedule_deleted' || kind==='deleted'" />
    <MegaphoneIcon v-else-if="kind==='information'" />
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent } from '@vue/composition-api'

import CanceledCalendarIcon from '@/assets/img/common/circle_icons/canceled_calendar.svg'
import ConfirmedCalendarIcon from '@/assets/img/common/circle_icons/confirmed_calendar.svg'
import MegaphoneIcon from '@/assets/img/common/circle_icons/megaphone.svg'
import RequestedCalendarIcon from '@/assets/img/common/circle_icons/requested_calendar.svg'
import RequestingCalendarIcon from '@/assets/img/common/circle_icons/requesting_calendar.svg'
import ReservationCalendarIcon from '@/assets/img/common/circle_icons/reservation_calendar.svg'
import AttendeeCalendarIcon from '@/assets/img/common/circle_icons/attendee_calendar.svg'

export default defineComponent({
  name: 'CircleIcon',
  components: {
    CanceledCalendarIcon,
    ConfirmedCalendarIcon,
    MegaphoneIcon,
    RequestedCalendarIcon,
    RequestingCalendarIcon,
    ReservationCalendarIcon,
    AttendeeCalendarIcon,
  },
  props: {
    kind: {
      type: String,
      default: () => '',
    }
  },
})
</script>

<style lang="scss" scoped>
</style>