import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type CreateScheduleMutationVariables = Types.Exact<{
  accessToken?: Types.Maybe<Types.Scalars['String']>;
  requesteeName?: Types.Maybe<Types.Scalars['String']>;
  id?: Types.Maybe<Types.Scalars['Int']>;
  placeKind?: Types.Maybe<Types.Scalars['String']>;
  placeId?: Types.Maybe<Types.Scalars['String']>;
  otherPlaceName?: Types.Maybe<Types.Scalars['String']>;
  startAt?: Types.Maybe<Types.Scalars['String']>;
  tel?: Types.Maybe<Types.Scalars['String']>;
  emailForNonLoginUser?: Types.Maybe<Types.Scalars['String']>;
}>;


export type CreateScheduleMutation = (
  { __typename?: 'Mutation' }
  & { createSchedule: (
    { __typename?: 'Schedule' }
    & Pick<Types.Schedule, 'id'>
  ) }
);


export const CreateScheduleDocument = gql`
    mutation createSchedule($accessToken: String, $requesteeName: String, $id: Int, $placeKind: String, $placeId: String, $otherPlaceName: String, $startAt: String, $tel: String, $emailForNonLoginUser: String) {
  createSchedule(
    accessToken: $accessToken
    requesteeName: $requesteeName
    id: $id
    placeKind: $placeKind
    placeId: $placeId
    otherPlaceName: $otherPlaceName
    startAt: $startAt
    tel: $tel
    emailForNonLoginUser: $emailForNonLoginUser
  ) {
    id
  }
}
    `;

/**
 * __useCreateScheduleMutation__
 *
 * To run a mutation, you first call `useCreateScheduleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateScheduleMutation({
 *   variables: {
 *     accessToken: // value for 'accessToken'
 *     requesteeName: // value for 'requesteeName'
 *     id: // value for 'id'
 *     placeKind: // value for 'placeKind'
 *     placeId: // value for 'placeId'
 *     otherPlaceName: // value for 'otherPlaceName'
 *     startAt: // value for 'startAt'
 *     tel: // value for 'tel'
 *     emailForNonLoginUser: // value for 'emailForNonLoginUser'
 *   },
 * });
 */
export function useCreateScheduleMutation(options: VueApolloComposable.UseMutationOptions<CreateScheduleMutation, CreateScheduleMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateScheduleMutation, CreateScheduleMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateScheduleMutation, CreateScheduleMutationVariables>(CreateScheduleDocument, options);
}
export type CreateScheduleMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateScheduleMutation, CreateScheduleMutationVariables>;