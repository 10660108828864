<template>
  <div class="lunchtime-setting">
    <PageHeader
      title="昼休みの設定編集"
      :backIcon="true"
      to="/lunch_time_setting"
    />
    <div class="contents-wrapper">
      <b-loading :is-full-page="false" :active="loading" />
      <div class="detail-contents">
        <b-field class="detail" label="昼休み開始時間">
          <b-select
            v-model="lunchStartTime"
            class="form-item"
            placeholder="昼休み開始時間を選択して下さい"
          >
            <option
              v-for="time in filteredTimes"
              :value="time.val"
              :key="time.val"
            >
              {{ time.text }}
            </option>
          </b-select>
        </b-field>

        <b-field class="detail" label="昼休み終了時間">
          <b-select
            v-model="lunchEndTime"
            class="form-item"
            placeholder="昼休み終了時間を選択して下さい"
          >
            <option
              v-for="time in filteredTimes"
              :value="time.val"
              :key="time.val"
            >
              {{ time.text }}
            </option>
          </b-select>
        </b-field>
      </div>

      <b-button class="save-button" expanded @click="onClickSaveButton">
        <p class="text is-size-6_5 has-text-weight-bold">
          昼休みの設定を保存する
        </p>
      </b-button>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import {
  defineComponent,
  reactive,
  ref,
  toRefs,
  computed,
} from "@vue/composition-api";

import { useAuth } from "@/submodules/auth";
import { useToast } from "@/submodules/toast";
import { useLunchTimeQuery } from "./graphql/query/lunchTime.generated";
import { useSaveLunchTimeMutation } from "./graphql/mutation/saveLunchTime.generated";
import { times } from "@/submodules/constants";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";

const { getToken } = useAuth();
const { toastError, setSessionMessage } = useToast();
const fetchLunchTime = () => {
  console.log("fetchLunchTime start");
  const lunchTime = ref({});
  const { onResult, loading } = useLunchTimeQuery({
    accessToken: getToken(),
  });
  onResult((result) => {
    console.log("result", result);
    lunchTime.value = result.data.lunchTime;
  });

  return {
    lunchTime,
    loading,
  };
};
const useLunchTimeMutation = () => {
  const { mutate: saveLunchTimeMutate } = useSaveLunchTimeMutation();
  const saveLunchTime = (lunchTime) => {
    const result = saveLunchTimeMutate({
      accessToken: getToken(),
      startHour: lunchTime.startHour,
      startMinute: lunchTime.startMinute,
      endHour: lunchTime.endHour,
      endMinute: lunchTime.endMinute,
    });

    return result;
  };

  return saveLunchTime;
};
const filteredTimes = times.filter((time) => time.val);
const paddedTime = (time) => {
  if (!time) return "";
  return ("0" + time).slice(-2);
};

export default defineComponent({
  name: "LunchTime",
  components: {
    PageHeader,
    Footer,
  },
  setup(_, { root }) {
    const state = reactive({
      loading: true,
      lunchTime: {},
    });

    const { lunchTime, loading } = fetchLunchTime();
    state.lunchTime = lunchTime;
    state.loading = loading;

    const lunchStartTime = computed({
      get: () => {
        if (!state.lunchTime.startHour) return null;

        return `${paddedTime(state.lunchTime.startHour)}:${paddedTime(
          state.lunchTime.startMinute
        )}`;
      },
      set: (startTime) => {
        console.log("setter startTime", startTime);
        if (!startTime) return;
        state.lunchTime.startHour = startTime.slice(0, 2);
        state.lunchTime.startMinute = startTime.slice(-2);
      },
    });
    const lunchEndTime = computed({
      get: () => {
        if (!state.lunchTime.endHour) return null;
        return `${paddedTime(state.lunchTime.endHour)}:${paddedTime(
          state.lunchTime.endMinute
        )}`;
      },
      set: (endTime) => {
        if (!endTime) return;
        state.lunchTime.endHour = endTime.slice(0, 2);
        state.lunchTime.endMinute = endTime.slice(-2);
      },
    });
    const saveLunchTime = useLunchTimeMutation();
    const onClickSaveButton = async () => {
      try {
        await saveLunchTime(state.lunchTime);
        setSessionMessage("昼休みの設定を保存しました");
        root.$router.push("/lunch_time_setting");
      } catch (error) {
        console.error(error);
        toastError();
      }
    };

    return {
      ...toRefs(state),
      lunchStartTime,
      lunchEndTime,
      filteredTimes,
      onClickSaveButton,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
}
.detail-contents {
  > .detail {
    width: 100%;
    text-align: left;
    padding: 8px 0;
    display: block;
    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .button {
  margin-top: 16px;
  color: white;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      color: white;
    }
  }
}
.save-button {
  margin-top: 32px;
  background-color: colors(green);
  color: white;
}
</style>
