import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type NotificationsQueryVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
}>;


export type NotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications: Array<(
    { __typename?: 'Notification' }
    & Pick<Types.Notification, 'id' | 'title' | 'color' | 'day' | 'time' | 'name' | 'url' | 'kind' | 'label' | 'content' | 'placeName' | 'read'>
  )> }
);


export const NotificationsDocument = gql`
    query notifications($accessToken: String!) {
  notifications(accessToken: $accessToken) {
    id
    title
    color
    day
    time
    name
    url
    kind
    label
    content
    placeName
    read
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a Vue component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useNotificationsQuery({
 *   accessToken: // value for 'accessToken'
 * });
 */
export function useNotificationsQuery(variables: NotificationsQueryVariables | VueCompositionApi.Ref<NotificationsQueryVariables> | ReactiveFunction<NotificationsQueryVariables>, options: VueApolloComposable.UseQueryOptions<NotificationsQuery, NotificationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<NotificationsQuery, NotificationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<NotificationsQuery, NotificationsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, variables, options);
}
export type NotificationsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<NotificationsQuery, NotificationsQueryVariables>;