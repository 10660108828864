<template>
  <div class="new-schedule">
    <PageHeader title="予約カレンダー" :backIcon="loggedIn()" />
    <ValidationObserver v-if="calendar.isAvailable" v-slot="{ invalid }">
      <div class="content-wrapper">
        <b-loading :is-full-page="false" :active="isCalendarLoading" />
        <CalendarRow :calendar-name="calendarName" />
        <div class="calendar-details">
          <div class="detail">
            <div class="name">
              <p class="is-size-7 has-text-weight-bold">必要な時間</p>
            </div>
            <div class="value">
              <p class="is-size-6">{{ `${calendarDuration}分` }}</p>
            </div>
          </div>
          <div v-if="isPlaceDetermined" class="detail">
            <div class="name">
              <p class="is-size-7 has-text-weight-bold">場所</p>
            </div>
            <div class="value">
              <p class="is-size-6">{{ placeName }}</p>
            </div>
          </div>
        </div>

        <div class="form-wrapper">
          <div class="title-area">
            <span class="is-size-6_5 has-text-weight-bold label-text"
              >下記内容をご入力下さい</span
            >
          </div>
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            name="日時選択"
          >
            <b-field
              class="form-item"
              :type="{ 'is-danger': errors[0] }"
              :message="errors[0]"
            >
              <template #label>
                <div class="labels-wrapper">
                  <div class="label-wrapper">
                    <span class="label-text">日時選択</span>
                    <b-tag type="is-danger">必須</b-tag>
                  </div>
                  <div
                    v-if="!datetimeNotSelected"
                    class="label-wrapper link-text"
                    @click="clearSelectedDatetime"
                  >
                    <EditCalendarIcon />
                    <span>変更</span>
                  </div>
                </div>
              </template>
              <b-input
                v-if="!datetimeNotSelected"
                class="form-item"
                v-model="computedSelectedDatetime"
                disabled
              ></b-input>
              <CalendarSheet
                v-if="datetimeNotSelected"
                @update-selected-datetime="selectedDatetime = $event"
                :calendar-data="calendarData"
                :isLoading="isCalendarDataLodaing"
              />
            </b-field>
          </ValidationProvider>
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            name="お名前"
          >
            <b-field
              class="form-item"
              :type="{ 'is-danger': errors[0] }"
              :message="errors[0]"
            >
              <template #label>
                <span class="label-text">お名前</span>
                <b-tag type="is-danger">必須</b-tag>
              </template>
              <b-input
                class="form-item"
                v-model="schedule.requesteeName"
                placeholder="例）アポとる 太郎"
              ></b-input>
            </b-field>
          </ValidationProvider>
          <ValidationProvider
            v-if="!isPlaceDetermined"
            rules="required"
            v-slot="{ errors }"
            name="場所"
          >
            <b-field
              class="form-item"
              :type="{ 'is-danger': errors[0] }"
              :message="errors[0]"
            >
              <template #label>
                <span class="label-text">場所選択</span>
                <b-tag type="is-danger">必須</b-tag>
              </template>
              <b-select
                v-model="schedule.place"
                class="form-item"
                placeholder="場所を選択して下さい"
              >
                <option
                  v-for="place in placeOptions"
                  :value="place"
                  :key="place.id"
                >
                  {{ place.name }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
          <ValidationProvider
            v-if="isPlaceKindOther"
            rules="required"
            v-slot="{ errors }"
            name="場所（自由入力）"
          >
            <b-field
              class="form-item"
              :type="{ 'is-danger': errors[0] }"
              :message="errors[0]"
            >
              <template #label>
                <span class="label-text">場所（自由入力）</span>
                <b-tag type="is-danger">必須</b-tag>
              </template>
              <b-input
                class="form-item"
                v-model="schedule.otherPlaceName"
                placeholder="例）○○駅前の××カフェ"
              />
            </b-field>
          </ValidationProvider>
          <ValidationProvider
            v-if="calendar.needContactInfo"
            rules="required|phone"
            v-slot="{ errors }"
            name="電話番号"
          >
            <b-field
              class="form-item"
              :type="{ 'is-danger': errors[0] }"
              :message="errors[0]"
            >
              <template #label>
                <span class="label-text">連絡先電話番号</span>
                <b-tag type="is-danger">必須</b-tag>
              </template>
              <b-input
                class="form-item"
                v-model="schedule.tel"
                placeholder="例）090-xxxx-xxxx"
              />
            </b-field>
          </ValidationProvider>
        </div>
        <b-button
          class="createschedule-button"
          expanded
          @click="createSchedule"
          :disabled="invalid || isCalendarLoading"
        >
          <CheckedCalendarIcon />
          <p class="text is-size-6_5 has-text-weight-bold">アポ予約を入れる</p>
        </b-button>
      </div>
    </ValidationObserver>
    <div v-else class="alert-section">
      <article class="message is-danger">
        <div class="message-body is-size-6_5 has-text-weight-bold">
          このカレンダーは現在無効です
        </div>
      </article>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
} from "@vue/composition-api";
import { useAuth } from "@/submodules/auth";
import { useToast } from "@/submodules/toast";
import { formatDateWithoutPadding, weekDayJp } from "@/submodules/format";
import { useCalendarQuery } from "../graphql/query/calendar.generated";
import { useCalendarDataQuery } from "../graphql/query/calendarData.generated";
import { useCreateScheduleMutation } from "../graphql/mutation/createSchedule.generated";

import PageHeader from "@/components/PageHeader.vue";
import CalendarRow from "@/components/schedules/CalendarRow.vue";
import CalendarSheet from "@/components/schedules/CalendarSheet.vue";
import EditCalendarIcon from "@/assets/img/common/outline_icons/edit_calendar.svg";
import CheckedCalendarIcon from "@/assets/img/common/outline_icons/checked_calendar.svg";
// import CalendarForm from "@/components/calendars/CalendarForm.vue";

const { getToken, loggedIn } = useAuth();
const { toastError, setSessionMessage } = useToast();

const useSaveCalendar = () => {
  const { mutate: saveScheduleMutation } = useCreateScheduleMutation();

  const mutate = async (calendar, schedule, startAt) => {
    console.log("schedule", schedule);
    console.log("calendar", calendar);

    const result = await saveScheduleMutation({
      id: calendar.id,
      accessToken: getToken(),
      requesteeName: schedule.requesteeName,
      placeId: schedule?.place?.id || calendar?.place?.id,
      placeKind: schedule?.place?.kind || calendar?.placeKind,
      otherPlaceName: schedule.otherPlaceName,
      startAt,
      tel: schedule.tel,
    });
    return result.data;
  };

  return {
    mutate,
  };
};
const fetchCalendarData = (calendarId) => {
  const variables = { calendarId }
  const accessToken = getToken()
  if (accessToken) {
    variables.accessToken = accessToken
  }
  const { result, onResult } = useCalendarDataQuery(variables);
  return {
    result: computed(() => result.value?.calendarData),
    onResult,
  };
};
const fetchCalendar = (calendarId) => {
  const { onResult } = useCalendarQuery({
    id: calendarId,
  });
  return onResult;
};

export default defineComponent({
  name: "NewSchedule",
  components: {
    PageHeader,
    CalendarRow,
    CalendarSheet,
    EditCalendarIcon,
    CheckedCalendarIcon,
  },
  setup(_, { root }) {
    const state = reactive({
      schedule: {
        requesteeName: "",
        otherPlaceName: null,
      },
      calendar: {
        name: null,
        duration: null,
        place: {
          kind: "",
        },
        otherPlaceName: null,
        isAvailable: true,
      },
      calendarData: {
        reservationName: "",
      },
      selectedDatetime: null,
      isCalendarLoading: true,
      isCalendarDataLodaing: true,
    });
    const {
      result: fetchCalendarDataResult,
      onResult: fetchCalendarDataOnResult,
    } = fetchCalendarData(root.$route.query.calendar_id);
    state.calendarData = fetchCalendarDataResult;
    fetchCalendarDataOnResult(() => {
      state.isCalendarDataLodaing = false;
    });
    const onResultFetchCalendar = fetchCalendar(root.$route.query.calendar_id);
    onResultFetchCalendar((result) => {
      console.log("onResultFetchCalendar result", result);
      state.calendar = result.data.calendar;
      state.isCalendarLoading = false;
    });

    const isPlaceDetermined = computed(() => {
      return state.calendar?.placeKind !== "follow_requestee";
    });
    const placeName = computed(() => {
      if (!state.calendar?.placeKind) return "";
      if (state.calendar?.placeKind === "registered") {
        return state.calendar.place.name;
      } else if (state.calendar?.placeKind === "other") {
        return state.calendar.otherPlaceName;
      } else {
        return "";
      }
    });
    const isPlaceKindOther = computed(() => {
      return state.schedule?.place?.kind === "other";
    });
    const calendarName = computed(() => {
      return state.calendar?.name || "";
    });
    const calendarDuration = computed(() => {
      return state.calendar?.duration || "";
    });
    const placeOptions = computed(() => {
      return state.calendarData?.places || [];
    });
    const datetimeNotSelected = computed(() => {
      return !state.selectedDatetime?.time;
    });
    const computedSelectedDatetime = computed(() => {
      if (!state.selectedDatetime?.time) return "";
      const datetimeString = `${state.selectedDatetime.date} ${state.selectedDatetime.time}`.replace(
        /-/g,
        "/"
      );
      const startDate = new Date(datetimeString);
      const endDate = new Date(datetimeString);
      endDate.setMinutes(startDate.getMinutes() + state.calendar.duration);

      return `${formatDateWithoutPadding(startDate, "M月d日")}（${weekDayJp(
        startDate
      )}） ${formatDateWithoutPadding(
        startDate,
        "H:mm"
      )} - ${formatDateWithoutPadding(endDate, "H:mm")}`;
    });
    const clearSelectedDatetime = () => {
      state.selectedDatetime = {};
    };

    const useSaveCalendarHandler = useSaveCalendar(root.$route.params.id);
    const createSchedule = async () => {
      console.log("createSchedule CALLED");
      state.isCalendarLoading = true;
      try {
        const startAt = `${state.selectedDatetime.date} ${state.selectedDatetime.time}`;
        const saveCalendarResult = await useSaveCalendarHandler.mutate(
          state.calendar,
          state.schedule,
          startAt
        );
        state.isCalendarLoading = false;
        console.log("saveCalendarResult", saveCalendarResult);
        const scheduleId = saveCalendarResult.createSchedule.id;
        setSessionMessage("アポ予約を確定しました");
        root.$router.push("/schedules/" + scheduleId);
      } catch (error) {
        console.error(error);
        toastError();
      }
    };

    return {
      loggedIn,
      ...toRefs(state),
      isPlaceDetermined,
      placeName,
      isPlaceKindOther,
      calendarName,
      calendarDuration,
      placeOptions,
      datetimeNotSelected,
      computedSelectedDatetime,
      clearSelectedDatetime,
      createSchedule,
    };
  },
});
</script>

<style lang="scss" scoped>
.content-wrapper {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px;
  text-align: left;
}
.calendar-details {
  > .detail {
    width: 100%;
    text-align: left;
    padding-top: 16px;
    .name {
      height: 20px;
      white-space: nowrap;
    }
  }
}
.form-wrapper {
  > .title-area {
    width: 100%;
    height: 20px;
    border-bottom: 1px solid colors(light-gray);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  padding: 48px 0 24px;

  flex-direction: column;
}
::v-deep .tag {
  height: 16px;
  margin-left: 4px;
  color: white;
  span {
    font-weight: 700;
  }
}
.labels-wrapper {
  display: flex;
  justify-content: space-between;
  .label-wrapper {
    display: flex;
    white-space: nowrap;
    align-items: center;
    .svg {
      height: 13px;
      fill: currentColor;
      padding-right: 2px;
    }
    &.link-text {
      color: colors(blue);
    }
  }
}
::v-deep input:disabled {
  color: colors(black);
  -webkit-text-fill-color: colors(black);
  opacity: 1;
}
.createschedule-button {
  margin-top: 16px;
  background-color: colors(green);
  .svg {
    margin-bottom: 3px;
    fill: white;
    width: 18px;
    height: 18px;
  }
}
::v-deep .button {
  color: white;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      padding-left: 8px;
    }
  }
}
.field {
  padding-top: 16px;
}
.alert-section {
  padding: 16px;
  max-width: 760px;
  margin: 0 auto;
}

::v-deep .message-header {
  justify-content: left;
  span {
    margin-right: 4px;
  }
}
</style>
