<template>
  <div class="edit-calendar">
    <PageHeader title="カレンダー編集" :backIcon="true" />
    <CalendarForm
      @input="calendar = $event"
      :loading="loading"
      :calendar="calendar"
      :place-options="placeOptions"
      :attendees="attendees"
      @submit="submit"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
} from "@vue/composition-api";
import { useAuth } from "@/submodules/auth";
import { useToast } from "@/submodules/toast";
import { formatToPaddedTime } from "@/submodules/format";
import { useCalendarQuery } from "./graphql/query/calendar.generated";
import { usePlaceOptionsQuery } from "./graphql/query/placeOptions.generated";
import { useAttendeesQuery } from "./graphql/query/attendees.generated";
import { useSaveCalendarMutation } from "./graphql/mutation/saveCalendar.generated";

import PageHeader from "@/components/PageHeader.vue";
import CalendarForm from "@/components/calendars/CalendarForm.vue";

const { getToken } = useAuth();
const { toastError, setSessionMessage } = useToast();

const mapToCalendar = (responseCalendar) => {
  if (!responseCalendar) {
    return { place: {}, attendees: [] };
  }
  return {
    name: responseCalendar.name,
    duration: responseCalendar.duration,
    place: {
      id: responseCalendar.place?.id || responseCalendar.placeId,
      kind: responseCalendar.placeKind,
    },
    otherPlaceName: responseCalendar.otherPlaceName,
    startTime: formatToPaddedTime(
      responseCalendar.timeRangeStartHour,
      responseCalendar.timeRangeStartMinute
    ),
    endTime: formatToPaddedTime(
      responseCalendar.timeRangeEndHour,
      responseCalendar.timeRangeEndMinute
    ),
    attendees: responseCalendar.attendees.map((attendee) => {
      return { id: attendee.id, name: attendee.profile.name };
    }),
    needContactInfo: responseCalendar.needContactInfo,
    webMtgUrl: responseCalendar.webMtgUrl,
  };
};
const fetchCalendar = (calendarId) => {
  const { onResult } = useCalendarQuery({
    id: calendarId,
  });
  return onResult;
};
const fetchPlaceOptions = () => {
  const { result } = usePlaceOptionsQuery({ accessToken: getToken() });
  return computed(() => result.value?.placeOptions);
};
const fetchAttendees = () => {
  const { result } = useAttendeesQuery({ accessToken: getToken() });
  return computed(() => result.value?.attendees);
};
const useSaveCalendar = (calendarId) => {
  const { mutate: saveCalendarMutation } = useSaveCalendarMutation();

  const mutate = async (calendar) => {
    const result = await saveCalendarMutation({
      id: calendarId,
      accessToken: getToken(),
      name: calendar.name,
      duration: calendar.duration,
      timeRangeStartHour: calendar.startTime?.substring(0, 2),
      timeRangeStartMinute: calendar.startTime?.substring(3, 5),
      timeRangeEndHour: calendar.endTime?.substring(0, 2),
      timeRangeEndMinute: calendar.endTime?.substring(3, 5),
      placeId: calendar.place.id,
      placeKind: calendar.place.kind,
      otherPlaceName: calendar.otherPlaceName,
      attendeeIds: calendar.attendees.map((attendee) => attendee.id),
      needContactInfo: calendar.needContactInfo,
      webMtgUrl: calendar.webMtgUrl,
    });
    return result.data;
  };

  return {
    mutate,
  };
};

export default defineComponent({
  name: "EditCalendar",
  components: {
    PageHeader,
    CalendarForm,
  },
  setup(_, { root }) {
    const state = reactive({
      loading: true,
      calendar: {
        name: null,
        duration: null,
        place: {},
        otherPlaceName: null,
        startTime: null,
        endTime: null,
        attendees: [],
      },
      placeOptions: [],
      attendees: [],
    });
    const onFetchCalendarResult = fetchCalendar(root.$route.params.id);
    onFetchCalendarResult((result) => {
      state.calendar = mapToCalendar(result.data.calendar);
      state.loading = false;
    });
    state.attendees = fetchAttendees();
    state.placeOptions = fetchPlaceOptions();
    const useSaveCalendarHandler = useSaveCalendar(root.$route.params.id);
    const submit = async () => {
      state.loading = true;
      try {
        const saveCalendarResult = await useSaveCalendarHandler.mutate(
          state.calendar
        );
        state.loading = false;
        const calendarId = saveCalendarResult.saveCalendar.id;
        setSessionMessage("カレンダーを保存しました");
        root.$router.push("/calendars/" + calendarId);
      } catch (error) {
        console.error(error);
        toastError();
      }
    };

    return {
      ...toRefs(state),
      submit,
    };
  },
});
</script>

<style lang="scss" scoped>
.calendar-form {
  padding-bottom: 64px;
}
</style>
