import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type ScheduleQueryVariables = Types.Exact<{
  accessToken?: Types.Maybe<Types.Scalars['String']>;
  id?: Types.Maybe<Types.Scalars['String']>;
}>;


export type ScheduleQuery = (
  { __typename?: 'Query' }
  & { schedule: (
    { __typename?: 'DetailSchedule' }
    & Pick<Types.DetailSchedule, 'id' | 'requesterName' | 'requesteeName' | 'placeName' | 'mtgDateText' | 'durationText' | 'status' | 'statusText' | 'reservationName' | 'isReservation' | 'reservedByNonLoginUser' | 'userType' | 'tel' | 'isReminderSendable' | 'webMtgUrl' | 'emailForNonLoginUser' | 'updatedAt'>
    & { attendees?: Types.Maybe<Array<(
      { __typename?: 'User' }
      & Pick<Types.User, 'id' | 'name'>
    )>> }
  ) }
);


export const ScheduleDocument = gql`
    query schedule($accessToken: String, $id: String) {
  schedule(accessToken: $accessToken, id: $id) {
    id
    requesterName
    requesteeName
    placeName
    mtgDateText
    durationText
    status
    statusText
    reservationName
    isReservation
    reservedByNonLoginUser
    attendees {
      id
      name
    }
    userType
    tel
    isReminderSendable
    webMtgUrl
    emailForNonLoginUser
    updatedAt
  }
}
    `;

/**
 * __useScheduleQuery__
 *
 * To run a query within a Vue component, call `useScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useScheduleQuery({
 *   accessToken: // value for 'accessToken'
 *   id: // value for 'id'
 * });
 */
export function useScheduleQuery(variables: ScheduleQueryVariables | VueCompositionApi.Ref<ScheduleQueryVariables> | ReactiveFunction<ScheduleQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ScheduleQuery, ScheduleQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ScheduleQuery, ScheduleQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ScheduleQuery, ScheduleQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ScheduleQuery, ScheduleQueryVariables>(ScheduleDocument, variables, options);
}
export type ScheduleQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ScheduleQuery, ScheduleQueryVariables>;