<template>
  <div class="calendar-sheet">
    <div class="weekindicator-wrapper">
      <b-loading :is-full-page="false" :active="isLoading" />
      <b-button
        @click="goBackToPrevWeek"
        class="indicator-button"
        :disabled="!showPrevWeekButton"
      >
        <DoubleLeftArrowIcon />
        <span class="is-size-6_5 has-text-weight-bold">前週</span>
      </b-button>
      <b-button
        @click="goToNextWeek"
        class="indicator-button"
        :disabled="!showNextWeekButton"
      >
        <span class="is-size-6_5 has-text-weight-bold">翌週</span>
        <DoubleRightArrowIcon />
      </b-button>
    </div>
    <div class="guide-wrapper">
      <div class="guide">
        <SelectableIcon />
        <span class="is-size-6_5">:選択可</span>
      </div>
      <div class="guide">
        <UnselectableIcon />
        <span class="is-size-6_5">:選択不可</span>
      </div>
    </div>
    <div class="calendar-wrapper">
      <v-calendar
        ref="calendarRef"
        v-model="start"
        :start="computedStartDate"
        :now="today"
        :value="today"
        color="primary"
        type="week"
        first-day-of-week="1"
        locale="ja"
        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        :firstInterval="firstInterval"
        :intervalCount="intervalCount"
        :intervalMinutes="30"
        :interval-width="40"
        :interval-height="40"
        :interval-format="intervalFormat"
      >
        <template v-slot:day-label-header="{ date }">
          <div class="daylabel-wrapper">
            <span>
              {{ new Date(date).getMonth() + 1 }}/{{ new Date(date).getDate() }}
            </span>
            <span> ({{ weekDayJp(date) }}) </span>
          </div>
        </template>
        <template v-slot:interval="{ time, date }">
          <template v-if="!isLoading">
            <div
              @click="onClickInterval(eventsMap[`${date}${time}`])"
              class="intervalicon-wrapper is-selectable"
              v-if="eventsMap[`${date}${time}`]"
            >
              <SelectableIcon />
            </div>
            <div class="intervalicon-wrapper" v-else><UnselectableIcon /></div>
          </template>
        </template>
      </v-calendar>
    </div>
    <b-modal
      v-model="showSelectTimeDialog"
      :can-cancel="true"
      :on-cancel="onCancelSelectTimeDialog"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      aria-modal
      @after-enter="afterEnter"
    >
      <div
        id="modal-container"
        class="modal-card modal-container"
        style="width: auto"
      >
        <header class="modal-card-head modal-header has-text-weight-bold">
          <span class="is-size-6">
            {{
              `${formatDateWithoutPadding(
                selectedDatetime.date,
                "M月d日"
              )}（${weekDayJp(selectedDatetime.date)}）`
            }}</span
          >
          <span class="is-size-6_5">希望の時間帯をお選び下さい</span>
        </header>

        <section id="options-wrapper" class="modal-card-body options-wrapper">
          <div
            class="option"
            :class="{ active: datetime.id == selectedDatetimeId }"
            :id="`radio-button-${datetime.id}`"
            v-for="datetime in datetimeOptions"
            :key="datetime.id"
          >
            <b-radio
              v-model="selectedDatetimeId"
              :native-value="datetime.id"
              type="is-info"
            >
              {{ optionText(datetime) }}
            </b-radio>
          </div>
        </section>

        <footer class="buttons-wrapper modal-card-foot">
          <button
            class="button is-link is-outlined select-button has-text-weight-bold"
            @click="selectDatetime"
          >
            選ぶ
          </button>
          <button
            class="button is-link is-outlined cancel-button has-text-weight-bold"
            @click="cancelModal"
          >
            キャンセル
          </button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
} from "@vue/composition-api";
import { scroller } from "vue-scrollto/src/scrollTo";
import {
  formatDate,
  formatDateWithoutPadding,
  weekDayJp,
} from "@/submodules/format";
import SelectableIcon from "@/assets/img/common/selectable.svg";
import UnselectableIcon from "@/assets/img/common/unselectable.svg";
import DoubleLeftArrowIcon from "@/assets/img/common/double_left_arrow.svg";
import DoubleRightArrowIcon from "@/assets/img/common/double_right_arrow.svg";

const scrollTo = scroller();
const isNumber = (value) => {
  return ((typeof value === 'number') && (isFinite(value)));
}

export default defineComponent({
  name: "CalendarSheet",
  components: {
    SelectableIcon,
    UnselectableIcon,
    DoubleLeftArrowIcon,
    DoubleRightArrowIcon,
  },
  props: {
    calendarData: {
      type: Object,
      required: false,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      vCalendarData: {},
      today: formatDate(new Date(), "yyyy-MM-dd"),
      weekIndex: 0,
      calendarRef: null,
      start: "",
      showSelectTimeDialog: false,
      selectedDatetime: {
        date: null,
        time: null,
      },
      selectedDatetimeId: null,
    });

    const eventsMap = computed(() => {
      const eventsMap = {};
      const events = props.calendarData?.events || [];
      events.forEach((event) => {
        eventsMap[`${event.date}${event.time}`] = event;
      });
      return eventsMap;
    });
    const intervalFormat = (interval) => {
      return interval.time.replace(/^0/, "");
    };
    const showPrevWeekButton = computed(() => {
      return state.weekIndex >= 1;
    });
    const showNextWeekButton = computed(() => {
      return state.weekIndex < 7;
    });
    const goBackToPrevWeek = () => {
      state.calendarRef.prev();
      state.weekIndex -= 1;
    };
    const goToNextWeek = () => {
      state.calendarRef.next();
      state.weekIndex += 1;
    };
    const firstInterval = computed(() => {
      const events = props.calendarData?.events || [];
      const eventDates = events.map((event) => new Date(`${event?.date} ${event?.time}`.replace(/-/g,"/")).getTime())
      const eventHours = eventDates.map((date) => (new Date(date)).getHours())
      const minHour = Math.min(...eventHours)

      return isNumber(minHour) ? minHour * 2 : 0;
    });
    const intervalCount = computed(() => {
      const events = props.calendarData?.events || [];
      const eventDates = events.map((event) => new Date(`${event?.date} ${event?.time}`.replace(/-/g,"/")).getTime());
      const eventHours = eventDates.map((date) => (new Date(date)).getHours());
      const minHour = Math.min(...eventHours)
      const maxHour = Math.max(...eventHours)
      const conut = (maxHour - minHour) * 2 + 3;

      return props.isLoading ? 0 : (isNumber(conut) ? conut : 0);
    });

    const onClickInterval = (event) => {
      state.selectedDatetime = {
        date: event.date,
        time: event.time,
      };
      state.selectedDatetimeId = event.id;
      state.showSelectTimeDialog = true;
    };
    const onCancelSelectTimeDialog = () => {
      state.showSelectTimeDialog = false;
    };
    const selectDatetime = () => {
      state.showSelectTimeDialog = false;
      emit("update-selected-datetime", state.selectedDatetime);
    };
    const cancelModal = () => {
      state.selectedDatetime = {};
      state.selectedDatetimeId = null;
      state.showSelectTimeDialog = false;
    };
    const datetimeOptions = computed(() => {
      const events = props.calendarData?.events || [];
      const filterdEvents = events.filter(
        (event) => event.date == state.selectedDatetime.date
      );
      return filterdEvents;
    });
    const buildOptionString = () => {
      return "";
    };
    const afterEnter = () => {
      const options = {
        container: "#options-wrapper",
        easing: "ease-in",
        lazy: false,
        offset: -10,
        force: true,
        cancelable: true,
      };
      scrollTo(`#radio-button-${state.selectedDatetimeId}`, 300, options);
    };
    const optionText = (datetime) => {
      const datetimeString = `${datetime.date} ${datetime.time}`.replace(
        /-/g,
        "/"
      );
      const startDate = new Date(datetimeString);
      const endDate = new Date(datetimeString);
      endDate.setMinutes(startDate.getMinutes() + datetime.duration);
      return `${
        formatDateWithoutPadding(startDate, "H:") + formatDate(startDate, "mm")
      } - ${
        formatDateWithoutPadding(endDate, "H:") + formatDate(endDate, "mm")
      }`;
    };
    const computedStartDate = computed(() => {
      const events = props.calendarData?.events || [];
      const eventDates = events.map((event) => new Date(event?.date).getTime())
      const minDate = eventDates.length ? Math.min(...eventDates) : new Date().getTime()
      return formatDate(new Date(minDate), "yyyy-MM-dd")
    })

    return {
      ...toRefs(state),
      eventsMap,
      intervalFormat,
      onClickInterval,
      showPrevWeekButton,
      showNextWeekButton,
      goBackToPrevWeek,
      goToNextWeek,
      firstInterval,
      intervalCount,
      onCancelSelectTimeDialog,
      weekDayJp,
      formatDateWithoutPadding,
      selectDatetime,
      cancelModal,
      datetimeOptions,
      buildOptionString,
      afterEnter,
      optionText,
      computedStartDate,
    };
  },
});
</script>

<style lang="scss" scoped>
.calendar-sheet {
  position: relative;
}
.guide-wrapper {
  padding: 4px 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .guide {
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    .svg {
      height: 16px;
      width: 16px;
      padding-right: 4px;
    }
  }
}
::v-deep .v-calendar {
  border-radius: 4px;
  .v-calendar-daily_head-weekday {
    display: none;
  }
  .v-calendar-daily__head {
    .v-calendar-daily_head-day {
      &:nth-child(7) {
        color: colors(blue);
      }
      &:nth-child(8) {
        color: colors(pink);
      }
    }
  }
  .v-calendar-daily_head-day-label {
    font-size: 14px;
    cursor: default;
  }
}
.intervalicon-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .svg {
    height: 16px;
    width: 16px;
  }
  &.is-selectable {
    cursor: pointer;
  }
}
.daylabel-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.weekindicator-wrapper {
  padding: 4px 0;
  display: flex;
  justify-content: space-between;
  .indicator-button {
    height: 28px;
    width: 25%;
    max-width: 96px;
    background-color: colors(blue);
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .svg {
      padding: 0 2px;
      height: 12px;
    }
  }
}
.modal-container {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  background-color: white;
  border-radius: 4px;
  .modal-header {
    height: 56px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid colors(light-gray);
  }
  .buttons-wrapper {
    height: 48px;
    padding: 0;
    border-radius: 4px;
    border-top: 1px solid colors(light-gray);
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    .button {
      height: 32px;
      width: calc(50% - 32px);
      max-width: 160px;
      padding: 0;
      margin: 0;
      font-size: 0.9rem;
      border: 1px solid colors(black);
      &.select-button {
        background-color: black;
        color: white;
      }
      &.cancel-button {
        background-color: white;
        color: colors(black);
      }
    }
    .button::selection {
      -webkit-appearance: none;
    }
  }
  .options-wrapper {
    .option {
      background-color: colors(light-gray);
      padding: 8px;
      margin: 8px 0;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.active {
        font-weight: bold;
        background-color: colors(blue);
        color: white;
        ::v-deep .radio:hover {
          color: white;
        }
      }
    }
    ::v-deep .check {
      background-color: white;
      top: -1px;
    }
  }
}
::v-deep .modal-card {
  max-height: calc(100vh - 160px);
  .modal-card-body {
    padding: 0 8px;
  }
}
::v-deep .modal .animation-content .modal-card {
  margin: 0 auto;
}
</style>
