import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type PlaceOptionsQueryVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
}>;


export type PlaceOptionsQuery = (
  { __typename?: 'Query' }
  & { placeOptions: Array<(
    { __typename?: 'Place' }
    & Pick<Types.Place, 'id' | 'name' | 'kind'>
  )> }
);


export const PlaceOptionsDocument = gql`
    query placeOptions($accessToken: String!) {
  placeOptions(accessToken: $accessToken) {
    id
    name
    kind
  }
}
    `;

/**
 * __usePlaceOptionsQuery__
 *
 * To run a query within a Vue component, call `usePlaceOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceOptionsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePlaceOptionsQuery({
 *   accessToken: // value for 'accessToken'
 * });
 */
export function usePlaceOptionsQuery(variables: PlaceOptionsQueryVariables | VueCompositionApi.Ref<PlaceOptionsQueryVariables> | ReactiveFunction<PlaceOptionsQueryVariables>, options: VueApolloComposable.UseQueryOptions<PlaceOptionsQuery, PlaceOptionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PlaceOptionsQuery, PlaceOptionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PlaceOptionsQuery, PlaceOptionsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PlaceOptionsQuery, PlaceOptionsQueryVariables>(PlaceOptionsDocument, variables, options);
}
export type PlaceOptionsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PlaceOptionsQuery, PlaceOptionsQueryVariables>;