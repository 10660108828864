<template>
  <div class="purchase-plan-modal">
    <div class="modal-card modal-container" style="width: auto">
      <header class="modal-card-head modal-header has-text-weight-bold">
        <span class="is-size-6_5">月額 990 円の有料プランを購入</span>
      </header>

      <section class="modal-card-body card-wrapper">
        <div>
          <span class="is-size-6_5 has-text-weight-bold"
            >クレジットカード番号</span
          >
        </div>
        <div id="stripe-card" class="stripe-card"></div>
        <div id="card-errors" class="card-errors is-size-7" role="alert"></div>
      </section>

      <footer class="buttons-wrapper modal-card-foot">
        <button
          class="button is-link is-outlined select-button has-text-weight-bold"
          @click="purchase"
        >
          プラン購入
        </button>
        <button
          class="button is-link is-outlined cancel-button has-text-weight-bold"
          @click="cancelModal"
        >
          キャンセル
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
} from "@vue/composition-api";
import { loadStripe } from "@stripe/stripe-js";

import config from "config";

const stripeStyle = {
  base: {
    color: "#32325d",
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: "antialiased",
    fontSize: "16px",
    "::placeholder": {
      color: "#aab7c4",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
  },
};

export default defineComponent({
  name: "PurchasePlanModal",
  components: {},
  setup(_, { emit }) {
    const state = reactive({
      loading: true,
    });
    onMounted(async () => {
      state.stripe = await loadStripe(config.stripePubKey);
      const elements = state.stripe.elements();
      state.cardElement = elements.create("card", { style: stripeStyle });
      state.cardElement.mount("#stripe-card");
      state.cardElement.on("cange", (event) => {
        var displayError = document.getElementById("card-errors");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      });
    });
    const cancelModal = () => {
      emit("close");
    };
    const purchase = async () => {
      let cardToken = "";
      await state.stripe.createToken(state.cardElement).then((result) => {
        cardToken = result.token.id;
      });
      emit("purchase", cardToken);
    };

    return {
      ...toRefs(state),
      cancelModal,
      purchase,
    };
  },
});
</script>

<style lang="scss" scoped>
// .purchase-plan-modal {
//   margin: 0 auto;
//   border-radius: 5px;
//   background-color: white;
// }
.modal-container {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  background-color: white;
  border-radius: 4px;
  .modal-header {
    height: 56px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid colors(light-gray);
  }
  .card-wrapper {
    height: 120px;
    padding: 16px 16px 24px;
    text-align: left;
    .stripe-card {
      padding: 8px 0 8px 8px;
      border: 1px solid colors(light-gray);
      border-radius: 5px;
    }
    .card-errors {
      color: red;
    }
  }
  .buttons-wrapper {
    height: 48px;
    padding: 0;
    border-radius: 4px;
    border-top: 1px solid colors(light-gray);
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    .button {
      height: 32px;
      width: calc(50% - 32px);
      max-width: 160px;
      padding: 0;
      margin: 0;
      font-size: 0.9rem;
      border: 1px solid colors(black);
      &.select-button {
        background-color: black;
        color: white;
      }
      &.cancel-button {
        background-color: white;
        color: colors(black);
      }
    }
    .button::selection {
      -webkit-appearance: none;
    }
  }
}
// ::v-deep .modal-card {
//   // max-height: calc(100vh - 160px);
//   .modal-card-body {
//     padding: 0 8px;
//   }
// }
</style>
