import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type SchedulesQueryVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
  status: Types.Scalars['String'];
  limit?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type SchedulesQuery = (
  { __typename?: 'Query' }
  & { schedules: Array<(
    { __typename?: 'Schedule' }
    & Pick<Types.Schedule, 'id' | 'status' | 'requesteeName' | 'summaryText' | 'mtgDateText' | 'date' | 'time' | 'placeName'>
  )> }
);


export const SchedulesDocument = gql`
    query schedules($accessToken: String!, $status: String!, $limit: Boolean) {
  schedules(accessToken: $accessToken, status: $status, limit: $limit) {
    id
    status
    requesteeName
    summaryText
    mtgDateText
    date
    time
    placeName
  }
}
    `;

/**
 * __useSchedulesQuery__
 *
 * To run a query within a Vue component, call `useSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchedulesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSchedulesQuery({
 *   accessToken: // value for 'accessToken'
 *   status: // value for 'status'
 *   limit: // value for 'limit'
 * });
 */
export function useSchedulesQuery(variables: SchedulesQueryVariables | VueCompositionApi.Ref<SchedulesQueryVariables> | ReactiveFunction<SchedulesQueryVariables>, options: VueApolloComposable.UseQueryOptions<SchedulesQuery, SchedulesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SchedulesQuery, SchedulesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SchedulesQuery, SchedulesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SchedulesQuery, SchedulesQueryVariables>(SchedulesDocument, variables, options);
}
export type SchedulesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SchedulesQuery, SchedulesQueryVariables>;