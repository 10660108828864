export default {
  api_key: 'api_key_production',
  graphqlUrl: window.location.protocol + '//' + window.location.host + '/graphql',
  plansUrl: (() => {
    console.log('window.location.origin', window.location.origin)
    if (window.location.origin == "https://stg-apotoru.com") {
      return "https://www.stg-apotoru.com/redirect/to_plans"
    } else if (window.location.origin == "https://apotoru.biz") {
      return "https://www.apotoru.biz/redirect/to_plans"
    } else {
      return ""
    }
  })(),
  bugReportUrl: 'https://forms.gle/R895qnVm6MMJQFMq8',
  stripePubKey: (() => {
    console.log('stripePubKey window.location.origin', window.location.origin)
    if (window.location.origin == "https://stg-apotoru.com") {
      return "pk_test_N1xJo4zNI3FItyh5m3ectv1f00fGkkimWM"
    } else if (window.location.origin == "https://apotoru.biz") {
      return "pk_live_6AfCkjkIBEgivP4YMGZXQgWI004B7SAs72"
    } else {
      return ""
    }
  })(),
}
