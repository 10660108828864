import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type CalendarDataQueryVariables = Types.Exact<{
  accessToken?: Types.Maybe<Types.Scalars['String']>;
  calendarId: Types.Scalars['ID'];
}>;


export type CalendarDataQuery = (
  { __typename?: 'Query' }
  & { calendarData: (
    { __typename?: 'AdjustingSchedule' }
    & Pick<Types.AdjustingSchedule, 'requesterName' | 'duration' | 'start' | 'today' | 'placeKind' | 'selectedPlaceId' | 'reservationName'>
    & { fixedPlace?: Types.Maybe<(
      { __typename?: 'Place' }
      & Pick<Types.Place, 'id' | 'name' | 'kind'>
    )>, places?: Types.Maybe<Array<(
      { __typename?: 'Place' }
      & Pick<Types.Place, 'id' | 'name' | 'kind'>
    )>>, events: Array<(
      { __typename?: 'Event' }
      & Pick<Types.Event, 'id' | 'title' | 'date' | 'time' | 'duration'>
    )> }
  ) }
);


export const CalendarDataDocument = gql`
    query calendarData($accessToken: String, $calendarId: ID!) {
  calendarData(accessToken: $accessToken, calendarId: $calendarId) {
    requesterName
    duration
    start
    today
    fixedPlace {
      id
      name
      kind
    }
    places {
      id
      name
      kind
    }
    placeKind
    selectedPlaceId
    events {
      id
      title
      date
      time
      duration
    }
    reservationName
  }
}
    `;

/**
 * __useCalendarDataQuery__
 *
 * To run a query within a Vue component, call `useCalendarDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarDataQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCalendarDataQuery({
 *   accessToken: // value for 'accessToken'
 *   calendarId: // value for 'calendarId'
 * });
 */
export function useCalendarDataQuery(variables: CalendarDataQueryVariables | VueCompositionApi.Ref<CalendarDataQueryVariables> | ReactiveFunction<CalendarDataQueryVariables>, options: VueApolloComposable.UseQueryOptions<CalendarDataQuery, CalendarDataQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CalendarDataQuery, CalendarDataQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CalendarDataQuery, CalendarDataQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CalendarDataQuery, CalendarDataQueryVariables>(CalendarDataDocument, variables, options);
}
export type CalendarDataQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CalendarDataQuery, CalendarDataQueryVariables>;