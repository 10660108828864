<template>
  <div class="google-calendars">
    <PageHeader
      title="アポ予約Googleカレンダー指定"
      :backIcon="true"
      to="/settings"
    />
    <div class="contents-wrapper">
      <div class="description-container">
        <div class="text-container">
          <b-notification rounded :closable="false">
            <p>選択中の Google カレンダーに</p>
            <p>アポ予約が登録されます。</p>
          </b-notification>
        </div>
      </div>
      <b-loading :is-full-page="false" :active="loading" />
      <div class="detail-contents">
        <div
          class="detail"
          v-for="googleCalendar in googleCalendars"
          :key="googleCalendar.id"
        >
          <div class="name">
            <p class="text is-size-6_5">
              {{ googleCalendar.name }}
            </p>
          </div>
          <div class="switch-section">
            <b-radio v-model="selectedCalendarId"
              size="is-small"
              :native-value="googleCalendar.id"
              @input="onSelectCalendar($event)"
            />
            <div class="text-container">
              <p v-if="isSelected(googleCalendar.id)" class="text is-size-6_5">
                選択中
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, reactive, toRefs } from "@vue/composition-api";

import { useAuth } from "@/submodules/auth";
import { useToast } from "@/submodules/toast";

import { useGoogleCalendarsQuery } from "./graphql/query/googleCalendars.generated";
import { useSaveGoogleCalendarMutation } from "./graphql/mutation/saveGoogleCalendar.generated.ts";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";

const { toastMessage, toastError } = useToast();
const { getToken } = useAuth();
const useGoogleCalendarMutation = () => {
  const { mutate: saveGoogleCalendarMutate } = useSaveGoogleCalendarMutation();
  const saveGoogleCalendar = (googleCalendarId) => {
    const result = saveGoogleCalendarMutate({
      accessToken: getToken(),
      id: googleCalendarId,
      isEventRegisterable: true,
    });

    return result;
  };

  return saveGoogleCalendar;
};

export default defineComponent({
  name: "GoogleCalendars",
  components: {
    PageHeader,
    Footer,
  },
  setup() {
    const state = reactive({
      loading: true,
      googleCalendars: [],
      selectedCalendarId: '',
    });

    const { onResult, loading } = useGoogleCalendarsQuery({
      accessToken: getToken(),
    });
    state.loading = loading;
    onResult((result) => {
      state.googleCalendars = result.data.googleCalendars;
      state.selectedCalendarId = result.data.googleCalendars?.find((cal) => cal.isEventRegisterable)?.id
    });

    const saveGoogleCalendar = useGoogleCalendarMutation();

    const onSelectCalendar = async (googleCalendarId) => {
      try {
        await saveGoogleCalendar(googleCalendarId);
        state.selectedCalendarId = googleCalendarId
        toastMessage("アポ予約を入れるGoogleカレンダーを変更しました");
      } catch (error) {
        console.error(error);
        toastError();
      }
    };
    const isSelected = (googleCalendarId) => {
      return googleCalendarId === state.selectedCalendarId
    }

    return {
      ...toRefs(state),
      onSelectCalendar,
      isSelected,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
  > .description-container {
    padding-bottom: 16px;
    > .text-container {
      font-size: 14px;
      > .notification {
        padding: 8px;
      }
    }
  }
}
.detail-contents {
  > .detail {
    width: 100%;
    height: 40px;
    text-align: left;
    padding: 8px 0;
    border-top: 1px solid colors(light-gray);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
    }
    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.switch-section {
  display: flex;
  > .text-container {
    min-width: 44px;
  }
}
::v-deep {
  .switch {
    margin: 0;
  }
}
</style>
