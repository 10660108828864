<template>
  <div class="edit-eventintervals">
    <PageHeader
      title="前後の空き時間の設定編集"
      :backIcon="true"
      to="/interval_settings"
    />
    <div class="contents-wrapper">
      <b-loading :is-full-page="false" :active="loading" />
      <div class="detail-contents">
        <b-field class="detail" label="アポ予約前の空き時間">
          <b-select
            v-model="intervalBeforeEvent"
            class="form-item"
            placeholder="空き時間を選択して下さい"
          >
            <option
              v-for="duration in addedDurations"
              :value="duration.val"
              :key="duration.val"
            >
              {{ duration.text }}
            </option>
          </b-select>
        </b-field>

        <b-field class="detail" label="アポ予約後の空き時間">
          <b-select
            v-model="intervalAfterEvent"
            class="form-item"
            placeholder="空き時間を選択して下さい"
          >
            <option
              v-for="duration in addedDurations"
              :value="duration.val"
              :key="duration.val"
            >
              {{ duration.text }}
            </option>
          </b-select>
        </b-field>
      </div>

      <b-button class="save-button" expanded @click="onClickSaveButton">
        <p class="text is-size-6_5 has-text-weight-bold">
          前後の空き時間の設定を保存する
        </p>
      </b-button>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import {
  defineComponent,
  reactive,
  ref,
  toRefs,
  computed,
} from "@vue/composition-api";

import { useAuth } from "@/submodules/auth";
import { useToast } from "@/submodules/toast";
import { useEventIntervalsQuery } from "./graphql/query/eventIntervals.generated";
import { useSaveEventIntervalsMutation } from "./graphql/mutation/saveEventIntervals.generated";
import { durations } from "@/submodules/constants";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";

const { getToken } = useAuth();
const { toastError, setSessionMessage } = useToast();
const fetchIntervals = () => {
  const intervalSettings = ref({});
  const { onResult, loading } = useEventIntervalsQuery({
    accessToken: getToken(),
  });
  onResult((result) => {
    console.log("result", result);
    intervalSettings.value = result.data.adjustableTimePeriods;
  });

  return {
    intervalSettings,
    loading,
  };
};
const useEventIntervalsMutation = () => {
  const { mutate: saveEventIntervalsMutate } = useSaveEventIntervalsMutation();
  const saveEventIntervals = (intervalSettings) => {
    const result = saveEventIntervalsMutate({
      accessToken: getToken(),
      intervalBeforeEvent: intervalSettings.intervalBeforeEvent,
      intervalAfterEvent: intervalSettings.intervalAfterEvent,
    });

    return result;
  };

  return saveEventIntervals;
};
const addedDurations = [...durations];
addedDurations.unshift({ text: "なし", val: 0 });

export default defineComponent({
  name: "EditIntervalSettings",
  components: {
    PageHeader,
    Footer,
  },
  setup(_, { root }) {
    const state = reactive({
      loading: true,
      intervalSettings: {},
    });

    const { intervalSettings, loading } = fetchIntervals();
    state.intervalSettings = intervalSettings;
    state.loading = loading;

    const intervalBeforeEvent = computed({
      get: () => {
        if (
          !state.intervalSettings.intervalBeforeEvent ||
          state.intervalSettings.intervalBeforeEvent == "00"
        )
          return 0;

        return state.intervalSettings.intervalBeforeEvent;
      },
      set: (interval) => {
        console.log("setter interval", interval);

        state.intervalSettings.intervalBeforeEvent = String(interval);
      },
    });
    const intervalAfterEvent = computed({
      get: () => {
        if (
          !state.intervalSettings.intervalAfterEvent ||
          state.intervalSettings.intervalAfterEvent == "00"
        )
          return 0;

        return state.intervalSettings.intervalAfterEvent;
      },
      set: (interval) => {
        console.log("setter interval", interval);
        state.intervalSettings.intervalAfterEvent = String(interval);
      },
    });
    const saveEventIntervals = useEventIntervalsMutation();
    const onClickSaveButton = async () => {
      try {
        await saveEventIntervals(state.intervalSettings);
        setSessionMessage("前後の空き時間の設定を保存しました");
        root.$router.push("/interval_settings");
      } catch (error) {
        console.error(error);
        toastError();
      }
    };

    return {
      ...toRefs(state),
      intervalBeforeEvent,
      intervalAfterEvent,
      addedDurations,
      onClickSaveButton,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
}
.detail-contents {
  > .detail {
    width: 100%;
    text-align: left;
    padding: 8px 0;
    display: block;
    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .button {
  margin-top: 16px;
  color: white;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      color: white;
    }
  }
}
.save-button {
  margin-top: 32px;
  background-color: colors(green);
  color: white;
}
</style>
