<template>
  <div class="calendar-row">
    <div class="icon-area">
      <CircleIcon kind="reservation" />
    </div>
    <div class="description-area">
      <div class="summary">
        <p class="text is-size-6 has-text-weight-bold">{{ summaryText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, computed } from "@vue/composition-api";

import CircleIcon from "@/components/shared/CircleIcon.vue";

export default defineComponent({
  name: "CalendarRow",
  components: {
    CircleIcon,
  },
  props: {
    calendarName: {
      type: String,
      default: () => "",
    },
  },
  setup(props) {
    const summaryText = computed(() => props.calendarName || "");

    return {
      summaryText,
    };
  },
});
</script>

<style lang="scss" scoped>
.calendar-row {
  height: 48px;
  width: 100%;
  display: flex;

  .icon-area {
    display: flex;
    justify-content: center;
    align-items: center;
    > .circle-icon {
      display: flex;
    }
  }
  .description-area {
    width: calc(100% - 32px);
    padding-left: 8px;
    > .summary {
      width: 100%;
      height: 48px;
      display: flex;
      flex-direction: row;
      align-items: center;
      > .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
