import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type SaveGoogleCalendarMutationVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
  id: Types.Scalars['String'];
  available?: Types.Maybe<Types.Scalars['Boolean']>;
  isEventRegisterable?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type SaveGoogleCalendarMutation = (
  { __typename?: 'Mutation' }
  & { saveGoogleCalendar: (
    { __typename?: 'GoogleCalendar' }
    & Pick<Types.GoogleCalendar, 'id' | 'name' | 'available' | 'isEventRegisterable'>
  ) }
);


export const SaveGoogleCalendarDocument = gql`
    mutation saveGoogleCalendar($accessToken: String!, $id: String!, $available: Boolean, $isEventRegisterable: Boolean) {
  saveGoogleCalendar(
    accessToken: $accessToken
    id: $id
    available: $available
    isEventRegisterable: $isEventRegisterable
  ) {
    id
    name
    available
    isEventRegisterable
  }
}
    `;

/**
 * __useSaveGoogleCalendarMutation__
 *
 * To run a mutation, you first call `useSaveGoogleCalendarMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSaveGoogleCalendarMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSaveGoogleCalendarMutation({
 *   variables: {
 *     accessToken: // value for 'accessToken'
 *     id: // value for 'id'
 *     available: // value for 'available'
 *     isEventRegisterable: // value for 'isEventRegisterable'
 *   },
 * });
 */
export function useSaveGoogleCalendarMutation(options: VueApolloComposable.UseMutationOptions<SaveGoogleCalendarMutation, SaveGoogleCalendarMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SaveGoogleCalendarMutation, SaveGoogleCalendarMutationVariables>>) {
  return VueApolloComposable.useMutation<SaveGoogleCalendarMutation, SaveGoogleCalendarMutationVariables>(SaveGoogleCalendarDocument, options);
}
export type SaveGoogleCalendarMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SaveGoogleCalendarMutation, SaveGoogleCalendarMutationVariables>;