<template>
  <header class="page-header">
    <div class="wrapper">
      <div class="item back-icon">
        <template v-if="backIcon">
          <router-link v-if="to" :to="to"><LeftArrowIcon /></router-link>
          <a v-else @click="historyBack"><LeftArrowIcon /></a>
        </template>
      </div>
      <div class="item title-text">
        <span>{{ title }}</span>
      </div>
      <div class="item" />
    </div>
  </header>
</template>

<script>
// @ is an alias to /src
import { defineComponent } from "@vue/composition-api";
import LeftArrowIcon from "@/assets/img/common/left_arrow.svg";

export default defineComponent({
  name: "Header",
  components: {
    LeftArrowIcon,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: () => "",
    },
    to: {
      type: String,
      required: false,
      default: () => "",
    },
    backIcon: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  setup(_, { root }) {
    const historyBack = () => {
      root.$router.go(-1);
    };

    return {
      historyBack,
    };
  },
});
</script>

<style lang="scss" scoped>
.page-header {
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > .wrapper {
    display: flex;
    flex-direction: row;
    > .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    > .back-icon {
      svg {
        display: block;
      }
    }
    > .title-text {
      white-space: nowrap;
    }
  }
}
</style>
