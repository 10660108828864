import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type AdjustableTimePeriodsQueryVariables = Types.Exact<{
  accessToken?: Types.Maybe<Types.Scalars['String']>;
}>;


export type AdjustableTimePeriodsQuery = (
  { __typename?: 'Query' }
  & { adjustableTimePeriods: (
    { __typename?: 'AdjustableTimePeriods' }
    & Pick<Types.AdjustableTimePeriods, 'periodType'>
    & { dailyTimePeriods?: Types.Maybe<Array<(
      { __typename?: 'Period' }
      & Pick<Types.Period, 'id' | 'wday' | 'startHour' | 'startMinute' | 'endHour' | 'endMinute'>
    )>>, weeklyTimePeriods?: Types.Maybe<Array<(
      { __typename?: 'Period' }
      & Pick<Types.Period, 'id' | 'wday' | 'startHour' | 'startMinute' | 'endHour' | 'endMinute'>
    )>> }
  ) }
);


export const AdjustableTimePeriodsDocument = gql`
    query adjustableTimePeriods($accessToken: String) {
  adjustableTimePeriods(accessToken: $accessToken) {
    periodType
    dailyTimePeriods {
      id
      wday
      startHour
      startMinute
      endHour
      endMinute
    }
    weeklyTimePeriods {
      id
      wday
      startHour
      startMinute
      endHour
      endMinute
    }
  }
}
    `;

/**
 * __useAdjustableTimePeriodsQuery__
 *
 * To run a query within a Vue component, call `useAdjustableTimePeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdjustableTimePeriodsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAdjustableTimePeriodsQuery({
 *   accessToken: // value for 'accessToken'
 * });
 */
export function useAdjustableTimePeriodsQuery(variables: AdjustableTimePeriodsQueryVariables | VueCompositionApi.Ref<AdjustableTimePeriodsQueryVariables> | ReactiveFunction<AdjustableTimePeriodsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<AdjustableTimePeriodsQuery, AdjustableTimePeriodsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AdjustableTimePeriodsQuery, AdjustableTimePeriodsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AdjustableTimePeriodsQuery, AdjustableTimePeriodsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<AdjustableTimePeriodsQuery, AdjustableTimePeriodsQueryVariables>(AdjustableTimePeriodsDocument, variables, options);
}
export type AdjustableTimePeriodsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<AdjustableTimePeriodsQuery, AdjustableTimePeriodsQueryVariables>;