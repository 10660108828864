<template>
  <div class="profile">
    <PageHeader title="プロフィール" :backIcon="true" to="/settings" />
    <div class="contents-wrapper">
      <b-loading :is-full-page="false" :active="loading" />
      <div class="detail-contents">
        <div class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">
              所属組織名（会社・団体名）
            </p>
          </div>
          <div class="value">
            <p class="is-size-6">{{ profile.organization }}</p>
          </div>
        </div>
        <div class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">氏名</p>
          </div>
          <div class="value">
            <p class="is-size-6">{{ profile.name }}</p>
          </div>
        </div>
      </div>
      <router-link to="/profile/edit">
        <b-button class="edit-button" expanded>
          <p class="text is-size-6_5 has-text-weight-bold">編集する</p>
        </b-button>
      </router-link>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, reactive, toRefs, ref } from "@vue/composition-api";
import { useAuth } from "@/submodules/auth";

import { useProfileQuery } from "./graphql/query/profile.generated";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";

const { getToken } = useAuth();
const fetchProfile = () => {
  const profile = ref({ name: "", organization: "" });
  const { onResult, loading } = useProfileQuery({ accessToken: getToken() });
  onResult((result) => {
    profile.value = result.data.profile;
  });

  return {
    profile,
    loading,
  };
};

export default defineComponent({
  name: "Profile",
  components: {
    PageHeader,
    Footer,
  },
  setup() {
    const state = reactive({
      loading: true,
      profile: {},
    });

    const { profile, loading } = fetchProfile();
    state.profile = profile;
    state.loading = loading;

    return {
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.contents-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
}
.detail-contents {
  > .detail {
    width: 100%;
    text-align: left;
    padding-top: 16px;
    .name {
      height: 20px;
      white-space: nowrap;
    }

    .value {
      height: 20px;
    }
  }
}
::v-deep .button {
  margin-top: 16px;
  color: white;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      color: white;
    }
  }
}
.edit-button {
  margin-top: 40px;
  background-color: colors(green);
}
</style>
