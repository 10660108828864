export const formatToPaddedTime = (hour, minute=0) => {
  if (!hour || !minute) "";
  const paddedHour = ("00" + String(hour)).slice(-2);
  const paddedMinute = ("00" + String(minute)).slice(-2);

  return `${paddedHour}:${paddedMinute}`;
}

export const formatDate = (date, format) => {
  if (!date) return ''
  const inputDate = (typeof date == 'string') ? new Date(date) : date
  format = format.replace(/yyyy/g, inputDate.getFullYear());
  format = format.replace(/MM/g, ('0' + (inputDate.getMonth() + 1)).slice(-2));
  format = format.replace(/dd/g, ('0' + inputDate.getDate()).slice(-2));
  format = format.replace(/HH/g, ('0' + inputDate.getHours()).slice(-2));
  format = format.replace(/mm/g, ('0' + inputDate.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ('0' + inputDate.getSeconds()).slice(-2));
  format = format.replace(/SSS/g, ('00' + inputDate.getMilliseconds()).slice(-3));
  return format;
}

export const formatDateWithoutPadding = (date, format) => {
  if (!date) return ''
  const inputDate = (typeof date == 'string') ? new Date(date) : date
  format = format.replace(/yyyy/g, inputDate.getFullYear());
  format = format.replace(/M/g, (inputDate.getMonth() + 1));
  format = format.replace(/d/g, (inputDate.getDate()));
  format = format.replace(/H/g, (inputDate.getHours()));
  format = format.replace(/mm/g, ('0' + inputDate.getMinutes()).slice(-2));
  format = format.replace(/s/g, (inputDate.getSeconds()));
  format = format.replace(/S/g, (inputDate.getMilliseconds()));
  return format;
};

export const weekDayJp = (date) => {
  if (!date) return ''
  const inputDate = (typeof date == 'string') ? new Date(date) : date
  return ["日", "月", "火", "水", "木", "金", "土"][
    new Date(inputDate).getDay()
  ]
}