import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type PlaceQueryVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
  placeId?: Types.Maybe<Types.Scalars['String']>;
}>;


export type PlaceQuery = (
  { __typename?: 'Query' }
  & { place: (
    { __typename?: 'Place' }
    & Pick<Types.Place, 'id' | 'name'>
  ) }
);


export const PlaceDocument = gql`
    query place($accessToken: String!, $placeId: String) {
  place(accessToken: $accessToken, placeId: $placeId) {
    id
    name
  }
}
    `;

/**
 * __usePlaceQuery__
 *
 * To run a query within a Vue component, call `usePlaceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePlaceQuery({
 *   accessToken: // value for 'accessToken'
 *   placeId: // value for 'placeId'
 * });
 */
export function usePlaceQuery(variables: PlaceQueryVariables | VueCompositionApi.Ref<PlaceQueryVariables> | ReactiveFunction<PlaceQueryVariables>, options: VueApolloComposable.UseQueryOptions<PlaceQuery, PlaceQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PlaceQuery, PlaceQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PlaceQuery, PlaceQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PlaceQuery, PlaceQueryVariables>(PlaceDocument, variables, options);
}
export type PlaceQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PlaceQuery, PlaceQueryVariables>;