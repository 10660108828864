import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type SavePlaceMutationVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
  placeId?: Types.Maybe<Types.Scalars['String']>;
  name: Types.Scalars['String'];
}>;


export type SavePlaceMutation = (
  { __typename?: 'Mutation' }
  & { savePlace: (
    { __typename?: 'Place' }
    & Pick<Types.Place, 'id' | 'name'>
  ) }
);


export const SavePlaceDocument = gql`
    mutation savePlace($accessToken: String!, $placeId: String, $name: String!) {
  savePlace(accessToken: $accessToken, placeId: $placeId, name: $name) {
    id
    name
  }
}
    `;

/**
 * __useSavePlaceMutation__
 *
 * To run a mutation, you first call `useSavePlaceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSavePlaceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSavePlaceMutation({
 *   variables: {
 *     accessToken: // value for 'accessToken'
 *     placeId: // value for 'placeId'
 *     name: // value for 'name'
 *   },
 * });
 */
export function useSavePlaceMutation(options: VueApolloComposable.UseMutationOptions<SavePlaceMutation, SavePlaceMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SavePlaceMutation, SavePlaceMutationVariables>>) {
  return VueApolloComposable.useMutation<SavePlaceMutation, SavePlaceMutationVariables>(SavePlaceDocument, options);
}
export type SavePlaceMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SavePlaceMutation, SavePlaceMutationVariables>;