









import { computed, defineComponent, onMounted, onUpdated } from "@vue/composition-api";
import { useToast } from "@/submodules/toast";

import { useTutorialStore } from '@/stores/tutorial'

const showIfSessionMessageExist = () => {
  if (sessionStorage.message) {
    const { toastSessionMessage } = useToast();
    toastSessionMessage();
  }
};

export default defineComponent({
  name: "App",
  components: {},
  setup(_, { root }) {
    const login = () => {
      const loginOrigin = process.env.NODE_ENV === 'production' ? window.location.protocol + '//www.' + window.location.host : window.location.origin
      let url = loginOrigin + "/google_auth/redirect?client_type=pwa&base_url=" + window.location.origin
      if (root.$route.query.agency_id) {
        url += "&agency_id=" + root.$route.query.agency_id;
      }
      location.href = url;
    };

    const tutorial = useTutorialStore()
    onMounted( async () => {
      showIfSessionMessageExist()
      await tutorial.fetch()
      console.log('App.vue setup CALLED!')
      console.log('tutorial.current', tutorial.current)
    })
    onUpdated(() => showIfSessionMessageExist());
    const isTutorialActive = computed(() => {
      const isActive = ['BEFORE_START_TUTORIAL', 'CREATE_CALENDAR', 'INITIAL_SETTING'].includes(tutorial.current)
      if (isActive) {
        root.$router.push('/tutorial')
      }
      return isActive
    })

    return {
      login,
      isTutorialActive,
    };
  },
});
