<template>
  <div class="places">
    <PageHeader title="場所編集" :backIcon="true" />
    <div class="contents-wrapper">
      <b-loading :is-full-page="false" :active="loading" />
      <div class="detail-contents">
        <div class="detail">
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
            name="場所名"
          >
            <b-field :type="{ 'is-danger': errors[0] }" :message="errors[0]">
              <template #label>
                <span class="label-text">場所名</span>
                <b-tag type="is-danger">必須</b-tag>
              </template>
              <b-input
                class="form-item"
                v-model="place.name"
                type="textarea"
                placeholder=""
              ></b-input>
            </b-field>
          </ValidationProvider>
        </div>
      </div>
      <b-button class="save-button" expanded @click="onClickSaveButton">
        <p class="text is-size-6_5 has-text-weight-bold">保存する</p>
      </b-button>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, reactive, ref, toRefs } from "@vue/composition-api";

import { useAuth } from "@/submodules/auth";
import { usePlaceQuery } from "./graphql/query/place.generated";
import { useSavePlaceMutation } from "./graphql/mutation/savePlace.generated";
import { useToast } from "@/submodules/toast";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";

const { getToken } = useAuth();
const { toastError, setSessionMessage } = useToast();
const fetchPlace = (placeId) => {
  const place = ref({});
  const { onResult, loading } = usePlaceQuery({
    accessToken: getToken(),
    placeId,
  });
  onResult((result) => {
    place.value = result.data.place;
  });

  return {
    place,
    loading,
  };
};
const usePlaceMutation = () => {
  const { mutate: savePlaceMutate } = useSavePlaceMutation();
  const savePlace = (placeId, name) => {
    const result = savePlaceMutate({
      accessToken: getToken(),
      placeId,
      name,
    });

    return result;
  };

  return savePlace;
};

export default defineComponent({
  name: "Place",
  components: {
    PageHeader,
    Footer,
  },
  setup(_, { root }) {
    const state = reactive({
      loading: true,
      place: {},
    });

    const { place, loading } = fetchPlace(root.$route.params.id);
    state.place = place;
    state.loading = loading;

    const savePlace = usePlaceMutation();

    const onClickSaveButton = async () => {
      try {
        await savePlace(state.place.id, state.place.name);
        setSessionMessage("場所を保存しました");
        root.$router.push("/places");
      } catch (error) {
        console.error(error);
        toastError();
      }
    };

    return {
      ...toRefs(state),
      onClickSaveButton,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
}
.detail-contents {
  > .detail {
    width: 100%;
    text-align: left;
    padding-top: 16px;
    .name {
      height: 20px;
      white-space: nowrap;
    }
    .value {
    }
  }
}
::v-deep .button {
  margin-top: 16px;
  color: white;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      color: white;
    }
  }
}
.save-button {
  margin-top: 40px;
  background-color: colors(green);
}
::v-deep .tag {
  height: 16px;
  margin-left: 4px;
  color: white;
  &.attendee-tag {
    color: colors(black);
  }
  span {
    font-weight: 700;
  }
}
</style>
