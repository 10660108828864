import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type TutorialStatusQueryVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
}>;


export type TutorialStatusQuery = (
  { __typename?: 'Query' }
  & { tutorialStatus?: Types.Maybe<(
    { __typename?: 'TutorialStatus' }
    & Pick<Types.TutorialStatus, 'id' | 'currentTutorial'>
  )> }
);


export const TutorialStatusDocument = gql`
    query tutorialStatus($accessToken: String!) {
  tutorialStatus(accessToken: $accessToken) {
    id
    currentTutorial
  }
}
    `;

/**
 * __useTutorialStatusQuery__
 *
 * To run a query within a Vue component, call `useTutorialStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useTutorialStatusQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTutorialStatusQuery({
 *   accessToken: // value for 'accessToken'
 * });
 */
export function useTutorialStatusQuery(variables: TutorialStatusQueryVariables | VueCompositionApi.Ref<TutorialStatusQueryVariables> | ReactiveFunction<TutorialStatusQueryVariables>, options: VueApolloComposable.UseQueryOptions<TutorialStatusQuery, TutorialStatusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TutorialStatusQuery, TutorialStatusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TutorialStatusQuery, TutorialStatusQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TutorialStatusQuery, TutorialStatusQueryVariables>(TutorialStatusDocument, variables, options);
}
export type TutorialStatusQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TutorialStatusQuery, TutorialStatusQueryVariables>;