<template>
  <div class="settings">
    <PageHeader title="その他" />
    <div class="settings-wrapper">
      <b-menu v-for="setting in settings" :key="setting.id">
        <b-menu-list>
          <b-menu-item>
            <template
              v-if="isPath(setting.url) || isExternalLink(setting.url)"
              #label
            >
              <router-link
                class="setting"
                v-if="isPath(setting.url)"
                :to="setting.url"
              >
                <b-icon :icon="setting.icon" />
                <span class="text is-size-7 has-text-weight-bold">{{
                  setting.title
                }}</span>
              </router-link>
              <a
                v-else-if="isExternalLink(setting.url)"
                :href="setting.url"
                class="setting"
                target="_blank"
              >
                <b-icon :icon="setting.icon" />
                <span class="text is-size-7 has-text-weight-bold">{{
                  setting.title
                }}</span>
              </a>
            </template>
            <template v-else #label="props">
              <div class="setting">
                <b-icon :icon="setting.icon" />
                <span class="text is-size-7 has-text-weight-bold">{{
                  setting.title
                }}</span>
                <b-icon
                  class="is-pulled-right"
                  :icon="props.expanded ? 'menu-down' : 'menu-up'"
                ></b-icon>
              </div>
            </template>
            <b-menu-item
              class="setting child-setting"
              v-for="childSetting in setting.childSettings"
              :key="childSetting.id"
            >
              <template #label>
                <router-link class="setting" :to="childSetting.url">
                  <b-icon :icon="childSetting.icon" />
                  <span class="text is-size-7 has-text-weight-bold">{{
                    childSetting.title
                  }}</span>
                </router-link>
              </template>
            </b-menu-item>
          </b-menu-item>
        </b-menu-list>
      </b-menu>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, reactive, toRefs } from "@vue/composition-api";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";
import config from "config";

const settings = [
  {
    id: 1,
    icon: "account-circle",
    title: "プロフィール設定",
    url: "/profile",
  },
  {
    id: 2,
    icon: "map-marker",
    title: "場所設定",
    url: "/places",
  },
  {
    id: 3,
    icon: "calendar-clock",
    title: "アポ予約受付設定",
    // url: "/detail_settings",
    childSettings: [
      {
        id: 301,
        icon: "cancel",
        title: "土日・祝日の受付設定",
        url: "/holiday_setting",
      },
      {
        id: 302,
        icon: "clock-time-four-outline",
        title: "受付可能な時間帯の設定",
        url: "/adjustable_time_periods",
      },
      {
        id: 303,
        icon: "food-fork-drink",
        title: "昼休みの設定",
        url: "/lunch_time_setting",
      },
      {
        id: 304,
        icon: "arrow-expand-horizontal",
        title: "アポ予約前後の空き時間の設定",
        url: "/interval_settings",
      },
    ],
  },
  {
    id: 4,
    icon: "calendar-import",
    title: "アポ予約を入れるGoogleカレンダーの指定",
    url: "/event_registerable_caendar",
  },
  {
    id: 5,
    icon: "calendar-multiple-check",
    title: "連携Googleカレンダー設定",
    url: "/google_calendars",
  },
  {
    id: 6,
    icon: "cart",
    title: "プラン変更",
    url: "/purchase_plan",
    // url: config.plansUrl + "?access_token=" + getToken(),
  },
  {
    id: 7,
    icon: "text-box-outline",
    title: "利用規約",
    url: "/rules",
  },
  {
    id: 8,
    icon: "security",
    title: "プライバシーポリシー",
    url: "/privacy_policy",
  },
  {
    id: 9,
    icon: "message-alert-outline",
    title: "不具合・バグレポート",
    url: config.bugReportUrl,
  },
  {
    id: 10,
    icon: "help-circle-outline",
    title: "よくあるご質問",
    url: "/faq",
  },
  // {
  //   id: 10,
  //   icon: "information-outline",
  //   title: "その他操作",
  //   childSettings: [
  //     {
  //       id: 301,
  //       icon: "map-marker",
  //       title: "土日・祝日の設定",
  //       url: "/places",
  //     },
  //   ],
  // },
  {
    id: 12,
    icon: "logout-variant",
    title: "ログアウト",
    url: "/logout",
  },
];

export default defineComponent({
  name: "Settings",
  components: {
    PageHeader,
    Footer,
  },
  setup() {
    const state = reactive({});
    const isPath = (url) => {
      if (!url) {
        return false;
      }
      return !url.includes("http");
    };
    const isExternalLink = (url) => {
      if (!url) {
        return false;
      }
      return url.includes("http");
    };

    return {
      ...toRefs(state),
      settings,
      isPath,
      isExternalLink,
    };
  },
});
</script>

<style lang="scss" scoped>
.settings-wrapper {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .setting {
    height: 40px;
    border-top: 1px solid colors(light-gray);
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    // line-height: 36px;
    .text {
      padding-left: 8px;
    }
    .v-icon {
      font-size: 28px;
    }
  }
}
.child-setting {
  a {
    width: 100%;
  }
}
::v-deep .menu-list {
  a {
    padding: 0;
    &.setting {
      height: 40px;

      border-top: 1px solid colors(light-gray);
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      // line-height: 36px;
      .text {
        padding-left: 8px;
      }
      .v-icon {
        font-size: 28px;
      }
    }
    &.is-active {
      background-color: white;
      color: colors(black);
    }
  }

  .child-setting {
    > a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
</style>
