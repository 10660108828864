<template>
  <div class="purchase-plan">
    <PageHeader title="プラン変更" :backIcon="true" to="/settings" />
    <div class="contents-wrapper">
      <b-loading :is-full-page="false" :active="loading" />
      <div class="detail-contents">
        <div class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">現在の料金プラン</p>
          </div>
          <div class="value">
            <p class="is-size-6">{{ planName }}</p>
          </div>
        </div>
      </div>
      <b-button
        v-if="!planPersisted"
        class="purchase-button"
        expanded
        @click="showParchasePlanModal = !showParchasePlanModal"
      >
        <CartIcon />
        <p class="text is-size-6_5 has-text-weight-bold">
          月額 990 円の有料プランを購入
        </p>
      </b-button>
      <b-button v-else class="remove-button" expanded @click="cancelPlan">
        <TrashIcon />
        <p class="text is-size-6_5 has-text-weight-bold">
          有料プランを解約する
        </p>
      </b-button>
    </div>
    <Footer />
    <b-modal
      v-model="showParchasePlanModal"
      :on-cancel="cancelModal"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-label="PurchasePlanModal"
      aria-modal
      :can-cancel="['escape', 'outside']"
    >
      <template #default="props">
        <PurchasePlanModal @purchase="purchase" @close="props.close" />
      </template>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  defineComponent,
  reactive,
  ref,
  toRefs,
  computed,
} from "@vue/composition-api";

import { useAuth } from "@/submodules/auth";
import { useCurrentPlanQuery } from "./graphql/query/currentPlan.generated";
import { usePurchasePlanMutation } from "./graphql/mutation/purchasePlan.generated";
import { useToast } from "@/submodules/toast";
import { useCancelPlanMutation } from "./graphql/mutation/cancelPlan.generated";
import { showConfirmDialog } from "@/submodules/dialog";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";
import PurchasePlanModal from "@/components/purchasePlan/PurchasePlanModal.vue";

import CartIcon from "@/assets/img/common/outline_icons/cart.svg";
import TrashIcon from "@/assets/img/common/outline_icons/trash.svg";

const { getToken } = useAuth();
const { toastError, toastMessage } = useToast();
const fetchCurrentPlan = () => {
  const currentPlan = ref({});
  const { onResult, loading, refetch } = useCurrentPlanQuery({
    accessToken: getToken(),
  });
  onResult((result) => {
    currentPlan.value = result?.data?.currentPlan;
  });

  return {
    currentPlan,
    loading,
    refetch,
  };
};

export default defineComponent({
  name: "PurchasePlan",
  components: {
    PageHeader,
    Footer,
    CartIcon,
    TrashIcon,
    PurchasePlanModal,
  },
  setup() {
    const state = reactive({
      loading: true,
      currentPlan: {},
      showParchasePlanModal: false,
      stripe: null,
      cardElement: null,
    });

    const {
      currentPlan,
      loading,
      refetch: refetchCurrentPlan,
    } = fetchCurrentPlan();
    state.currentPlan = currentPlan;
    state.loading = loading;

    const planName = computed(() => {
      return state.currentPlan?.persisted
        ? "有料プラン（月額 990 円）"
        : "無料プラン（月額 0 円）";
    });
    const planPersisted = computed(() => {
      return state.currentPlan?.persisted;
    });
    const cancelModal = () => {
      state.showParchasePlanModal = false;
    };

    const { mutate: purchasePlanMutation } = usePurchasePlanMutation();
    const purchase = async (cardToken) => {
      state.showParchasePlanModal = false;
      state.loading = true;
      const purchasePlanMutationResult = await purchasePlanMutation({
        accessToken: getToken(),
        cardToken,
      });
      if (purchasePlanMutationResult?.data?.purchasePlan) {
        toastMessage("有料プランを購入しました");
        await refetchCurrentPlan();
        state.loading = false;
      } else {
        toastError();
      }
    };

    const { mutate: cancelPlanMutation } = useCancelPlanMutation();
    const cancelPlan = async () => {
      showConfirmDialog("有料プランを解約しますか？").then(async () => {
        state.loading = true;
        const cancelPlanResult = await cancelPlanMutation({
          accessToken: getToken(),
        });
        if (cancelPlanResult?.data?.cancelPlan == "OK") {
          toastMessage("有料プランを解約しました");
          await refetchCurrentPlan();
          state.loading = false;
        } else {
          toastError();
        }
      });
    };

    return {
      ...toRefs(state),
      planName,
      planPersisted,
      cancelModal,
      purchase,
      cancelPlan,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
}
.detail-contents {
  > .detail {
    width: 100%;
    text-align: left;
    padding-top: 16px;
    .name {
      height: 20px;
      white-space: nowrap;
    }
    .value {
    }
  }
}
::v-deep .button {
  margin-top: 16px;
  color: white;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      color: white;
    }
  }
}
.purchase-button {
  margin-top: 40px;
  background-color: colors(blue);
  .svg {
    margin-right: 6px;
  }
}
.remove-button {
  margin-top: 40px;
  background-color: colors(gray);
  .svg {
    margin-right: 6px;
  }
}
.svg {
  width: 18px;
  fill: white;
}
::v-deep .loading-overlay.is-active {
  background-color: white;
}
::v-deep .modal .animation-content .modal-card {
  margin: 0 auto;
}
</style>
