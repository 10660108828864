<template>
  <div class="top">
    <component :is="topPageComponent" />
  </div>
</template>

<script>
// @ is an alias to /src
import Dashboard from "@/components/tutorial/Dashboard.vue";
import Welcome from "@/components/tutorial/Welcome.vue";

import { useAuth } from "@/submodules/auth";
import { defineComponent, computed } from "@vue/composition-api";

import { useMainStore } from '@/stores/mainStore'

export default defineComponent({
  name: "Top",
  components: {
    Dashboard,
    Welcome,
  },
  setup() {
    console.log('TUTORIAL TOP')
    const topPageComponent = computed(() => {
      const { loggedIn } = useAuth();
      return loggedIn() ? "dashboard" : "welcome";
    });

    const main = useMainStore()
    console.log('main', main)
    console.log('main.increment', main.increment())
    console.log('main.counter', main.counter)
    const computedMainCounter = computed(() => {
      return main.count
    })
    console.log('Top.vue setup CALLED!')

    return {
      topPageComponent,
      computedMainCounter,
    };
  },
});
</script>

<style lang="scss" scoped>
.top {
  height: 100%;
}
</style>
