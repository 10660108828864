import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type GoogleCalendarsQueryVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
}>;


export type GoogleCalendarsQuery = (
  { __typename?: 'Query' }
  & { googleCalendars?: Types.Maybe<Array<(
    { __typename?: 'GoogleCalendar' }
    & Pick<Types.GoogleCalendar, 'id' | 'name' | 'available' | 'isEventRegisterable'>
  )>> }
);


export const GoogleCalendarsDocument = gql`
    query googleCalendars($accessToken: String!) {
  googleCalendars(accessToken: $accessToken) {
    id
    name
    available
    isEventRegisterable
  }
}
    `;

/**
 * __useGoogleCalendarsQuery__
 *
 * To run a query within a Vue component, call `useGoogleCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleCalendarsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGoogleCalendarsQuery({
 *   accessToken: // value for 'accessToken'
 * });
 */
export function useGoogleCalendarsQuery(variables: GoogleCalendarsQueryVariables | VueCompositionApi.Ref<GoogleCalendarsQueryVariables> | ReactiveFunction<GoogleCalendarsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GoogleCalendarsQuery, GoogleCalendarsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GoogleCalendarsQuery, GoogleCalendarsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GoogleCalendarsQuery, GoogleCalendarsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GoogleCalendarsQuery, GoogleCalendarsQueryVariables>(GoogleCalendarsDocument, variables, options);
}
export type GoogleCalendarsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GoogleCalendarsQuery, GoogleCalendarsQueryVariables>;