import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type IsServiceAvailableQueryVariables = Types.Exact<{
  accessToken: Types.Scalars['String'];
}>;


export type IsServiceAvailableQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'isServiceAvailable'>
);


export const IsServiceAvailableDocument = gql`
    query isServiceAvailable($accessToken: String!) {
  isServiceAvailable(accessToken: $accessToken)
}
    `;

/**
 * __useIsServiceAvailableQuery__
 *
 * To run a query within a Vue component, call `useIsServiceAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsServiceAvailableQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useIsServiceAvailableQuery({
 *   accessToken: // value for 'accessToken'
 * });
 */
export function useIsServiceAvailableQuery(variables: IsServiceAvailableQueryVariables | VueCompositionApi.Ref<IsServiceAvailableQueryVariables> | ReactiveFunction<IsServiceAvailableQueryVariables>, options: VueApolloComposable.UseQueryOptions<IsServiceAvailableQuery, IsServiceAvailableQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<IsServiceAvailableQuery, IsServiceAvailableQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<IsServiceAvailableQuery, IsServiceAvailableQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<IsServiceAvailableQuery, IsServiceAvailableQueryVariables>(IsServiceAvailableDocument, variables, options);
}
export type IsServiceAvailableQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<IsServiceAvailableQuery, IsServiceAvailableQueryVariables>;