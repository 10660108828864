import Vue from 'vue'
// import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'

// load zonly necessary component
import Vuetify, {
  VCard,
  VMenu,
  VExpandTransition,
  // VRating,
  VCalendar,
  // VCardText,
  // VSlideXTransition,
  // VSlideXReverseTransition,
  VIcon,
} from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'

// Vue.use(Vuetify)

// load zonly necessary component
Vue.use(Vuetify, {
  components: {
    VCard,
    VMenu,
    VExpandTransition,
    // VRating,
    VCalendar,
    // VCardText,
    // VSlideXTransition,
    // VSlideXReverseTransition,
    VIcon,
  },
})

const opts = {}

export default new Vuetify(opts)
