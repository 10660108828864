<template>
  <div class="purchase-plan-modal">
    <div class="modal-card modal-container" style="width: auto">
      <header class="modal-card-head modal-header has-text-weight-bold">
        <span class="is-size-6_5">初期設定が完了しました！</span>
      </header>

      <section class="modal-card-body">
          <div class="character-section">
            <div class="image-wrapper">
              <img class="image" src="@/assets/img/character/robot.jpeg" />
            </div>
          </div>
          <div class="guide-text">
            <div class="summary">
              <p class="is-size-6_5 has-text-weight-bold"
                >初期設定が完了しました🎉</p
              >
              <p class="is-size-6_5 has-text-weight-bold"
                >アポとるの予約カレンダーを設定する上で必要な初期設定と詳細設定についてご説明致しました。</p
              >
              <p class="is-size-6_5 has-text-weight-bold"
                >必要な設定がありましたら、こちらのページから設定を行って下さい。</p
              >
            </div>
          </div>
      </section>

      <footer class="buttons-wrapper modal-card-foot">
        <button
          class="button is-link is-outlined select-button has-text-weight-bold"
          @click="goToTop"
        >
          トップページに戻る
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
} from "@vue/composition-api";

import { useTutorialStore } from '@/stores/tutorial'
import { useSaveTutorialStatusMutation } from "../graphql/mutation/saveTutorialStatus.generated.ts";

export default defineComponent({
  name: "FinishInitialSettingsTutorialModal",
  components: {},
  setup(_, { root, emit }) {
    const state = reactive({
      loading: true,
    });
    const tutorial = useTutorialStore()
    const { mutate: saveTutorialStatusMutate } = useSaveTutorialStatusMutation()
    onMounted(async () => {
    });
    const cancelModal = () => {
      emit("close");
    };
    const startTutorial = async () => {
      emit("start-tutorial");
      emit("close");
    };
    const goToNextStep = () => {
      tutorial.goToNextStep()
    }
    const goToTop = async () => {
      await tutorial.changeTutorial('CREATE_CALENDAR', saveTutorialStatusMutate)
      root.$router.push('/tutorial')
    }

    return {
      ...toRefs(state),
      cancelModal,
      startTutorial,
      goToNextStep,
      goToTop,
    };
  },
});
</script>

<style lang="scss" scoped>
.modal-container {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  background-color: white;
  border-radius: 4px;
  .modal-header {
    height: 56px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid colors(light-gray);
  }
  .modal-card-body {
    margin: 0 auto;
    height: 256px;
    padding: 16px 16px 24px;
    text-align: left;
    .character-section {
      display: flex;
      padding-bottom: 8px;
      justify-content: center;
      .image-wrapper {
        width: 80px;
        display: flex;
      }
    }
  }
  .buttons-wrapper {
    height: 48px;
    padding: 0;
    border-radius: 4px;
    border-top: 1px solid colors(light-gray);
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    .button {
      height: 32px;
      width: 240px;
      max-width: 160px;
      padding: 0;
      margin: 0;
      font-size: 0.9rem;
      border: 1px solid colors(black);
      &.select-button {
        background-color: black;
        color: white;
      }
      &.cancel-button {
        background-color: white;
        color: colors(black);
      }
    }
    .button::selection {
      -webkit-appearance: none;
    }
  }
}
</style>
