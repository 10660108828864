<template>
  <div class="dashboard">
    <Header />
    <CalendarButtonSection />
    <LatestNews />
    <LatestSchedules />
    <Footer />
    <b-modal
      v-model="computedShowConfirmTutorialModal"
      :on-cancel="cancelConfirmTutorialModal"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-label="PurchasePlanModal"
      aria-modal
      :can-cancel="['escape', 'outside']"
    >
      <template #default="props">
        <ConfirmTutorialModal @start-tutorial="startTutorial" @close="props.close" />
      </template>
    </b-modal>
    <b-modal
      v-model="computedShowStartCreateCalendarTutorialModal"
      :on-cancel="cancelConfirmTutorialModal"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-label="PurchasePlanModal"
      aria-modal
      :can-cancel="['escape', 'outside']"
    >
      <template #default="props">
        <StartCreateCalendarTutorialModal @start-tutorial="goToNext" @close="props.close" />
      </template>
    </b-modal>
    <b-modal
      v-model="computedShowStartInitialSettingTutorialModal"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-label="StartInitialSettingTutorialModal"
      aria-modal
      :can-cancel="['escape', 'outside']"
    >
      <template #default="props">
        <StartInitialSettingTutorialModal @start-tutorial="goToNext" @close="props.close" />
      </template>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { computed, defineComponent } from "@vue/composition-api";
import { useTutorialStore } from '@/stores/tutorial'
import { useSaveTutorialStatusMutation } from "./graphql/mutation/saveTutorialStatus.generated.ts";

import Header from "@/components/Header.vue";
import CalendarButtonSection from "@/components/tutorial/dashboard/calendarButtonSection.vue";
// import ServiceAvailableAlertSection from "@/components/dashboard/ServiceAvailableAlertSection.vue";
import LatestNews from "@/components/tutorial/dashboard/LatestNews.vue";
import LatestSchedules from "@/components/tutorial/dashboard/LatestSchedules.vue";
import Footer from "@/components/tutorial/Footer.vue";
import ConfirmTutorialModal from "@/components/dashboard/ConfirmTutorialModal.vue";
import StartCreateCalendarTutorialModal from "@/components/tutorial/dashboard/StartCreateCalendarTutorialModal.vue";
import StartInitialSettingTutorialModal from "@/components/tutorial/dashboard/StartInitialSettingTutorialModal.vue";

export default defineComponent({
  name: "Top",
  components: {
    Header,
    Footer,
    CalendarButtonSection,
    // ServiceAvailableAlertSection,
    LatestNews,
    LatestSchedules,
    ConfirmTutorialModal,
    StartCreateCalendarTutorialModal,
    StartInitialSettingTutorialModal,
  },
  setup(_, { root }) {
    // const state = reactive({
    //   showConfirmTutorialModal: false,
    //   showStartCreateCalendarTutorialModal: false,
    // })
    const tutorial = useTutorialStore()
    // onMounted(() => {
    //   state.showConfirmTutorialModal = tutorial.current === 'startTutorial'
    // })
    const { mutate: saveTutorialStatusMutate } = useSaveTutorialStatusMutation()
    const computedShowConfirmTutorialModal = computed({
      get: () => tutorial.current === 'BEFORE_START_TUTORIAL',
      set: async (val) => {
        // チュートリアルスキップの場合のみ呼び出される
        tutorial.skipTutorial()
        await tutorial.changeTutorial('COMPLETED', saveTutorialStatusMutate)
        root.$router.push('/')
        console.log('computed val', val)
      }
    })
    // const computedShowStartCreateCalendarTutorialModal = computed(() => tutorial.current === 'CREATE_CALENDAR' && tutorial.step === 0)
    const computedShowStartCreateCalendarTutorialModal = computed({
      get: () => tutorial.current === 'CREATE_CALENDAR' && tutorial.step === 0,
      set: async (val) => {
        tutorial.skipTutorial()
        await tutorial.changeTutorial('COMPLETED', saveTutorialStatusMutate)
        root.$router.push('/')
        console.log('computedShowStartCreateCalendarTutorialModal', val)
        // tutorial.skipTutorial()
        // root.$router.push('/')
      }
    })
    const computedShowStartInitialSettingTutorialModal = computed({
      get: () => tutorial.current === 'INITIAL_SETTING' && tutorial.step === 0,
      set: async (val) => {
        console.log('computedShowStartInitialSettingTutorialModal', val)
        tutorial.skipTutorial()
        await tutorial.changeTutorial('COMPLETED', saveTutorialStatusMutate)
        root.$router.push('/')
      }
    })
    const cancelConfirmTutorialModal = () => {
      console.log('cancelConfirmTutorialModal CLICKED!!')
      tutorial.skipTutorial()
      root.$router.push('/')
    }
    // const cancelConfirmTutorialModal = () => {
    //   console.log('cancelConfirmTutorialModal CLICKED!!')
    // }
    // const cancelStartCreateCalendarTutorialModal = () => {
    //   console.log('cancelStartCreateCalendarTutorialModal CLICKED!!')
    // }
    const startTutorial = async () => {
      console.log('START TUTORIAL')
      await tutorial.changeTutorial('INITIAL_SETTING', saveTutorialStatusMutate)
      // tutorial.startTutorial()
      // root.$router.push('/tutorial/settings')
    }
    const goToNext = () => {
      console.log('GO TO NEXT TUTORIAL')
      tutorial.goToNextStep()
    }

    return {
      // ...toRefs(state),
      computedShowConfirmTutorialModal,
      computedShowStartCreateCalendarTutorialModal,
      // showConfirmTutorialModal,
      // showStartCreateCalendarTutorialModal,
      computedShowStartInitialSettingTutorialModal,
      cancelConfirmTutorialModal,
      // cancelStartCreateCalendarTutorialModal,
      startTutorial,
      goToNext,
    }
  },
});
</script>

<style lang="scss" scoped>
.dashboard {
  padding-bottom: 56px;
}
::v-deep .modal .animation-content.modal-content .modal-card {
  margin: 0 auto;
}
</style>
