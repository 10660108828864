import * as Types from '../../../gen/types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;
export type CalendarQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type CalendarQuery = (
  { __typename?: 'Query' }
  & { calendar: (
    { __typename?: 'Reservation' }
    & Pick<Types.Reservation, 'id' | 'name' | 'duration' | 'timeRangeStartHour' | 'timeRangeStartMinute' | 'timeRangeEndHour' | 'timeRangeEndMinute' | 'placeId' | 'placeKind' | 'otherPlaceName' | 'status' | 'needContactInfo' | 'webMtgUrl' | 'createdAt' | 'isAvailable'>
    & { place?: Types.Maybe<(
      { __typename?: 'Place' }
      & Pick<Types.Place, 'id' | 'name'>
    )>, attendees?: Types.Maybe<Array<(
      { __typename?: 'User' }
      & Pick<Types.User, 'id'>
      & { profile?: Types.Maybe<(
        { __typename?: 'Profile' }
        & Pick<Types.Profile, 'id' | 'name'>
      )> }
    )>> }
  ) }
);


export const CalendarDocument = gql`
    query calendar($id: String!) {
  calendar(id: $id) {
    id
    name
    duration
    timeRangeStartHour
    timeRangeStartMinute
    timeRangeEndHour
    timeRangeEndMinute
    place {
      id
      name
    }
    placeId
    placeKind
    otherPlaceName
    attendees {
      id
      profile {
        id
        name
      }
    }
    status
    needContactInfo
    webMtgUrl
    createdAt
    isAvailable
  }
}
    `;

/**
 * __useCalendarQuery__
 *
 * To run a query within a Vue component, call `useCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCalendarQuery({
 *   id: // value for 'id'
 * });
 */
export function useCalendarQuery(variables: CalendarQueryVariables | VueCompositionApi.Ref<CalendarQueryVariables> | ReactiveFunction<CalendarQueryVariables>, options: VueApolloComposable.UseQueryOptions<CalendarQuery, CalendarQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CalendarQuery, CalendarQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CalendarQuery, CalendarQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CalendarQuery, CalendarQueryVariables>(CalendarDocument, variables, options);
}
export type CalendarQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CalendarQuery, CalendarQueryVariables>;