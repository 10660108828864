import Vue from 'vue'
import VueRouter from 'vue-router'
import Top from '../views/Top.vue'
import Plans from '../views/Plans.vue'
import Schedule from '../views/Schedule.vue'
import Schedules from '../views/Schedules.vue'
import Calendars from '../views/Calendars.vue'
import Calendar from '../views/Calendar.vue'
import EditCalendar from '../views/EditCalendar.vue'
import NewCalendar from '../views/NewCalendar.vue'
import NewSchedule from '../views/NewSchedule.vue'
import Notifications from '../views/Notifications.vue'

import Settings from '../views/Settings.vue'
import Profile from '../views/Profile.vue'
import EditProfile from '../views/EditProfile.vue'
import Places from '../views/Places.vue'
import NewPlace from '../views/NewPlace.vue'
import Place from '../views/Place.vue'
import EditPlace from '../views/EditPlace.vue'
import DetailSettings from '../views/DetailSettings.vue'
import EventRegisterabeCalendar from '../views/EventRegisterabeCalendar.vue'
import GoogleCalendars from '../views/GoogleCalendars.vue'
import HolidaySetting from '../views/HolidaySetting.vue'
import AdjustableTimePeriods from '../views/AdjustableTimePeriods.vue'
import EditAdjustableTimePeriods from '../views/EditAdjustableTimePeriods.vue'
import LunchTimeSetting from '../views/LunchTimeSetting.vue'
import EditLunchTimeSetting from '../views/EditLunchTimeSetting.vue'
import IntervalSettings from '../views/IntervalSettings.vue'
import EditIntervalSettings from '../views/EditIntervalSettings.vue'
import PurchasePlan from '../views/PurchasePlan.vue'
import Rules from '../views/Rules.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import Faq from '../views/Faq.vue'
import OtherMenu from '../views/OtherMenu.vue'

// Tutorial
import TutorialTop from '../views/tutorial/Top.vue'
import TutorialPlans from '../views/tutorial/Plans.vue'
import TutorialSchedule from '../views/tutorial/Schedule.vue'
import TutorialSchedules from '../views/tutorial/Schedules.vue'
import TutorialCalendars from '../views/tutorial/Calendars.vue'
import TutorialCalendar from '../views/tutorial/Calendar.vue'
import TutorialEditCalendar from '../views/tutorial/EditCalendar.vue'
import TutorialNewCalendar from '../views/tutorial/NewCalendar.vue'
import TutorialNewSchedule from '../views/tutorial/NewSchedule.vue'
import TutorialNotifications from '../views/tutorial/Notifications.vue'
import TutorialSettings from '../views/tutorial/Settings.vue'
import TutorialProfile from '../views/tutorial/Profile.vue'
import TutorialEditProfile from '../views/tutorial/EditProfile.vue'
import TutorialPlaces from '../views/tutorial/Places.vue'
import TutorialNewPlace from '../views/tutorial/NewPlace.vue'
import TutorialPlace from '../views/tutorial/Place.vue'
import TutorialEditPlace from '../views/tutorial/EditPlace.vue'

import { useAuth } from '@/submodules/auth'

const { login } = useAuth()

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Top',
    component: Top
  },
  {
    path: '/plans',
    name: 'Plans',
    component: Plans
  },
  {
    path: '/schedules',
    name: 'Schedules',
    component: Schedules
  },
  {
    path: '/schedules/new',
    name: 'NewSchedule',
    component: NewSchedule
  },
  {
    path: '/schedules/:id',
    name: 'Schedule',
    component: Schedule
  },
  {
    path: '/calendars',
    name: 'Calendars',
    component: Calendars
  },
  {
    path: '/calendars/new',
    name: 'NewCalendar',
    component: NewCalendar,
  },
  {
    path: '/calendars/:id/edit',
    name: 'EditCalendar',
    component: EditCalendar
  },
  {
    path: '/calendars/:id',
    name: 'Calendar',
    component: Calendar
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/profile/edit',
    name: 'EditProfile',
    component: EditProfile
  },
  {
    path: '/places',
    name: 'Places',
    component: Places
  },
  {
    path: '/places/new',
    name: 'NewPlace',
    component: NewPlace
  },
  {
    path: '/places/:id/edit',
    name: 'EditPlace',
    component: EditPlace
  },
  {
    path: '/places/:id',
    name: 'Place',
    component: Place
  },
  {
    path: '/detail_settings',
    name: 'DetailSettings',
    component: DetailSettings
  },
  {
    path: '/event_registerable_caendar',
    name: 'EventRegisterabeCalendar',
    component: EventRegisterabeCalendar
  },
  {
    path: '/google_calendars',
    name: 'GoogleCalendars',
    component: GoogleCalendars
  },
  {
    path: '/holiday_setting',
    name: 'HolidaySetting',
    component: HolidaySetting
  },

  {
    path: '/adjustable_time_periods',
    name: 'AdjustableTimePeriods',
    component: AdjustableTimePeriods
  },
  {
    path: '/adjustable_time_periods/edit',
    name: 'EditAdjustableTimePeriods',
    component: EditAdjustableTimePeriods
  },

  {
    path: '/lunch_time_setting',
    name: 'LunchTimeSetting',
    component: LunchTimeSetting
  },
  {
    path: '/lunch_time_setting/edit',
    name: 'EditLunchTimeSetting',
    component: EditLunchTimeSetting
  },
  {
    path: '/interval_settings',
    name: 'IntervalSettings',
    component: IntervalSettings
  },
  {
    path: '/interval_settings/edit',
    name: 'EditIntervalSettings',
    component: EditIntervalSettings
  },
  {
    path: '/purchase_plan',
    name: 'PurchasePlan',
    component: PurchasePlan
  },
  {
    path: '/rules',
    name: 'Rules',
    component: Rules
  },
  {
    path: '/privacy_policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq
  },
  {
    path: '/other_menu',
    name: 'OtherMenu',
    component: OtherMenu
  },
  {
    path: '/logout',
    name: 'Logout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Logout.vue')
  },
  {
    path: '/login_dev',
    name: 'LoginDev',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginDev.vue')
  },
  {
    path: '/other_menu',
    name: 'OtherMenu',
    component: OtherMenu
  },
  // Tutorial
  {
    path: '/tutorial/',
    name: 'TutorialTop',
    component: TutorialTop
  },
  {
    path: '/tutorial/plans',
    name: 'TutorialPlans',
    component: TutorialPlans
  },
  {
    path: '/tutorial/schedules',
    name: 'TutorialSchedules',
    component: TutorialSchedules
  },
  {
    path: '/tutorial/schedules/new',
    name: 'TutorialNewSchedule',
    component: TutorialNewSchedule
  },
  {
    path: '/tutorial/schedules/:id',
    name: 'TutorialSchedule',
    component: TutorialSchedule
  },
  {
    path: '/tutorial/calendars',
    name: 'TutorialCalendars',
    component: TutorialCalendars
  },
  {
    path: '/tutorial/calendars/new',
    name: 'TutorialNewCalendar',
    component: TutorialNewCalendar,
  },
  {
    path: '/tutorial/calendars/:id/edit',
    name: 'TutorialEditCalendar',
    component: TutorialEditCalendar
  },
  {
    path: '/tutorial/calendars/:id',
    name: 'TutorialCalendar',
    component: TutorialCalendar
  },
  {
    path: '/tutorial/notifications',
    name: 'TutorialNotifications',
    component: TutorialNotifications
  },
  {
    path: '/tutorial/settings',
    name: 'TutorialSettings',
    component: TutorialSettings
  },
  {
    path: '/tutorial/profile',
    name: 'TutorialProfile',
    component: TutorialProfile
  },
  {
    path: '/tutorial/profile/edit',
    name: 'TutorialEditProfile',
    component: TutorialEditProfile
  },
  {
    path: '/tutorial/places',
    name: 'TutorialPlaces',
    component: TutorialPlaces
  },
  {
    path: '/tutorial/places/new',
    name: 'TutorialNewPlace',
    component: TutorialNewPlace
  },
  {
    path: '/tutorial/places/:id/edit',
    name: 'TutorialEditPlace',
    component: TutorialEditPlace
  },
  {
    path: '/tutorial/places/:id',
    name: 'TutorialPlace',
    component: TutorialPlace
  },
  {
    path: '*',
    name: 'Top',
    component: Top
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Redirect if fullPath begins with a hash (ignore hashes later in path)
  if (to.fullPath.substr(0,2) === "/#") {
    const path = to.fullPath.substr(2);
    next(path);
    return;
  }
  if (to.fullPath.includes('detail_schedule')) {
    const path = to.fullPath.replace('detail_schedule', 'schedules')
    next(path);
    return;
  }
  if (to.fullPath.includes('create_schedule_from_reservation')) {
    const calendarId = to.fullPath.match(/create_schedule_from_reservation\/(\d*)$/)[1]
    if (calendarId) {
      const path = `/schedules/new?calendar_id=${calendarId}`
      next(path)
      return
    }
  }

  // get token from url
  if (to.fullPath.includes('?token=')) {
    const token = to.fullPath.match(/token=(.*)([&].*)?/)[1]
    login(token)
    sessionStorage.message = "ログインしました"
    next('/')
    return;
  }
  if (to.fullPath == "/top" || to.fullPath == "/#top") {
    next('/')
    return;
  }

  next();
});

export default router
