<template>
  <div class="calendar-button-section">
    <div class="areas-wrapper">
      <div class="description-area">
        <p class="is-size-6 float-text">Googleカレンダーから</p>
        <p class="is-size-6 float-text">
          <span class="float-text blue-text is-size-6 has-text-weight-bold"
            >日程調整・予約受付</span
          >の
        </p>
        <p class="float-text is-size-6">カレンダーを作りましょう</p>
        <div class="bgimage-row">
          <img class="image" src="@/assets/img/bg-calendar.png" />
        </div>
      </div>
      <div class="button-area">
        <b-tooltip v-if="isCreateCalendarTutorialStep1Active" :class="{ 'top-index': isCreateCalendarTutorialStep1Active }" position="is-top" type="is-success" multilined always>
          <template v-slot:content>
            <div><b>カレンダーを作成(1/6)</b></div>
            <p>このボタンをクリックして</p>
            <p>カレンダー作成を開始します</p>
          </template>
          <router-link to="/calendars/new">
            <b-button
              size="calendar-button is-large has-text-white has-text-weight-bold is-size-6"
            >
              <div class="inner-button">
                <div class="svg-wrapper"><BlankCalendarIcon /></div>
                <span>カレンダーを作る</span>
              </div>
            </b-button>
          </router-link>
        </b-tooltip>
        <router-link v-else to="/calendars/new">
          <b-button
            size="calendar-button is-large has-text-white has-text-weight-bold is-size-6"
          >
            <div class="inner-button">
              <div class="svg-wrapper"><BlankCalendarIcon /></div>
              <span>カレンダーを作る</span>
            </div>
          </b-button>
        </router-link>
      </div>
      <!-- <b-tooltip :always="true" position="is-top" multilined>
        <b-button label="Html Content" type="is-primary" />
        <template v-slot:content>
          <b>Lorem ipsum dolor sit amet</b>, consectetur warning elit. <i>Fusce id fermentum quam</i>.
        </template>
      </b-tooltip> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { computed, defineComponent, reactive, toRefs } from "@vue/composition-api";
import { useTutorialStore } from '@/stores/tutorial'

import BlankCalendarIcon from "@/assets/img/common/outline_icons/blank_calendar.svg";

export default defineComponent({
  name: "CalendarButtonSection",
  components: {
    BlankCalendarIcon,
  },
  setup() {
    const state = reactive({});
    const tutorial = useTutorialStore()
    const isCreateCalendarTutorialStep1Active = computed(() => {
      return tutorial.current == 'CREATE_CALENDAR' && tutorial.step == 1
    })

    return {
      ...toRefs(state),
      isCreateCalendarTutorialStep1Active,
    };
  },
});
</script>

<style lang="scss" scoped>
.calendar-button-section {
  position: relative;
  height: 168px;
  width: 100%;
  background-color: colors(light-gray);
  overflow: hidden;
  > .areas-wrapper {
    max-width: 760px;
    margin: 0 auto;
    padding: 16px;
    position: relative;
    > .description-area {
      height: 72px;
      display: flex;
      flex-direction: column;
      align-items: start;
      .blue-text {
        color: colors(blue);
      }
    }
    > .button-area {
      width: 100%;
      padding-top: 16px;
      .calendar-button {
        background-color: colors(blue);
        width: 100%;
        height: 48px;
        z-index: 1;
        .inner-button {
          display: flex;
          align-items: center;
          > .svg-wrapper {
            display: flex;
            align-items: center;
            padding-bottom: 3px;
            padding-right: 8px;
          }
        }
      }
      > .b-tooltip {
        width: 100%;
      }
    }
  }
  .bgimage-row {
    width: 200px;
    height: 360px;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    > .image-wrapper {
      .image {
        display: block;
      }
    }
  }
}
.float-text {
  z-index: 1;
}
.top-index {
  z-index: 10;
}
</style>
