<template>
  <div class="interval-settings">
    <PageHeader
      title="アポ予約前後の空き時間の設定"
      :backIcon="true"
      to="/settings"
    />
    <div class="contents-wrapper">
      <b-loading :is-full-page="false" :active="loading" />
      <div class="detail-contents">
        <div class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">アポ予約前の空き時間</p>
          </div>
          <div class="value">
            <p class="is-size-6">{{ intervalBeforeEvent }}</p>
          </div>
        </div>
      </div>
      <div class="detail-contents">
        <div class="detail">
          <div class="name">
            <p class="is-size-7 has-text-weight-bold">アポ予約後の空き時間</p>
          </div>
          <div class="value">
            <p class="is-size-6">{{ intervalAfterEvent }}</p>
          </div>
        </div>
      </div>
      <router-link to="/interval_settings/edit">
        <b-button class="edit-button" expanded>
          <p class="text is-size-6_5 has-text-weight-bold">
            前後の空き時間を編集する
          </p>
        </b-button>
      </router-link>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import {
  defineComponent,
  reactive,
  ref,
  toRefs,
  computed,
} from "@vue/composition-api";

import { useAuth } from "@/submodules/auth";
import { useEventIntervalsQuery } from "./graphql/query/eventIntervals.generated";

import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";

const { getToken } = useAuth();
const fetchIntervals = () => {
  const intervalSettings = ref({});
  const { onResult, loading } = useEventIntervalsQuery({
    accessToken: getToken(),
  });
  onResult((result) => {
    console.log("result", result);
    intervalSettings.value = result.data.adjustableTimePeriods;
  });

  return {
    intervalSettings,
    loading,
  };
};

export default defineComponent({
  name: "IntervalSettings",
  components: {
    PageHeader,
    Footer,
  },
  setup() {
    const state = reactive({
      loading: true,
      intervalSettings: {},
    });

    const { intervalSettings, loading } = fetchIntervals();
    state.intervalSettings = intervalSettings;
    state.loading = loading;

    const intervalBeforeEvent = computed(() => {
      if (!state.intervalSettings.intervalBeforeEvent) {
        return "";
      }
      if (state.intervalSettings.intervalBeforeEvent == "00") {
        return "なし";
      } else {
        return state.intervalSettings.intervalBeforeEvent + "分";
      }
    });
    const intervalAfterEvent = computed(() => {
      if (!state.intervalSettings.intervalAfterEvent) {
        return "";
      }
      if (state.intervalSettings.intervalAfterEvent == "00") {
        return "なし";
      } else {
        return state.intervalSettings.intervalAfterEvent + "分";
      }
    });

    return {
      ...toRefs(state),
      intervalBeforeEvent,
      intervalAfterEvent,
    };
  },
});
</script>

<style lang="scss" scoped>
.contents-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  padding: 16px 16px 72px;
}
.detail-contents {
  > .detail {
    width: 100%;
    text-align: left;
    padding: 8px 0;
    display: block;
    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .button {
  margin-top: 16px;
  color: white;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    .text {
      color: white;
    }
  }
}
.edit-button {
  margin-top: 32px;
  background-color: colors(green);
  color: white;
}
</style>
