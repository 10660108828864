<template>
  <div class="purchase-plan-modal">
    <div class="modal-card modal-container" style="width: auto">
      <header class="modal-card-head modal-header has-text-weight-bold">
        <span class="is-size-6_5">カレンダーを作ろう</span>
      </header>

      <section class="modal-card-body">
          <div class="character-section">
            <div class="image-wrapper">
              <img class="image" src="@/assets/img/character/robot.jpeg" />
            </div>
          </div>
          <div class="guide-text">
            <div class="summary">
              <p class="is-size-6_5 has-text-weight-bold"
                >カレンダーを作って</p
              >
              <p class="is-size-6_5 has-text-weight-bold"
                >アポ予約を受けつけましょう。</p
              >
            </div>
            <div class="steps">
              <p class="is-size-6_5 has-text-weight-bold"
                >STEP1: カレンダー作成画面を開く</p
              >
              <p class="is-size-6_5 has-text-weight-bold"
                >STEP2: 必要事項を入力</p
              >
              <p class="is-size-6_5 has-text-weight-bold"
                >STEP3: カレンダーのURLを発行する</p
              >
            </div>

          </div>
      </section>

      <footer class="buttons-wrapper modal-card-foot">
        <button
          class="button is-link is-outlined select-button has-text-weight-bold"
          @click="startTutorial"
        >
          開始します
        </button>
        <button
          class="button is-link is-outlined cancel-button has-text-weight-bold"
          @click="cancelModal"
        >
          スキップ
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
} from "@vue/composition-api";

export default defineComponent({
  name: "PurchasePlanModal",
  components: {},
  setup(_, { emit }) {
    const state = reactive({
      loading: true,
    });
    onMounted(async () => {
    });
    const cancelModal = () => {
      emit("close");
    };
    const startTutorial = async () => {
      console.log('startTutorial Called!')
      emit("start-tutorial");
      // emit("close");
    };

    return {
      ...toRefs(state),
      cancelModal,
      startTutorial,
    };
  },
});
</script>

<style lang="scss" scoped>
.modal-container {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  background-color: white;
  border-radius: 4px;
  .modal-header {
    height: 56px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid colors(light-gray);
  }
  .modal-card-body {
    margin: 0 auto;
    height: 232px;
    padding: 16px 16px 24px;
    text-align: left;
    .character-section {
      display: flex;
      padding-bottom: 8px;
      justify-content: center;
      .image-wrapper {
        width: 80px;
        display: flex;
      }
    }
  }
  .buttons-wrapper {
    height: 48px;
    padding: 0;
    border-radius: 4px;
    border-top: 1px solid colors(light-gray);
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    .button {
      height: 32px;
      width: calc(50% - 32px);
      max-width: 160px;
      padding: 0;
      margin: 0;
      font-size: 0.9rem;
      border: 1px solid colors(black);
      &.select-button {
        background-color: black;
        color: white;
      }
      &.cancel-button {
        background-color: white;
        color: colors(black);
      }
    }
    .button::selection {
      -webkit-appearance: none;
    }
  }
}
</style>
